import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { handleTabAction } from '../utils/helpers';

const PlanSummary = ({ handleBilling, handleTabChange }) => {
  const [isPlan, setIsPlan] = useState(false);
  const handleNext = (e) => {
    e.preventDefault();
    handleTabAction('billing', 'questionnaire');
    // let data = {
    //   selectedStripeIds,
    //   selectedPackage,
    //   total,
    //   subTotal,
    // };
    // dispatch(setBillingDetail(data));
    handleBilling('questionnaire');
  };
  const handleBack = () => {
    handleTabChange('profile');
    handleTabAction('billing', 'profile');
  };
  return (
    <>
      <div className="plan-summary account_height">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <div className="s-m">
                <h4>Plan Summary</h4>
                <div className="ps">
                  <div className="form-check form-switch">
                    <label
                      className="form-check-label mo"
                      htmlFor="monthly"
                      style={{ textAlign: 'left' }}
                    >
                      Monthly
                    </label>
                    <input
                      className={`form-check-input ${!isPlan ? 'month' : 'yearly'}`}
                      type="checkbox"
                      id={!isPlan ? 'annually' : 'monthly'}
                      onChange={() => setIsPlan(!isPlan)}
                      checked={isPlan}
                    />
                    <label className="form-check-label y" htmlFor="annually">
                      Annually
                    </label>
                  </div>
                </div>
              </div>

              <div className="c-p">
                <p className="hooray">
                  Hooray! You have saved $46 by choosing Life Time plan
                </p>
                <table className="table fee-t">
                  <tbody>
                    {/* <tr>
                      <td>Activation Fee</td>
                      <th>$2.95</th>
                    </tr> */}
                    <tr>
                      <td>Tax</td>
                      <th>$0</th>
                    </tr>
                    <tr>
                      <td>One Time Registration Fee</td>
                      <th>$0</th>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>Total</td>
                      <th>$2.78</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div className="button-back my-5">
                <a className="back-text" onClick={handleBack}>
                  Back
                </a>
                <button
                  type="button"
                  onClick={handleNext}
                  className="theme-button next-btn mobile-button-view"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

PlanSummary.propTypes = {
  handleBilling: PropTypes.func,
  handleTabChange: PropTypes.func,
};

export default PlanSummary;
