import React from 'react';
import AccountMainLayout from '../Layout/AccountMainLayout';

const RangePolicy = () => {
  return (
    <>
      <AccountMainLayout>
        <div className="privacy-terms">
          <div className="container">
            <div className="row">
              <div className="conditions">
                <h3 className="my-4">RANGE POLICIES</h3>
                <p>
                  I have read and I understand all the Safety Rules (&quot;Range
                  Rules&quot;) and I agree to the terms of the Range&apos;s
                  Waiver of Liability and Assumption of Risk
                  (&quot;Waiver&quot;) below. In consideration of the right to
                  use the shooting range, I hold Range, its affiliates, owners,
                  heirs, officers, members, employees and volunteers harmless
                  from and indemnify them against, and release them from, any
                  and all claims, demands, damages, expenses, causes of action,
                  attachments of property, liability, injury, death or financial
                  compensation, which may occur in present or future
                  participation in any and all activities at Range (even to the
                  extent caused by the ordinary negligence of a Range).
                </p>
                <p>
                  I agree to compensate range/s for damage resulting from
                  improper use of the range and/or damage caused by my
                  negligence.
                </p>
                <p>
                  I understand that photos and videos (including, without
                  limitation, security videos) may be taken in the range. I have
                  the right to object to photos and videos being taken of me
                  (other than security videos). If I do not object at the time
                  photos and videos are taken, I agree that the range may use
                  such photographs and videos of me with or without my name and
                  for any lawful purpose, including for example such purposes as
                  publicity, illustration, advertising, and web content.
                  Security videos may be used by Stoddard&apos;s by law
                  enforcement.
                </p>
                <h3>WAIVER OF LIABILITY AND ASSUMPTION OF RISK</h3>
                <p className="my-3">
                  I UNDERSTAND THAT I AM ENTERING A PRIVATE SHOOTING RANGE AT MY
                  OWN RISK WHERE I WILL ENCOUNTER HIGH LEVELS OF NOISE AND THE
                  DISCHARGE OF AMMUNITION FROM FIREARMS.
                </p>
                <p>
                  I ACKNOWLEDGE THE RISKS AND DANGERS THAT EXIST IN MY USE OF
                  ANY AND ALL FIREARMS AND MY PARTICIPATION IN SHOOTING
                  ACTIVITIES.
                </p>
                <p>
                  I ACKNOWLEDGE AND UNDERSTAND THAT SHOOTING ACTIVITIES ARE
                  INHERENTLY HAZARDOUS AND INVOLVE BOTH KNOWN AND UNANTICIPATED
                  RISKS WHICH COULD RESULT IN DAMAGE OR DESTRUCTION OF PROPERTY
                  AND PHYSICAL OR EMOTIONAL INJURY, INCLUDING, WITHOUT
                  LIMITATION, PARALYSIS OR DEATH, OF MYSELF OR OF OTHER PERSONS
                  AND I ASSUME THE RISKS THAT COULD RESULT IN PHYSICAL AND/OR
                  EMOTIONAL INJURY, PARALYSIS, DEATH, OR DAMAGE TO MYSELF, TO
                  PROPERTY, OR TO THIRD-PARTIES. THE RISKS INCLUDE, BUT ARE NOT
                  LIMITED TO, FLYING BULLETS, FLYING DEBRIS, NOISE, BEING SHOT
                  BY OR SHOOTING MYSELF OR OTHERS; PARTIAL OR TOTAL LOSS OF
                  EYESIGHT OR HEARING; INHALATION OR OTHER HARMFUL CONTACT WITH
                  LEAD OR OTHER CONTAMINANTS; BURNS AND SCARRING; AND BEING
                  STRUCK BY FLYING OR FALLING OBJECTS.
                </p>
                <p>
                  I UNDERSTAND I AM USING THE RANGE AS A TEMPORARY MEMBER FOR A
                  PRESCRIBED TIME WITH NO OTHER RIGHTS OF MEMBERSHIP, WHICH
                  MEMBERSHIP CAN BE REVOKED AT THE SOLE DISCRETION OF
                  STODDARD&apos;S. THIS RELEASE IS BINDING ON MY HEIRS,
                  SUCCESSORS AND ASSIGNS. IF ANY PART OF THIS AGREEMENT IS
                  DEEMED TO BE VOID, THIS WILL HAVE NO EFFECT ON THE REMAINER OF
                  THIS AGREEMENT.
                </p>
                <p>
                  I FURTHER ACKNOWLEDGE AND UNDERSTAND THAT STODDARD&apos;S HAS
                  NO DUTY TO UNDERTAKE FIRST-AID OR RESCUE OPERATIONS OR
                  PROCEDURES IN THE EVENT ANY SUCH PROPERTY DAMAGE OR PHYSICAL
                  OR EMOTIONAL INJURY OCCURS. NOTWITHSTANDING THE FOREGOING AND
                  WHILE STODDARD&apos;S HAS NO OBLIGATION TO UNDERTAKE FIRST
                  AID, IN THE EVENT OF AN EMERGENCY, I HEREBY CONSENT TO
                  EMERGENCY MEDICAL CARE BY STODDARD&apos;S PERSONNEL OR OTHERS,
                  INCLUDING, WITHOUT LIMITATION, CPR, FIRST AID, EMERGENCY
                  TRANSPORT, AND I SHALL BE RESPONSIBLE FOR ALL COSTS INVOLVED.
                </p>
                <p>
                  I FURTHER ACKNOWLEDGE AND UNDERSTAND THAT THE STODDARD&apos;S
                  MAKES NO WARRANTY AS TO THE DESIGN, MANUFACTURE, MAINTENANCE,
                  CONDITION OR FITNESS FOR ANY PARTICULAR PURPOSE OF ANY RANGE
                  FACILITIES OR EQUIPMENT, INCLUDING, BUT NOT LIMITED TO:
                  FIREARMS, AMMUNITION, EYE/HEARING PROTECTION, AND FIRST-AID
                  SUPPLIES.
                </p>
                <p>
                  I AGREE TO ASSUME ALL RESPONSIBILITY AND LIABILITY FOR ANY ACT
                  OR ACTS, EVEN ANY NEGLIGENT, RECKLESS OR CRIMINAL ACT OR
                  OMISSION TO ACT, OF MY GUESTS AT THE RANGE AND FOR MINORS
                  UNDER MY SUPERVISION. I ALSO AGREE TO SUPERVISE AND BE
                  RESPONSIBLE FOR ANY MINOR IN MY CARE WHILE ON THE PROPERTY.
                </p>
                <p>
                  I AGREE TO BE PERSONALLY RESPONSIBLE FOR MY SAFETY AND THE
                  SAFETY OF MINORS UNDER MY SUPERVISION AND FOR THE SAFETY OF MY
                  GUESTS.
                </p>
                <p>
                  I AGREE TO WEAR ALL APPROPRIATE SAFETY EQUIPMENT, INCLUDING,
                  WITHOUT LIMITATION, EAR AND EYE PROTECTION.
                </p>
                <p>
                  IN ADDITION TO, AND NOT IN LIMITATION OF, ALL OTHER PROVISIONS
                  OF THIS WAIVER, I UNDERSTAND THAT USE OF THE RANGE MAY POSE A
                  HEIGHTENED RISK OF EXPOSURE TO THE CORONAVIRUS (COVID-19)
                  PANDEMIC AND I KNOWINGLY ACCEPT THE RISK.
                </p>
                <p>
                  I UNDERSTAND AND AGREE THAT I WILL NOT ACCESS THE RANGE IF I
                  HAVE ANY SYMPTOMS OF COVID-19, IF I HAVE BEEN KNOWINGLY
                  EXPOSED TO ANYONE WHO IS SUSPECTED TO HAVE, OR IS CONFIRMED TO
                  HAVE, COVID-19 IN THE PREVIOUS FIFTEEN (15) DAYS OR IF I HAVE
                  TRAVELLED TO A COUNTRY OR STATE WITH A KNOWN COVID-19 OUTBREAK
                  IN THE PAST FIFTEEN (15) DAYS. IN USING THE RANGE, I WILL
                  PRACTICE SOCIAL DISTANCING IN ACCORDANCE WITH CDC GUIDELINES.
                  I WILL COMPLY WITH ALL OTHER CDC GUIDELINES, INCLUDING,
                  WITHOUT LIMITATION, FREQUENT HAND WASHING AND COVERING COUGHS
                  AND SNEEZES.
                </p>
                <p>
                  I ACKNOWLEDGE THAT I ASSUME ALL RISKS ASSOCIATED WITH
                  CONTRACTING COVID-19, OR BEING EXPOSED TO COVID-19, IN RANGE’S
                  FACILITIES. I RELEASE RANGE FROM ALL CLAIMS, COSTS, CAUSES OF
                  ACTIONS, OR LIABILITIES ARISING OUT OF OR RELATED TO ANY LOSS,
                  PERSONAL INJURY OR DEATH RELATED TO COVID-19 OR OTHER
                  ILLNESSES OR DISEASES.
                </p>
                <h6>RULES</h6>
                <p>
                  All Federal, State, and Local firearm laws must be obeyed.
                  Always follow all firearm safety rules:
                </p>

                <ul className="browser-m">
                  <li>Always treat firearms like they are loaded. ALWAYS.</li>
                  <li>Always keep the muzzle pointed downrange.</li>
                  <li>
                    Always keep your finger off the trigger and outside the
                    trigger guard until you are ready to shoot.
                  </li>
                  <li>
                    All shooters must be aware of their target and what is
                    behind it.
                  </li>
                  <li>Do Do not attempt to catch a falling handgun.</li>
                  <li>
                    Commands issued by Range Officers and Range personnel must
                    be obeyed immediately and without question
                  </li>
                  <li>
                    Children must be at least 10 years of age to be allowed on
                    the range. Children under the age of 18, if shooting a long
                    gun, or under 21 if shooting a handgun, must be accompanied
                    by their parent or guardian and supervised at all times.
                    PROOF OF GUARDIANSHIP IS REQUIRED.
                  </li>
                  <li>
                    All firearms not on the firing line must be unloaded with
                    their action open and the magazine removed.{' '}
                  </li>
                  <li>
                    Eye protection and ear protection are mandatory at all times
                    on the range.
                  </li>
                  <li>
                    Tracer, armor piercing, incendiary, steel case, and steel
                    core ammunition are prohibited.
                  </li>
                  <li>
                    No one other than the Range Officer may go forward of the
                    firing line.
                  </li>
                  <li>
                    Shooter must know and understand all of the operating and
                    safety features of the firearms they are using prior to
                    shooting in the range.
                  </li>
                  <li>
                    Report any unsafe activity or conditions to the Range
                    Officer or manager immediately.
                  </li>
                  <li>
                    Never bring a loaded weapon out of the range. If your gun
                    malfunctions, lay it in the booth facing downrange and
                    notify the Range Officer or use the booth intercom to
                    contact range personnel.
                  </li>
                  <li>
                    No firearm handling will be allowed outside the shooting
                    booth. No exceptions.
                  </li>
                  <li>
                    Food, beverages, gum, smoking and tobacco are prohibited on
                    the range.
                  </li>
                  <li>
                    When the command &quot;Cease Fire&quot; is given, stop
                    shooting IMMEDIATELY and wait for further instructions from
                    the Range Officer or Range manager.
                  </li>
                  <li>
                    Use the appropriate size target, placed at eye level, to
                    ensure that your shots strike the backstop and not the
                    floor, ceiling or target holder. Firing a shot on the range
                    that results in the bullet striking anything other than the
                    target and/or backstop, may result in a damage or repair
                    fine and/or your removal from the range.
                  </li>
                  <li>
                    Only shoot at your target, cross firing of targets is
                    prohibited.
                  </li>
                  <li>
                    Always wash your hands immediately after shooting and/or gun
                    cleaning.
                  </li>
                  <li>
                    Range Officers reserve the right to inspect all firearms and
                    ammunition for safety considerations.
                  </li>
                  <li>
                    Cell phone usage, including texting, is strictly prohibited
                    on the range.
                  </li>
                  <li>
                    Shotguns may be used on the range with slugs or 00 buck shot
                    only.
                  </li>
                  <li>Pistol-grip shotguns are not permitted on the range.</li>
                  <li>
                    If you have brought more than one firearm, use only one at a
                    time and, when finished, case the unloaded firearm and its
                    ammunition before uncasing the next one.
                  </li>
                  <li>
                    If you wish to draw from a holster, you must have been
                    qualified by a Range Officer and paid the Qualification Fee.
                    Double Tap or Point Shooting practice may only be executed
                    after notification of intent to do so with the Range
                    Officer. The shooter&apos;s ability will be observed and
                    continued practice will be at the sole discretion of the
                    Range Officer.
                  </li>
                  <li>No flash photography is permitted on the range.</li>
                  <li>
                    Pregnant or nursing mothers are not allowed on the range at
                    any time.
                  </li>
                  <li>
                    Shooters may collect their own brass behind the firing line.
                    Please be aware that routine cleaning (approximately every
                    15 minutes) of the walkways is needed in order to maintain
                    the cleanliness of the area, so pay prompt attention to any
                    brass that falls behind your shooting booth.
                  </li>
                  <li>All firearms used on the range must have sights.</li>
                  <li>No dark tinted glasses are permitted on the range.</li>
                  <li>
                    Shooters must shoot Range’s purchased ammunition from their
                    rental guns shop.
                  </li>
                  <li>
                    All outside firearm instructors must check in at the Range
                    Desk and must provide range with a certificate of insurance
                    for liability insurance covering firearm instruction and
                    naming range as an additional insured.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </AccountMainLayout>
    </>
  );
};

export default RangePolicy;
