import React, { useEffect, useState } from 'react';
import AccountMainLayout from '../Layout/AccountMainLayout';
import { Link, useNavigate } from 'react-router-dom';
// import Accordion from '../components/Accordion';
import { getApi } from '../utils/api';
import { API_BASE_URL, membershipCard, overview } from '../utils/endpoint';
import Cookies from 'js-cookie';
import { generatePrice, getGreeting } from '../utils/helpers';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner';
import { logoutUser } from '../utils/Storage';

const Overview = () => {
  const { personalDetail } = useSelector((state) => state.signup);
  const navigate = useNavigate();
  const [plan, setPlan] = useState({});
  const [upcomingInvoice, setUpcomingInvoice] = useState({});
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      personalDetail?.subscriptions?.length === 0 &&
      Cookies.get('isSubscribed') === undefined
    ) {
      navigate('/plans');
    }
  }, []);

  useEffect(() => {
    let access_token = Cookies.get('userToken');
    let data = localStorage.getItem('userData');
    setIsLoading(true);
    setUserData(JSON.parse(data));
    getApi(overview, access_token)
      .then((res) => {
        if (res?.data?.success) {
          let subscriptionType =
            res?.data?.data?.plans?.subscriptionInterval === 'month'
              ? 'Monthly'
              : 'Yearly';

          let obj = res?.data.data.plans;
          obj.subscriptionType = subscriptionType;

          setPlan(obj);
          setUpcomingInvoice(res?.data.data.upcomingInvoice);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (
          err?.response?.status === 401 &&
          err?.response?.statusText === 'Unauthorized'
        ) {
          logoutUser();
          navigate('/');
        }
        console.log(err);
      });
  }, []);

  return (
    <>
      <AccountMainLayout>
        <div className="account-login account-new pt-5">
          {isLoading && (
            <div className="loader">
              <ThreeDots
                height="110"
                width="110"
                radius="10"
                color="#aeaeae"
                ariaLabel="three-dots-loading"
                wrapperStyle={{ display: 'contents' }}
                wrapperClassName=""
                visible={true}
              />
            </div>
          )}
          {!isLoading && (
            <>
              <div className="card-user-id-number">
                <div className="container-fluid">
                  <div className="row">
                    <div className="over-view-heading">
                      <h1 className="sub-title theme-color">
                        {getGreeting()},{' '}
                        {Object.keys(personalDetail).length > 0
                          ? (personalDetail?.name || personalDetail?.fullName) +
                            '!'
                          : // + (personalDetail?.last_name || personalDetail?.lastName) +
                            // '!'
                            userData
                            ? userData?.fullName +
                              // ' ' +  userData?.lastName +
                              '!'
                            : 'Loading....'}
                      </h1>
                    </div>
                    {upcomingInvoice?.total !== 0 && (
                      <>
                        <h3 className="section-heading card-heading pt-4 pb-3">
                          Account Overview
                        </h3>
                        <div className="col-lg-4 mb-3">
                          <div className="card card-list">
                            <div className="card-header section-heading card-heading">
                              Upcoming Bill
                            </div>
                            <ul className="list-group list-group-flush">
                              <li className="dollar">
                                {upcomingInvoice?.total
                                  ? generatePrice(upcomingInvoice?.total / 100)
                                  : 'Loading......'}
                              </li>
                              <li className="next">Next Payment Date</li>
                              <li className="pay">
                                Auto Pay on{' '}
                                {upcomingInvoice?.next_payment_attempt
                                  ? moment
                                      .unix(
                                        upcomingInvoice?.next_payment_attempt
                                      )
                                      .format('MMMM DD, YYYY')
                                  : 'Loading......'}
                              </li>
                              <Link
                                to="/account-billing"
                                className="button-golden border-golden"
                              >
                                BILLING DETAILS
                              </Link>
                            </ul>
                          </div>
                        </div>
                      </>
                    )}
                    <div
                      className={`${
                        upcomingInvoice?.total !== 0 ? 'col-lg-4' : 'col-lg-6'
                      }  mb-3`}
                    >
                      <div className="card card-list">
                        <div className="card-header section-heading card-heading">
                          Your Package
                        </div>
                        <ul className="list-group list-group-flush">
                          <li className="dollar dollar-list">
                            <div className="web-image">
                              <img
                                src="../images/brand_emblem.svg"
                                alt="weblogo"
                                className="img-fluid"
                              />
                            </div>
                            <div className="web-text">
                              <p>Membership</p>
                              <h5>{plan?.name ? plan?.name : 'Loading...'}</h5>
                            </div>
                          </li>
                          <li className="next"></li>
                          <li className="pay"></li>
                          <Link
                            to="/account-coverage"
                            className="button-golden border-golden mt-4"
                          >
                            Reservations
                          </Link>
                        </ul>
                      </div>
                    </div>
                    <div
                      className={`${
                        upcomingInvoice?.total !== 0 ? 'col-lg-4' : 'col-lg-6'
                      }  mb-3`}
                    >
                      <div className="card card-list">
                        <div className="card-header section-heading card-heading">
                          Quick Links
                        </div>
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item link">
                            <Link
                              to="https://therangenetwork.com/contact/"
                              target="_blank"
                            >
                              Get Emergency help
                            </Link>
                            <i
                              className="fa fa-chevron-right"
                              aria-hidden="true"
                            ></i>
                          </li>
                          <li className="list-group-item link d-none">
                            <Link
                              to={
                                API_BASE_URL +
                                membershipCard +
                                personalDetail?.id
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              Download ID Card
                            </Link>
                            <i
                              className="fa fa-chevron-right"
                              aria-hidden="true"
                            ></i>
                          </li>
                          {/* <li className="list-group-item link">
                            <a href="#">Download ID Card</a>
                            <i
                              className="fa fa-chevron-right"
                              aria-hidden="true"
                            ></i>
                          </li> */}
                          <li className="list-group-item link">
                            <Link to="/profile-preferences">
                              Change Password
                              {/* /profile-preferences */}
                            </Link>
                            <i
                              className="fa fa-chevron-right"
                              aria-hidden="true"
                            ></i>
                          </li>
                          {/* <li className="list-group-item link">
                            <Link to="#">Legal Documents</Link>
                            <i
                              className="fa fa-chevron-right"
                              aria-hidden="true"
                            ></i>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                    <div className="col-12 d-none">
                      <h3 className="sub-title theme-color black-color py-4">
                        Right To Bear Training Series
                      </h3>
                      {/* <iframe

                        src="https://www.youtube.com/embed/N2uFsI7w1wA"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                        className="video-iframe"
                      ></iframe> */}
                      <div className="video-frame-2">
                        <div className="container-fluid gx-0">
                          <div className="row">
                            <video
                              src="../video/Right_To_Bear_Gun_Safety_Training(360p).mp4"
                              poster="../../images/Promo_Video_Image.png"
                              width={'100%'}
                              controls
                            ></video>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="row d-none">
                  <div className="col-lg-6 g-0">
                    <div className="t-shirt p-3">
                      <img
                        src="../images/t-shirt2.png"
                        alt="t-shirt2"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="t-shirt-heading">
                      <h2 className="sub-title">
                        GET YOUR RIGHT TO BEAR GEAR HERE!
                      </h2>
                      <Link
                        // to="https://teamlocker.squadlocker.com/#/lockers/right-to-bear"
                        className="theme-button login-button"
                      >
                        GEAR UP NOW
                      </Link>
                    </div>
                    <div className="shirt-line">
                      <img
                        src="../images/line.svg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <Accordion heading="LOOKING FOR INFORMATION?" /> */}
            </>
          )}
        </div>
      </AccountMainLayout>
    </>
  );
};

export default Overview;
