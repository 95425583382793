import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { paymentMethodApi } from '../utils/endpoint';
import Cookies from 'js-cookie';
import { postApi } from '../utils/api';
import PropTypes from 'prop-types';
import { state_city } from '../utils/state_cities';
// import { Tooltip as ReactTooltip } from 'react-tooltip';

const PaymentForm = ({ Heading, ButtonFirst }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMsg, SetErrorMsg] = useState('');
  const [isError, SetIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isBillingAddress, setIsBillingAddress] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const navigate = useNavigate();
  const statelist = Object.keys(state_city);
  const [formData, setFormData] = useState({
    fullName: '',
    phoneNumber: '',
    address: '',
    address2: '',
    state: '',
    city: '',
    country: 'United States',
    zipCode: '',
    isDefault: 0,
  });
  const [isDefault, setIsDefault] = useState(formData.isDefault);
  const formRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (!stripe || !elements) {
      return;
    }

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
      });

      if (error) {
        SetIsError(true);
        SetErrorMsg(error.message);
        setTimeout(() => {
          SetIsError(false);
        }, 4000);
        setIsLoading(false);
      }

      if (paymentMethod) {
        const formdata = new FormData();
        formdata.append('payment_method', paymentMethod.id);
        formdata.append('firstname', formData.fullName);
        formdata.append('lastname', '');
        formdata.append('street_address1', formData.address);
        formdata.append('street_address2', formData.address2);
        formdata.append('city', formData.city);
        formdata.append('country', formData.country);
        formdata.append('state', formData.state);
        formdata.append('postal_code', formData.zipCode);
        formdata.append('phone_number', formData.phoneNumber);
        formdata.append('is_default', isDefault);
        let access_token = Cookies.get('userToken');
        postApi(paymentMethodApi, formdata, access_token)
          .then((res) => {
            if (res.data.success) {
              setIsLoading(false);
              setSuccess(true);
              if (elements) {
                elements.getElement(CardNumberElement).clear();
                elements.getElement(CardExpiryElement).clear();
                elements.getElement(CardCvcElement).clear();
              }
              handleNavigate();
            } else {
              SetIsError(true);
              SetErrorMsg('Something went wrong. Please try again!');
              setTimeout(() => {
                SetIsError(false);
              }, 4000);
              setIsLoading(false);
            }
          })
          .catch((e) => {
            SetIsError(true);
            SetErrorMsg(e.response?.data?.message);
            setTimeout(() => {
              SetIsError(false);
            }, 4000);
            setIsLoading(false);
          });
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const handleNavigate = () => {
    navigate('/account-billing', {
      state: {
        dataState: 'paymentMethod',
      },
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleDefault = (e) => {
    let selected = e.target.checked === true ? 1 : 0;
    setIsDefault(selected);
  };

  const handleManualSubmit = async (e) => {
    e.preventDefault();
    const form = formRef.current;
    if (form.checkValidity()) {
      setFieldErrors({});
      await handleSubmit(e);
    } else {
      const newFieldErrors = {};
      for (let i = 0; i < form.elements.length; i++) {
        const field = form.elements[i];
        if (
          field.tagName === 'INPUT' ||
          field.tagName === 'TEXTAREA' ||
          field.tagName === 'SELECT'
        ) {
          if (!field.validity.valid) {
            newFieldErrors[field.name] = true;
          }
        }
      }
      setFieldErrors(newFieldErrors);
      console.log(newFieldErrors);
      form.reportValidity();
    }
  };
  return (
    <>
      <div className="container">
        <form id="earlyForm" ref={formRef}>
          <div className="row">
            <div className="close-modal">
              <h2 className="profile pro-changes">{Heading}</h2>
            </div>

            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="floating-group select-floating w-100 mb-f">
                    <CardNumberElement
                      className="form-control input-outline padding-add"
                      placeholder=""
                    />
                    <label htmlFor="name" className="form-label label-text">
                      Card Number
                    </label>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="floating-group select-floating w-100 mb-f">
                    <CardExpiryElement
                      className="form-control input-outline padding-add"
                      placeholder=""
                    />
                    <label htmlFor="name" className="form-label label-text">
                      MM / YY
                    </label>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="floating-group select-floating w-100 mb-f">
                    <CardCvcElement
                      className="form-control input-outline padding-add"
                      placeholder=""
                    />
                    <label htmlFor="name" className="form-label label-text">
                      CVC
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="bill-add-new">
              <p
                className="bill-show link-text"
                onClick={() => setIsBillingAddress(!isBillingAddress)}
              >
                + Add Billing Address
              </p>

              {isBillingAddress && (
                <div className="row">
                  <div className="col-lg-6">
                    {/* <ReactTooltip
                      id="fullName"
                      place="top"
                      variant="info"
                      content={fieldErrors.fullName}
                    /> */}
                    <div
                      className="floating-group floating-b mt-0"
                      data-tooltip-id="fullName"
                    >
                      <input
                        type="name"
                        className={
                          'form-control' +
                          (fieldErrors.fullName
                            ? ' error-input-border-red'
                            : '')
                        }
                        required
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="fullName">Full Name*</label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    {/* <ReactTooltip
                      id="phoneNumber"
                      place="top"
                      variant="info"
                      content={fieldErrors.phoneNumber}
                    /> */}
                    <div
                      className="form-floating floating-b mt-0"
                      data-tooltip-id="phoneNumber"
                    >
                      <input
                        type="tel"
                        maxLength="10"
                        minLength="10"
                        onKeyPress={(e) => {
                          const regex = /^[0-9\b]+$/;
                          if (!regex.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        className={
                          'form-control' +
                          (fieldErrors.phoneNumber
                            ? ' error-input-border-red'
                            : '')
                        }
                        required
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleInputChange}
                        placeholder="phone Number"
                      />
                      <label htmlFor="phoneNumber">Phone Number*</label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    {/* <ReactTooltip
                      id="address"
                      place="top"
                      variant="info"
                      content={fieldErrors.address}
                    /> */}
                    <div
                      className="floating-group floating-b"
                      data-tooltip-id="address"
                    >
                      <input
                        type="address"
                        required
                        name="address"
                        value={formData.address}
                        className={
                          'form-control' +
                          (fieldErrors.address ? ' error-input-border-red' : '')
                        }
                        onChange={handleInputChange}
                      />
                      <label htmlFor="address">Street Address 1*</label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-floating floating-b">
                      <input
                        type="address2"
                        name="address2"
                        value={formData.address2}
                        className="form-control"
                        onChange={handleInputChange}
                        placeholder="Street Address 2"
                      />
                      <label htmlFor="address2">Street Address 2</label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="floating-group floating-b select-floating">
                      <select
                        id="inputState"
                        className="form-select unite"
                        onChange={handleInputChange}
                        value={formData.country}
                      >
                        <option
                          value={'United States'}
                          defaultValue="United States"
                        >
                          United States
                        </option>
                      </select>
                      <label htmlFor="inputState">Country</label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    {/* <ReactTooltip
                      id="state"
                      place="top"
                      variant="info"
                      content={fieldErrors.state}
                    /> */}
                    <div
                      className="floating-group floating-b select-floating"
                      data-tooltip-id="state"
                    >
                      <select
                        id="inputState1"
                        onChange={handleInputChange}
                        value={formData.state}
                        name="state"
                        className={
                          'form-select unite' +
                          (fieldErrors.state ? ' error-input-border-red' : '')
                        }
                        required
                      >
                        <option value="" disabled defaultValue="">
                          State
                        </option>
                        {statelist.map((item, index) => (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        ))}
                      </select>
                      <label htmlFor="inputState1">State*</label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    {/* <ReactTooltip
                      id="city"
                      place="top"
                      variant="info"
                      content={fieldErrors.city}
                    /> */}
                    <div
                      className="floating-group floating-b"
                      data-tooltip-id="city"
                    >
                      <input
                        type="text"
                        className={
                          'form-control' +
                          (fieldErrors.city ? ' error-input-border-red' : '')
                        }
                        required
                        name="city"
                        value={formData.city}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="floatingSix">City*</label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    {/* <ReactTooltip
                      id="zipCode"
                      place="top"
                      variant="info"
                      content={fieldErrors.zipCode}
                    /> */}
                    <div
                      className="form-floating floating-b"
                      data-tooltip-id="zipCode"
                    >
                      <input
                        type="text"
                        className={
                          'form-control' +
                          (fieldErrors.zipCode ? ' error-input-border-red' : '')
                        }
                        required
                        name="zipCode"
                        value={formData.zipCode}
                        onChange={handleInputChange}
                        maxLength="5"
                        minLength="5"
                        onKeyPress={(e) => {
                          const regex = /^[0-9\b]+$/;
                          if (!regex.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        placeholder="Postal/ZIP code*"
                      />
                      <label htmlFor="floatingZip">Postal/ZIP code*</label>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-check mb-3 mt-4">
                      <input
                        className="form-check-input check-focus"
                        type="checkbox"
                        id="gridCheck"
                        name="isDefault"
                        checked={isDefault}
                        value={isDefault}
                        onChange={handleDefault}
                      />
                      <label
                        className="form-check-label df"
                        htmlFor="gridCheck"
                      >
                        Set as default address
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-12">
              {isError && (
                <h6 className="main-heading text-heading text-align-list text-center text-danger">
                  {errorMsg}
                </h6>
              )}
            </div>
            <div className="col-lg-12">
              <div className="button-listing-sub ">
                <button
                  type="submit"
                  onClick={handleManualSubmit}
                  className={`button-black size-button mt-0  ${
                    (!stripe || !elements || success || isLoading) &&
                    'disabled-btn'
                  }`}
                  disabled={
                    !stripe || !elements || success || isLoading ? true : false
                  }
                >
                  {isLoading ? (
                    <ThreeDots
                      height="20"
                      width="60"
                      radius="2"
                      color="#fff"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    ButtonFirst
                  )}
                </button>
                <span
                  id="cancel-id"
                  className="button-black  size-button size-border cancel-add mt-0"
                  onClick={() => {
                    if (elements) {
                      elements.getElement(CardNumberElement).clear();
                      elements.getElement(CardExpiryElement).clear();
                      elements.getElement(CardCvcElement).clear();
                    }
                    handleNavigate();
                  }}
                >
                  Cancel
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

PaymentForm.propTypes = {
  Heading: PropTypes.string,
  ButtonFirst: PropTypes.string,
  fetchPaymentMethod: PropTypes.func,
};
export default PaymentForm;
