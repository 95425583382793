import React from 'react';
import MainLayout from '../Layout/MainLayout';
import Map from '../components/Map';
import { getAuthToken } from '../utils/Storage';
import AccountMainLayout from '../Layout/AccountMainLayout';

const Coverage = () => {
  let userToken = getAuthToken('userToken');
  return (
    <>
      {userToken ? (
        <AccountMainLayout>
          <div className="account-login">
            <div className="about-banner banner-self d-none">
              <div className="about-outer">
                <h1 className="banner-heading">
                  Explore all the ways you can experience your passion.
                </h1>
              </div>
            </div>
            <h3 className="find-local">FIND YOUR LOCAL RANGE</h3>
            <Map />
          </div>
        </AccountMainLayout>
      ) : (
        <MainLayout>
          <div className="about-banner banner-self d-none">
            <div className="about-outer">
              <h1 className="banner-heading">
                Explore all the ways you can experience your passion.
              </h1>
            </div>
          </div>
          <div className="account-login">
            <h3 className="find-local">FIND YOUR LOCAL RANGE</h3>
            <Map />
          </div>
        </MainLayout>
      )}
    </>
  );
};

export default Coverage;
