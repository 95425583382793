import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Headroom from 'react-headroom';
import PropTypes from 'prop-types';

const MainLayout = ({ children, className }) => {
  return (
    <>
      <div className="wrapper">
        <Headroom>
          <Header />
        </Headroom>
        <div className={`wrapper-body ${className} `}>{children}</div>
        <Footer />
      </div>
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default MainLayout;
