import {
  GoogleMap,
  InfoWindow,
  Marker,
  StreetViewPanorama,
} from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import './App.css';
import PropTypes from 'prop-types';

const FindMap = ({ markerData, toggleShowList, isLoaded, showList }) => {
  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isMarkerClicked, setIsMarkerClicked] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();
  const markers = markerData ? markerData : [];
  const [selectedMarker, setSelectedMarker] = useState({
    lat: markers[0]?.lat ? Number(markers[0]?.lat) : 0,
    lng: markers[0]?.lng ? Number(markers[0]?.lng) : 0,
  });

  const onMapLoad = (map) => {
    setMapRef(map);
    const bounds = new window.google.maps.LatLngBounds();
    markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    map.fitBounds(bounds);
  };

  const handleMarkerClick = (id, lat, lng, address, phone, name, ind) => {
    mapRef?.panTo({ lat, lng });
    setInfoWindowData({ id, address, phone, name });
    setIsOpen(true);
    toggleShowList(ind, id);
    setIsMarkerClicked(true);
    setSelectedMarker({
      lat: lat,
      lng: lng,
    });
  };

  useEffect(() => {
    if (Object.keys(showList).length > 0 && !isMarkerClicked) {
      let { id, lat, lng, address, phone, name } =
        markers[Object.keys(showList)[0]];
      mapRef?.panTo({ lat, lng });
      setSelectedMarker({
        lat: lat,
        lng: lng,
      });
      setInfoWindowData({ id, address, phone, name });
      setIsOpen(true);
    } else {
      setIsMarkerClicked(false);
    }
  }, [showList]);

  useEffect(() => {
    setSelectedMarker({
      lat: markers[0]?.lat ? Number(markers[0]?.lat) : 0,
      lng: markers[0]?.lng ? Number(markers[0]?.lng) : 0,
    });
  }, [markers]);

  return (
    <div>
      {!isLoaded ? (
        <h3>Loading...</h3>
      ) : (
        <GoogleMap
          mapContainerClassName="map-select"
          onLoad={onMapLoad}
          onClick={() => setIsOpen(false)}
          options={{
            gestureHandling: 'greedy',
          }}
          zoom={1}
          center={selectedMarker}
        >
          {markers?.map(
            ({ address, lat, lng, phone, name, range_type_color, id }, ind) => (
              <Marker
                key={ind}
                position={{ lat, lng }}
                onClick={() => {
                  handleMarkerClick(id, lat, lng, address, phone, name, ind);
                }}
                icon={range_type_color}
              >
                {isOpen && infoWindowData?.id === id && (
                  <InfoWindow
                    onCloseClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    <div>
                      <h3>{infoWindowData.name}</h3>
                      <p>
                        {infoWindowData.address}
                        <br />
                        {infoWindowData.phone}
                      </p>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            )
          )}
          <StreetViewPanorama />
        </GoogleMap>
      )}
    </div>
  );
};

FindMap.propTypes = {
  markerData: PropTypes.array,
  toggleShowList: PropTypes.func,
  isLoaded: PropTypes.bool,
  showList: PropTypes.object,
};

export default FindMap;
