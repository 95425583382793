import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPackage } from '../redux/features/packages/packageSlice';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const PackageForm = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.package.packages);
  const navigate = useNavigate();
  const { personalDetail } = useSelector((state) => state.signup);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(fetchPackage());
    };
    fetchData();
  }, []);

  const handlePlan = (data) => {
    localStorage.setItem('selectedPlan', JSON.stringify(data));
    if (
      personalDetail?.subscriptions?.length === 0 &&
      Cookies.get('isSubscribed') === undefined
    ) {
      localStorage.setItem('joinEmail', personalDetail?.email);
      navigate('/plan-checkout');
    } else {
      navigate('/joinform');
    }
  };

  return (
    <>
      <div className="choose-plans-description">
        {data ? (
          <>
            {data.map((item, index) => (
              <div className="plans-price" key={index}>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="logo-form">
                        <img
                          src="../../images/footer-logo.webp"
                          alt=""
                          className="img-fluid"
                        />
                        <p className="plan-features">PLAN FEATURES</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      {item.packages
                        ?.filter(
                          (item) =>
                            item.add_on === 0 && item.name !== 'One Time Fee'
                        )
                        .map((package_data) => (
                          <div
                            className="associate-price"
                            key={package_data.id}
                          >
                            <h3 className="coverage">{package_data.name}</h3>
                            {package_data.package_prices.map((price, index) => (
                              <div key={index}>
                                <p className="monthly-price">
                                  <span>
                                    <sup className="currency-symbol">$</sup>
                                  </span>
                                  {Math.trunc(price.price)}
                                  <span className="month">
                                    /{' '}
                                    {price.price_frequency === 'Monthly'
                                      ? 'Month'
                                      : 'Year'}
                                  </span>
                                </p>
                                {index !==
                                  package_data.package_prices.length - 1 && (
                                  <div className="divider">
                                    <hr className="hr-line" />
                                    <span className="associate-price-divider">
                                      Or
                                    </span>
                                    <hr className="hr-line" />
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        ))}
                    </div>

                    <div className="table-list-user-plan">
                      <table className="table">
                        <tbody>
                          {item.features?.map((feature) => (
                            <tr className="table-list" key={feature.id}>
                              <td className="table-title">
                                <p className="table-heading">{feature.name}</p>
                              </td>
                              <td className="table-image">
                                <img
                                  src="../../images/right-check.svg"
                                  alt="right-check"
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      <h3 className="options">{item.name}</h3>
                      <table className="table">
                        <tbody>
                          {item.packages
                            ?.filter((item) => item.add_on !== 0)
                            .map((package_data) => (
                              <tr
                                className="coverage-table"
                                key={package_data.id}
                              >
                                <td className="table-plan">
                                  <h4>{package_data.name}</h4>
                                </td>
                                <td className="table-monthly">
                                  {package_data.package_prices.map(
                                    (price, index) => (
                                      <p
                                        className={`${
                                          price.price_frequency === 'Monthly'
                                            ? 'monthly-price'
                                            : 'yearly-price'
                                        } `}
                                        key={index}
                                      >
                                        ${Math.trunc(price.price)}/
                                        {price.price_frequency === 'Monthly'
                                          ? 'Month'
                                          : 'Year'}
                                      </p>
                                    )
                                  )}
                                </td>
                              </tr>
                            ))}
                          <tr className="plan-button">
                            <td className="table-plan"></td>
                            <td className="correct-image-1">
                              {item.packages
                                ?.filter(
                                  (item) =>
                                    item.add_on === 0 &&
                                    item.name === 'One Time Fee'
                                )
                                .map((package_data, index) => (
                                  <p className="fee" key={index}>
                                    **One-time $
                                    {package_data?.package_prices[0]?.price}{' '}
                                    Registration Fee**
                                  </p>
                                ))}

                              <a
                                className="button-golden"
                                onClick={() => handlePlan(item)}
                              >
                                select plan
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </>
  );
};

export default PackageForm;
