import React, { useEffect, useRef, useState } from 'react';
import Bill from '../../components/Bill';
import { getApi } from '../../utils/api';
import { generatePrice, handleTabAction } from '../../utils/helpers';
import { packages } from '../../utils/endpoint';
import { useDispatch, useSelector } from 'react-redux';
import { setBillingDetail } from '../../redux/features/signup/signupSlice';
import PropTypes from 'prop-types';

const Billing = ({ handleBilling, handleTabChange }) => {
  const [planList, setPlanList] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [total, setTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [selectedStripeIds, setSelectedStripeIds] = useState([]);
  const formRef = useRef(null);
  let packagePlan = localStorage.getItem('selectedPlan');
  let packagePlanType = localStorage.getItem('selectedPlanType');
  let packageTerm = localStorage.getItem('selectedTerm');
  let selectedPlan = packagePlan && JSON.parse(packagePlan);
  const [planYearly, setPlanYearly] = useState(
    selectedPlan !== undefined && selectedPlan !== null
      ? selectedPlan?.name
      : ''
  );
  const [selectPlanType, setSelectPlanType] = useState(
    packagePlanType
      ? packagePlanType === 'Lifetime'
        ? packageTerm === 'month'
          ? 'Monthly'
          : 'Yearly'
        : packagePlanType
      : 'Monthly'
  );
  const { billingDetail } = useSelector((state) => state.signup);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      getApi(packages)
        .then((res) => {
          setPlanList(res.data?.data);
          if (
            selectedPlan !== undefined &&
            selectedPlan !== null &&
            Object.keys(billingDetail).length === 0
          ) {
            let data = {};
            let planPackage = selectedPlan;
            data.planName = planPackage.name;
            data.id = planPackage.id;
            let stripeArray = [];
            let dummypackagePlanType = packagePlanType
              ? packagePlanType
              : selectPlanType;
            planPackage?.packages[0]?.package_prices
              .filter(
                (resData) => resData.price_frequency === dummypackagePlanType
              )
              .map((item) => {
                data.planPrice = item.price;
                data.priceFrequency = item.price_frequency;
                data.stripeId =
                  item.price_frequency !== 'Lifetime'
                    ? item.stripe_price_id
                    : item.id;
                stripeArray.push(
                  item.price_frequency !== 'Lifetime'
                    ? item.stripe_price_id
                    : item.id
                );
              });
            setSelectedPackage({
              billingOption: data,
            });
            setSelectedStripeIds(stripeArray);
          }
          if (planYearly === '' && Object.keys(billingDetail).length === 0) {
            setPlanYearly(res.data?.data[0]?.name);
            let data = {};
            let planPackage = res.data?.data[0];
            data.planName = planPackage.name;
            data.id = planPackage.id;
            let stripeArray = [];
            let getTerm = packagePlanType ? packagePlanType : 'Monthly';
            planPackage?.packages[0]?.package_prices
              .filter((resData) => resData.price_frequency === getTerm)
              .map((item) => {
                data.planPrice = item.price;
                data.priceFrequency = item.price_frequency;
                data.stripeId =
                  item.price_frequency !== 'Lifetime'
                    ? item.stripe_price_id
                    : item.id;
                stripeArray.push(
                  item.price_frequency !== 'Lifetime'
                    ? item.stripe_price_id
                    : item.id
                );
              });
            setSelectedPackage({
              billingOption: data,
            });
            setSelectedStripeIds(stripeArray);
          }

          if (Object.keys(billingDetail).length > 0) {
            setPlanYearly(
              billingDetail?.selectedPackage?.billingOption?.planName
            );
            setSelectedPackage(billingDetail.selectedPackage);
            setSelectedStripeIds(billingDetail.selectedStripeIds);
            setSelectPlanType(
              billingDetail.selectedPackage?.billingOption?.priceFrequency ===
                'Lifetime'
                ? packageTerm === 'month'
                  ? 'Monthly'
                  : 'Yearly'
                : billingDetail.selectedPackage?.billingOption?.priceFrequency
            );
          }
        })
        .catch((er) => {
          console.log(er);
        });
    };
    fetchData();
  }, []);

  useEffect(() => {
    let planAmount = selectedPackage?.billingOption
      ? selectedPackage?.billingOption?.planPrice
      : 0;

    let sum = Number(planAmount);
    setSubTotal(sum);
    // let tax = (sum * 5) / 100;
    let total = sum;
    // setTax(tax);
    setTotal(total);
  }, [selectedPackage]);

  const handleChangePlan = (e, plan_data) => {
    setPlanYearly(plan_data.name);
    let data = {};
    let stripeArray = [];
    data.planName = plan_data.name;
    data.id = plan_data.id;
    data.planPrice = plan_data.price;
    data.priceFrequency = plan_data.priceFrequency;
    data.stripeId = plan_data.stripeId;
    stripeArray.push(plan_data.stripeId);
    setSelectedStripeIds(stripeArray);
    setSelectedPackage({
      billingOption: data,
    });
  };
  const handleBack = () => {
    handleTabChange('profile');
    handleTabAction('billing', 'profile');
  };

  const handleNext = (e) => {
    e.preventDefault();
    handleTabAction('billing', 'questionnaire');
    let data = {
      selectedStripeIds,
      selectedPackage,
      total,
      subTotal,
    };
    dispatch(setBillingDetail(data));
    handleBilling('questionnaire');
  };

  const handleManualSubmit = (e) => {
    e.preventDefault();
    const form = formRef.current;
    if (form.checkValidity()) {
      handleNext(e);
    } else {
      form.reportValidity();
    }
  };

  const flatPlanList = planList.flatMap((item) =>
    item?.packages?.flatMap((planItem) =>
      planItem?.package_prices?.map((price) => ({
        id: item.id,
        planId: planItem.id,
        price: price.price,
        name: item.name,
        priceFrequency: price.price_frequency,
        stripeId:
          price.price_frequency !== 'Lifetime'
            ? price.stripe_price_id
            : price.id,
      }))
    )
  );

  const handlePlanType = (e) => {
    setSelectPlanType(e.target.value);
    let dummyList = flatPlanList?.filter(
      (filData) =>
        filData.priceFrequency === e.target.value ||
        filData.price_frequency === 'Lifetime'
    );
    handleChangePlan(e, dummyList[0]);
  };

  return (
    <>
      <div className="join-form-user">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <form ref={formRef}>
                <div className="row gx-3 pt-2">
                  <h4 className="billing-plan">PLAN SELECTION</h4>
                  <div className="col-md-12 col-lg-6 px-0 px-lg-2">
                    <div className="radioBtn">
                      <input
                        className="radio-input"
                        type="radio"
                        name="planradioIndex"
                        id={`planradioindex0`}
                        value="Monthly"
                        checked={selectPlanType === 'Monthly'}
                        onChange={handlePlanType}
                      />
                      <label
                        htmlFor={`planradioindex0`}
                        className="radio__label"
                      >
                        <p>Monthly</p>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6 px-0 px-lg-2">
                    <div className="radioBtn">
                      <input
                        className="radio-input"
                        type="radio"
                        name="planradioIndex"
                        id={`planradioindex1`}
                        value="Yearly"
                        checked={selectPlanType === 'Yearly'}
                        onChange={handlePlanType}
                      />
                      <label
                        htmlFor={`planradioindex1`}
                        className="radio__label"
                      >
                        <p>Yearly</p>
                      </label>
                    </div>
                  </div>
                  <div className="plan-set-card">
                    {flatPlanList &&
                      flatPlanList
                        ?.filter(
                          (filData) =>
                            filData.priceFrequency === selectPlanType ||
                            filData.priceFrequency === 'Lifetime'
                        )
                        .map((item, index) => (
                          <div className="radioBtn" key={index}>
                            <input
                              className="radio-input"
                              type="radio"
                              name="planradio"
                              id={`planradio${index}`}
                              value={item.name}
                              checked={planYearly === item.name}
                              onChange={(e) => handleChangePlan(e, item)}
                            />
                            <label
                              htmlFor={`planradio${index}`}
                              className="radio__label"
                            >
                              <p>
                                ${item.price}
                                {item.priceFrequency === 'Monthly'
                                  ? '/ Month'
                                  : item.priceFrequency === 'Yearly'
                                    ? '/ Year'
                                    : '/ One-time'}
                              </p>
                              <p>{item.name}</p>
                            </label>
                          </div>
                        ))}
                  </div>
                  <hr />
                  <div className="button-back">
                    <a onClick={handleBack} className="back-text">
                      Back
                    </a>
                    <button
                      type="button"
                      onClick={handleManualSubmit}
                      className="theme-button mobile-button-view"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-4">
              <Bill
                Heading="PLAN SUMMARY"
                Yearly={
                  selectedPackage
                    ? selectedPackage?.billingOption?.planName
                    : 'Associate Coverage Yearly'
                }
                Dollar={
                  selectedPackage
                    ? selectedPackage?.billingOption?.planPrice &&
                      '$' + selectedPackage?.billingOption?.planPrice
                    : '$125.00'
                }
                PlanUser="Plan"
                PlanType={
                  selectedPackage
                    ? selectedPackage?.billingOption?.priceFrequency ===
                      'Yearly'
                      ? '/ Year'
                      : selectedPackage?.billingOption?.priceFrequency ===
                          'Monthly'
                        ? '/ Month'
                        : '/ One-time'
                    : '/ Month'
                }
                Price={
                  selectedPackage
                    ? selectedPackage?.billingOption?.planPrice &&
                      '$' + selectedPackage?.billingOption?.planPrice
                    : '$125.00'
                }
                Add="Add Ons"
                AddPrice={
                  selectedPackage
                    ? selectedPackage?.addonPrice === undefined ||
                      selectedPackage?.addonPrice === null ||
                      isNaN(selectedPackage?.addonPrice) ||
                      selectedPackage?.addonPrice === 0
                      ? '-'
                      : generatePrice(selectedPackage?.addonPrice)
                    : '-'
                }
                Additional="Additional Associate One Time Fee"
                AdditionalPrice={
                  selectedPackage
                    ? selectedPackage?.additionalAddon === undefined ||
                      selectedPackage?.additionalAddon === 0
                      ? '-'
                      : generatePrice(selectedPackage?.additionalAddon)
                    : '-'
                }
                Tax="Tax (0.00)"
                TaxPrice={generatePrice(0)}
                Total="Total"
                TotalPrice={generatePrice(total)}
                Time="One Time Registration Fee"
                TimePrice="$0.00"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Billing.propTypes = {
  handleBilling: PropTypes.func,
  handleTabChange: PropTypes.func,
};

export default Billing;
