import React, { useEffect, useRef, useState } from 'react';
import Bill from '../../components/Bill';
import { getApi } from '../../utils/api';
import { generatePrice, handleTabAction } from '../../utils/helpers';
import { packages } from '../../utils/endpoint';
import { useDispatch, useSelector } from 'react-redux';
import { setBillingDetail } from '../../redux/features/signup/signupSlice';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const AddressRange = ({ handleBilling, handleTabChange }) => {
  const [plan, setPlan] = useState([]);
  const [planYearly, setPlanYearly] = useState('Monthly');
  const [addPeople, setAddPeople] = useState('');
  const [isAddPeople, setIsAddPeople] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [selectedPackage, setSelectedPackage] = useState({});
  const [total, setTotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [subTotal, setSubTotal] = useState(0);

  const [selectedStripeIds, setSelectedStripeIds] = useState([]);

  const [selectedStripeAdditionalIds, setSelectedStripeAdditionalIds] =
    useState([]);
  const [checkedItemsAddPeople, setCheckedItemsAddPeople] = useState({});
  const [peopleDetail, setPeopleDetail] = useState({
    firstName: '',
    lastName: '',
  });
  const formRef = useRef(null);
  let packagePlan = localStorage.getItem('selectedPlan');
  let selectedPlan = packagePlan ? JSON.parse(packagePlan) : plan;

  const { billingDetail } = useSelector((state) => state.signup);

  const dispatch = useDispatch();
  console.log(
    addPeople,
    setSelectedStripeAdditionalIds,
    checkedItemsAddPeople,
    setPeopleDetail
  );
  useEffect(() => {
    const fetchData = async () => {
      getApi(packages)
        .then((res) => {
          setPlan(res.data?.data[0]);
          let packagePlan = res.data?.data[0]?.packages[0];
          let data = {};
          data.planName = packagePlan.name + ' Monthly';
          data.id = packagePlan.id;
          let stripeArray = [];
          packagePlan?.package_prices
            .filter((item) => item.price_frequency === planYearly)
            .map((item) => {
              data.planPrice = Number(item.price);
              data.priceFrequency = item.price_frequency;
              data.stripeId = item.stripe_price_id;
              stripeArray.push(item.stripe_price_id);
            });
          setSelectedPackage({
            billingOption: data,
          });
          setSelectedStripeIds(stripeArray);
          if (Object.keys(billingDetail).length > 0) {
            let packages = res.data?.data[0]?.packages;
            setPlanYearly(
              billingDetail?.selectedPackage?.billingOption?.priceFrequency
            );

            setSelectedPackage(billingDetail.selectedPackage);

            const addonIdsSet = new Set(
              billingDetail?.selectedPackage?.addons?.map((addon) => addon.id)
            );
            const defaultCheckedItems = packages
              ?.filter(
                (item) =>
                  item.add_on !== 0 &&
                  item.name !== 'Additional Associate Coverage'
              )
              ?.reduce((acc, item, index) => {
                acc[index] = addonIdsSet.has(item.id);
                return acc;
              }, {});

            setCheckedItems(defaultCheckedItems);
          }
        })
        .catch((er) => {
          console.log(er);
        });
    };
    fetchData();
  }, []);

  useEffect(() => {
    let planAmount = selectedPackage?.billingOption
      ? selectedPackage?.billingOption?.planPrice
      : 0;
    let addonAmount = selectedPackage?.addonPrice
      ? selectedPackage?.addonPrice
      : 0;
    // let additionalAddonAmount = selectedPackage?.additionalAddon
    //   ? selectedPackage?.additionalAddon
    //   : 0;
    let sum = Number(planAmount) + Number(addonAmount);
    setSubTotal(sum + 15);
    let tax = (sum * 5) / 100;
    let total = tax + sum;
    setTax(tax);
    setTotal(total);
  }, [selectedPackage]);

  const handleChangePlan = (e, plan) => {
    setPlanYearly(e.target.value);
    setCheckedItems({});
    setCheckedItemsAddPeople({});
    setSelectedStripeIds([]);
    setAddPeople('');
    setSelectedPackage({});
    setIsAddPeople(false);
    let data = {};
    let stripeArray = [];
    if (e.target.value === 'Yearly') {
      data.planName = plan.name + ' Yearly';
      data.id = plan.id;
      plan.package_prices
        .filter((item) => item.price_frequency === 'Yearly')
        .map((item) => {
          data.planPrice = item.price;
          data.priceFrequency = item.price_frequency;
          data.stripeId = item.stripe_price_id;
          stripeArray.push(item.stripe_price_id);
        });
    }
    if (e.target.value === 'Monthly') {
      data.planName = plan.name + ' Monthly';
      data.id = plan.id;
      plan.package_prices
        .filter((item) => item.price_frequency === 'Monthly')
        .map((item) => {
          data.planPrice = item.price;
          data.priceFrequency = item.price_frequency;
          data.stripeId = item.stripe_price_id;
          stripeArray.push(item.stripe_price_id);
        });
    }
    setSelectedStripeIds(stripeArray);
    setCheckedItems({
      0: false,
      1: false,
      2: false,
    });
    setSelectedPackage({
      billingOption: data,
    });
  };

  const handleCheckboxChange = (data, index) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [index]: !prevCheckedItems[index],
    }));

    let customData = {
      id: data.id,
    };
    let price, stripeId;
    data.package_prices
      .filter((item) => item.price_frequency === planYearly)
      .map((item) => {
        price = Number(item.price);
        customData.planName = data.name + ' ' + item.price_frequency;
        customData.planPrice = item.price;
        customData.priceFrequency = item.price_frequency;
        customData.stripeId = item.stripe_price_id;

        stripeId = item.stripe_price_id;
      });

    setSelectedPackage((prevItems) => {
      const newAddons = prevItems.addons ? [...prevItems.addons] : [];
      const isDataInArray = newAddons.some(
        (item) => JSON.stringify(item) === JSON.stringify(customData)
      );
      if (isDataInArray) {
        const filteredAddons = newAddons.filter(
          (item) => JSON.stringify(item) !== JSON.stringify(customData)
        );
        let sum;

        sum = prevItems.addonPrice - price;
        return {
          ...prevItems,
          addons: filteredAddons,
          addonPrice: sum,
        };
      } else {
        const updatedAddons = [...newAddons, customData];
        let sum = prevItems.addonPrice ? prevItems.addonPrice : 0;
        sum = sum + price;
        return {
          ...prevItems,
          addons: updatedAddons,
          addonPrice: sum,
        };
      }
    });

    setSelectedStripeIds((prevItems) => {
      const newId = prevItems ? [...prevItems] : [];
      const isDataInArray = newId.includes(stripeId);
      if (isDataInArray) {
        const filteredId = newId.filter((item) => item !== stripeId);
        return filteredId;
      } else {
        return [...prevItems, stripeId];
      }
    });
  };

  // important code start
  // const handleChangeAddPeople = (e, data) => {
  //   setIsAddPeople(!isAddPeople);
  //   setCheckedItemsAddPeople({});
  //   setPeopleDetail({
  //     firstName: "",
  //     lastName: "",
  //   });
  //   setSelectedStripeAdditionalIds([]);
  //   let customData = {
  //     id: data.id,
  //   };
  //   let price, stripeId;
  //   data.package_prices
  //     .filter((item) => item.price_frequency === planYearly)
  //     .map((item) => {
  //       price = Number(item.price);
  //       customData.planName = data.name + " " + item.price_frequency;
  //       customData.planPrice = item.price;
  //       customData.priceFrequency = item.price_frequency;
  //       customData.stripeId = item.stripe_price_id;
  //       stripeId = item.stripe_price_id;
  //     });
  //   setSelectedPackage((prevItems) => {
  //     const newAdditionalAddons = prevItems.additionalAddons
  //       ? [...prevItems.additionalAddons]
  //       : [];
  //     const isDataInArray = newAdditionalAddons.some(
  //       (item) => JSON.stringify(item) === JSON.stringify(customData)
  //     );
  //     if (isDataInArray) {
  //       const filteredAdditionalAddons = newAdditionalAddons.filter(
  //         (item) => JSON.stringify(item) !== JSON.stringify(customData)
  //       );
  //       let sum, sumAdditional;
  //       let demo = prevItems.addonPrice - prevItems.additionalAddonPrice;
  //       sum = demo - price;
  //       sumAdditional = 0;
  //       return {
  //         ...prevItems,
  //         additionalAddons: filteredAdditionalAddons,
  //         additionalAddonsPeople: [],
  //         addonPrice: sum,
  //         additionalAddon: 0,
  //         additionalAddonPrice: sumAdditional,
  //       };
  //     } else {
  //       const updatedAddons = [...newAdditionalAddons, customData];
  //       let sum = prevItems.addonPrice ? prevItems.addonPrice : 0;
  //       sum = sum + price;
  //       return {
  //         ...prevItems,
  //         additionalAddons: updatedAddons,
  //         addonPrice: sum,
  //         additionalAddon: price,
  //       };
  //     }
  //   });

  //   setSelectedStripeIds((prevItems) => {
  //     const newId = prevItems ? [...prevItems] : [];
  //     const isDataInArray = newId.includes(stripeId);
  //     if (isDataInArray) {
  //       const filteredId = newId.filter((item) => item !== stripeId);
  //       return filteredId;
  //     } else {
  //       return [...prevItems, stripeId];
  //     }
  //   });
  // };

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setPeopleDetail((prevFormData) => ({
  //     ...prevFormData,
  //     [name]: value,
  //   }));
  // };

  // const handleCheckAddPeopleChange = (data, index) => {
  //   setCheckedItemsAddPeople((prevCheckedItems) => ({
  //     ...prevCheckedItems,
  //     [index]: !prevCheckedItems[index],
  //   }));
  //   let customData = {
  //     id: data.id,
  //   };
  //   let price, stripeId;
  //   data.package_prices
  //     .filter((item) => item.price_frequency === planYearly)
  //     .map((item) => {
  //       price = Number(item.price);
  //       customData.planName = data.name + " " + item.price_frequency;
  //       customData.planPrice = item.price;
  //       customData.priceFrequency = item.price_frequency;
  //       customData.stripeId = item.stripe_price_id;
  //       stripeId = item.stripe_price_id;
  //     });
  //   setSelectedPackage((prevItems) => {
  //     const newAdditionalAddonsPeople = prevItems.additionalAddonsPeople
  //       ? [...prevItems.additionalAddonsPeople]
  //       : [];
  //     const isDataInArray = newAdditionalAddonsPeople.some(
  //       (item) => JSON.stringify(item) === JSON.stringify(customData)
  //     );
  //     if (isDataInArray) {
  //       const filteredAdditionalAddonsPeople = newAdditionalAddonsPeople.filter(
  //         (item) => JSON.stringify(item) !== JSON.stringify(customData)
  //       );
  //       let sum, sumAdditional;
  //       sum = prevItems.addonPrice - price;
  //       sumAdditional = prevItems.additionalAddonPrice - price;

  //       return {
  //         ...prevItems,
  //         additionalAddonsPeople: filteredAdditionalAddonsPeople,
  //         addonPrice: sum,
  //         additionalAddonPrice: sumAdditional,
  //       };
  //     } else {
  //       const updatedAddons = [...newAdditionalAddonsPeople, customData];
  //       let sum = prevItems.addonPrice ? prevItems.addonPrice : 0;
  //       let sumAdditional = prevItems.additionalAddonPrice
  //         ? prevItems.additionalAddonPrice
  //         : 0;
  //       sum = sum + price;
  //       sumAdditional = sumAdditional + price;

  //       return {
  //         ...prevItems,
  //         additionalAddonsPeople: updatedAddons,
  //         addonPrice: sum,
  //         additionalAddonPrice: sumAdditional,
  //       };
  //     }
  //   });
  //   setSelectedStripeAdditionalIds((prevItems) => {
  //     const newId = prevItems ? [...prevItems] : [];
  //     const isDataInArray = newId.includes(stripeId);
  //     if (isDataInArray) {
  //       const filteredId = newId.filter((item) => item !== stripeId);
  //       return filteredId;
  //     } else {
  //       return [...prevItems, stripeId];
  //     }
  //   });
  // };
  // important code end
  const handleBack = () => {
    handleTabChange('profile');
    handleTabAction('billing', 'profile');
  };

  const handleNext = (e) => {
    e.preventDefault();
    handleTabAction('billing', 'questionnaire');
    let data = {
      selectedStripeIds,
      selectedStripeAdditionalIds,
      selectedPackage,
      isAddPeople,
      peopleDetail,
      total,
      tax,
      subTotal,
    };
    dispatch(setBillingDetail(data));
    handleBilling('questionnaire');
  };

  const handleManualSubmit = (e) => {
    e.preventDefault();
    const form = formRef.current;
    if (form.checkValidity()) {
      handleNext(e);
    } else {
      form.reportValidity();
    }
  };

  return (
    <>
      <div className="join-form-user">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8 d-none">
              <form ref={formRef}>
                <div className="row gx-3 pt-2">
                  <h4 className="billing-plan">BILLING OPTIONS</h4>
                  {selectedPlan.packages
                    ?.filter(
                      (item) =>
                        item.add_on === 0 && item.name !== 'One Time Fee'
                    )
                    ?.map((package_data) => (
                      <>
                        {package_data.package_prices.map((price, index) => (
                          <div className="col-md-12 col-lg-6" key={index}>
                            <div className="radioBtn">
                              <input
                                className="radio-input"
                                type="radio"
                                name="planradio"
                                id={`planradio${index}`}
                                value={price.price_frequency}
                                checked={price.price_frequency === planYearly}
                                onChange={(e) =>
                                  handleChangePlan(e, package_data)
                                }
                              />
                              <label
                                htmlFor={`planradio${index}`}
                                className="radio__label"
                              >
                                <p>
                                  ${price.price} {package_data.name}{' '}
                                  {price.price_frequency === 'Monthly'
                                    ? 'Monthly'
                                    : 'Yearly'}
                                </p>
                              </label>
                            </div>
                          </div>
                        ))}
                      </>
                    ))}
                  <h4 className="billing-plan pt-4 pb-2">
                    ADDITIONAL COVERAGE & ADDONS
                  </h4>
                  {selectedPlan.packages
                    ?.filter(
                      (item) =>
                        item.add_on !== 0 &&
                        item.name !== 'Additional Associate Coverage'
                    )
                    ?.map((item, index) => (
                      <div className="col-md-6 col-lg-4" key={index}>
                        {item.package_prices
                          ?.filter(
                            (item) => item.price_frequency === planYearly
                          )
                          .map((price, ind) => (
                            <div className="form-group" key={ind}>
                              <input
                                type="checkbox"
                                name="cab_booking"
                                id={'later' + index}
                                className="check-box-list"
                                onChange={() =>
                                  handleCheckboxChange(item, index)
                                }
                                checked={checkedItems[index]}
                              />
                              <label
                                htmlFor={'later' + index}
                                className="later-book"
                              >
                                {item.name}
                                {planYearly === 'Yearly'
                                  ? ' Yearly '
                                  : ' Monthly '}
                                - ${price.price}
                              </label>
                            </div>
                          ))}
                      </div>
                    ))}
                  {/*  important code start */}
                  {/* <h4 className="billing-plan py-4">ADD PEOPLE</h4>
                  {selectedPlan.packages
                    ?.filter(
                      (item) => item.name === "Additional Associate Coverage"
                    )
                    .map((_package, index) => (
                      <div className="col-md-12 col-lg-6 mb-4" key={index}>
                        {_package.package_prices
                          ?.filter(
                            (item) => item.price_frequency === planYearly
                          )
                          .map((price, ind) => (
                            <div className="radioBtn" key={ind}>
                              <input
                                className="radio-input"
                                type="checkbox"
                                name="myradio-2"
                                id={"myradio3" + index}
                                value={_package.name}
                                checked={isAddPeople}
                                onChange={(e) =>
                                  handleChangeAddPeople(e, _package)
                                }
                              />
                              <label
                                htmlFor={"myradio3" + index}
                                className="radio__label"
                              >
                                <p>
                                  {_package.name}
                                  {planYearly === "Yearly"
                                    ? " Yearly "
                                    : " Monthly "}
                                  - ${price.price}
                                </p>
                              </label>
                            </div>
                          ))}
                      </div>
                    ))}

                  <div className="col-md-12 col-lg-6"></div>
                  {isAddPeople && (
                    <>
                      <div className="col-6 col-md-6 col-lg-3">
                        <input
                          type="name"
                          className="input-outline mt-2 mb-4"
                          placeholder="First Name"
                          value={peopleDetail.firstName}
                          name="firstName"
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="col-6 col-md-6 col-lg-3">
                        <input
                          type="name"
                          className="input-outline mt-2 mb-4"
                          placeholder="Last Name"
                          value={peopleDetail.lastName}
                          name="lastName"
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="col-md-12"></div>
                      {selectedPlan.packages
                        ?.filter(
                          (item) =>
                            item.add_on !== 0 &&
                            item.name !== "Additional Associate Coverage"
                        )
                        ?.map((item, index) => (
                          <div className="col-md-6 col-lg-4" key={index}>
                            {item.package_prices
                              ?.filter(
                                (item) => item.price_frequency === planYearly
                              )
                              .map((price, ind) => (
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    name="cab_booking"
                                    id={"laterOne" + index}
                                    className="check-box-list"
                                    onChange={() =>
                                      handleCheckAddPeopleChange(item, index)
                                    }
                                    checked={checkedItemsAddPeople[index]}
                                  />
                                  <label
                                    htmlFor={"laterOne" + index}
                                    className="later-book"
                                  >
                                    {item.name}
                                    {planYearly === "Yearly"
                                      ? " Yearly "
                                      : " Monthly "}
                                    - ${price.price}
                                  </label>
                                </div>
                              ))}
                          </div>
                        ))}
                    </>
                  )} */}

                  {/*  important code end */}

                  <hr className="mt-3" />
                  <div className="button-back">
                    <a onClick={handleBack} className="back-text">
                      Back
                    </a>
                    <button
                      type="button"
                      onClick={handleManualSubmit}
                      className="theme-button mobile-button-view"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-8 d-none">
              <div className="row">
                <div className="billing-range">
                  <h4 className="sub-title-2">
                    Range Membership Application - The Range Network
                  </h4>
                </div>
                <div className="col-md-12">
                  <label htmlFor="email" className="form-label label-text">
                    Email Address:
                  </label>
                  <input type="email" className="form-control input-outline" />
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="TypesOfMemberships*"
                    className="form-label label-text mb-md-4 "
                  >
                    Cummunication Preferences
                  </label>

                  <div className="select-mm">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Weekly"
                      />
                      <label className="form-check-label" htmlFor="Weekly">
                        Weekly E-Newsletter
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Notifications"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="Notifications"
                      >
                        Send Email Notifications
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="TypesOfMemberships*"
                    className="form-label label-text mb-md-0 pt-3"
                  >
                    Member Directory Options
                  </label>
                  <p className="mb-0">
                    Please Note: This only applies to Ranges,
                  </p>
                  <div className="select-mm">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Exclude"
                      />
                      <label className="form-check-label" htmlFor="Exclude">
                        Exclude From Directory?
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <label
                    htmlFor="name"
                    className="form-label label-text py-md-4 pt-md-0 pt-3"
                  >
                    Members&lsquo;s Area Access Info:
                  </label>
                  <p>Choose a Username:</p>
                  <input type="text" className="form-control input-outline" />
                  <p>Enter a username you cna easily remember</p>
                </div>
                <div className="col-md-12">
                  <div className="last">
                    <Link to="#" className="back">
                      Back
                    </Link>
                    <Link to="#" className="theme-button">
                      Continue
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="name" className="form-label label-text">
                    Address*
                  </label>
                  <input
                    type="address"
                    name="address"
                    className="form-control input-outline"
                    // value={formData.address}
                    // onChange={handleInputChange}
                    required
                  />
                  {/* {errors.address && (
                    <div style={styleClass}>{errors.address}</div>
                  )} */}
                </div>
                <div className="col-md-12">
                  <label htmlFor="name" className="form-label label-text">
                    Address Line 2:
                  </label>
                  <input
                    type="address"
                    name="address2"
                    className="form-control input-outline"
                    // value={formData.address2}
                    // onChange={handleInputChange}
                  />
                  {/* {errors.address2 && (
                    <div style={styleClass}>{errors.address2}</div>
                  )} */}
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputCity1" className="form-label label-text">
                    City*
                  </label>
                  <input
                    type="text"
                    className="form-control input-outline"
                    id="inputCity1"
                    name="city"
                    // onChange={handleInputChange}
                    // value={formData.city}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="inputState1"
                    className="form-label label-text"
                  >
                    State*
                  </label>
                  <select
                    id="inputState1"
                    className="form-select input-outline"
                    name="state"
                    // onChange={handleInputChange}
                    // value={formData.state}
                    required
                  >
                    <option value="">Select State</option>
                    {/* {statelist.map((item, index) => (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    ))} */}
                  </select>
                </div>
                <div className="col-md-6">
                  <label htmlFor="zipCode" className="form-label label-text">
                    Postal/ZIP code*
                  </label>
                  <input
                    type="text"
                    className="form-control input-outline"
                    id="inputZip1"
                    name="zipCode"
                    // onChange={handleInputChange}
                    // value={formData.zipCode}
                    maxLength="5"
                    onKeyPress={(e) => {
                      const regex = /^[0-9\b]+$/;
                      if (!regex.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    required
                  />
                </div>
                <div className="col-md-6 d-none">
                  <label
                    htmlFor="inputState1"
                    className="form-label label-text"
                  >
                    County:
                  </label>
                  <input type="text" className="input-outline" />
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="inputState1"
                    className="form-label label-text"
                  >
                    Country:
                  </label>
                  <select
                    id="inputState1"
                    className="form-select input-outline"
                    name="billingState"
                    // onChange={handleInputChange}
                    // value={formData.billingState}
                  >
                    <option selected>Select State</option>
                    {/* {statelist.map((item, index) => (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    ))} */}
                  </select>
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="phoneNumber"
                    className="form-label label-text"
                  >
                    Phone*
                  </label>
                  <input
                    type="tel"
                    maxLength="10"
                    className="form-control input-outline"
                    // value={formData.phoneNumber}
                    name="phoneNumber"
                    // onChange={handleInputChange}
                    onKeyPress={(e) => {
                      const regex = /^[0-9\b]+$/;
                      if (!regex.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    required
                  />
                  {/* {errors.phoneNumber && (
                    <div style={styleClass}>{errors.phoneNumber}</div>
                  )} */}
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="secondaryPhone"
                    className="form-label label-text"
                  >
                    Secondary Phone:
                  </label>
                  <input
                    type="tel"
                    maxLength="10"
                    className="form-control input-outline"
                    // value={formData.secondaryPhone}
                    name="secondaryPhone"
                    // onChange={handleInputChange}
                    onKeyPress={(e) => {
                      const regex = /^[0-9\b]+$/;
                      if (!regex.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {/* {errors.secondaryPhone && (
                    <div style={styleClass}>{errors.secondaryPhone}</div>
                  )} */}
                </div>
                <div className="col-md-12">
                  <label
                    htmlFor="RangeWebsite"
                    className="form-label label-text"
                  >
                    Range Website*
                  </label>
                  <input
                    type="text"
                    className="form-control input-outline"
                    required
                  />
                </div>
                <div className="col-md-12">
                  <div className="last">
                    <Link to="/membership-application" className="back">
                      Back
                    </Link>
                    <Link
                      to="#"
                      className="theme-button"
                      // onClick={handleManualSubmit}
                    >
                      Continue
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <Bill
                Heading="PLAN SUMMARY"
                Yearly={
                  selectedPackage
                    ? selectedPackage?.billingOption?.planName
                    : 'Associate Coverage Yearly'
                }
                Dollar={
                  selectedPackage
                    ? selectedPackage?.billingOption?.planPrice &&
                      '$' + selectedPackage?.billingOption?.planPrice
                    : '$125.00'
                }
                PlanUser="Plan"
                PlanType={
                  selectedPackage
                    ? selectedPackage?.billingOption?.priceFrequency ===
                      'Yearly'
                      ? '/ Year'
                      : selectedPackage?.billingOption?.priceFrequency ===
                          'Monthly'
                        ? '/ Month'
                        : '/ One-time'
                    : '/ Month'
                }
                Price={
                  selectedPackage
                    ? selectedPackage?.billingOption?.planPrice &&
                      '$' + selectedPackage?.billingOption?.planPrice
                    : '$125.00'
                }
                Add="Add Ons"
                AddPrice={
                  selectedPackage
                    ? selectedPackage?.addonPrice === undefined ||
                      selectedPackage?.addonPrice === null ||
                      isNaN(selectedPackage?.addonPrice) ||
                      selectedPackage?.addonPrice === 0
                      ? '-'
                      : generatePrice(selectedPackage?.addonPrice)
                    : '-'
                }
                isAdditional={isAddPeople}
                Time="One Time Fee"
                TimePrice="$0.00"
                Additional="Additional Associate One Time Fee"
                AdditionalPrice={
                  selectedPackage
                    ? selectedPackage?.additionalAddon === undefined ||
                      selectedPackage?.additionalAddon === 0
                      ? '-'
                      : generatePrice(selectedPackage?.additionalAddon)
                    : '-'
                }
                Tax="Tax (5.00%)"
                TaxPrice={generatePrice(tax)}
                Total="Total"
                TotalPrice={generatePrice(total)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

AddressRange.propTypes = {
  handleBilling: PropTypes.func,
  handleTabChange: PropTypes.func,
};
export default AddressRange;
