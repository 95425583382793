import React from 'react';
import AccountMainLayout from '../Layout/AccountMainLayout';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from '../components/PaymentForm';
import { STRIPE_PUBLISHABLE_KEY, paymentMethodApi } from '../utils/endpoint';
import { getApi } from '../utils/api';
import Cookies from 'js-cookie';
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
const PaymentMethodNew = () => {
  const access_token = Cookies.get('userToken');

  const fetchPaymentMethod = () => {
    getApi(paymentMethodApi, access_token)
      .then((res) => {
        if (res?.data?.success) {
          //   setPaymentMethodList(res?.data.data);
          //   setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <AccountMainLayout>
        <div className="account-login payment-new">
          <div className="container-fluid">
            <div className="row">
              <Elements stripe={stripePromise}>
                <PaymentForm
                  Heading="Add a new payment method"
                  ButtonFirst="Add"
                  fetchPaymentMethod={fetchPaymentMethod}
                />
              </Elements>
            </div>
          </div>
        </div>
      </AccountMainLayout>
    </>
  );
};

export default PaymentMethodNew;
