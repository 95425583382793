import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { useEffect, useRef, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { getApi, postApi } from '../utils/api';
import {
  STRIPE_PUBLISHABLE_KEY,
  checkCouponApi,
  lifetimeSubscribe,
  subscribe,
} from '../utils/endpoint';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { Link, useNavigate } from 'react-router-dom';
import { generatePrice } from '../utils/helpers';
import { state_city } from '../utils/state_cities';
import { deleteLocalData, getLocalData } from '../utils/Storage';
import ErrorBanner from './ErrorBanner';
import { setIsSubscribed } from '../redux/features/signup/signupSlice';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const CheckoutForm = () => {
  const { billingDetail, personalDetail } = useSelector(
    (state) => state.signup
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [errorMsg, SetErrorMsg] = useState('');
  const [isError, SetIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [associateCode, setAssociateCode] = useState(
    getLocalData('referralCode') ? true : false
  );
  const [formData, setFormData] = useState({
    address: personalDetail?.address ? personalDetail?.address : '',
    address2: personalDetail?.address2 ? personalDetail?.address2 : '',
    state: personalDetail?.state ? personalDetail?.state : '',
    city: personalDetail?.city ? personalDetail?.city : '',
    zipCode: personalDetail?.zipCode
      ? personalDetail?.zipCode
      : personalDetail?.zipcode
        ? personalDetail?.zipcode
        : '',
    associateCode: '',
    referralCode: getLocalData('referralCode')
      ? getLocalData('referralCode')
      : '',
  });
  const [discount, setDiscount] = useState('');
  const [discountApplied, setDiscountApplied] = useState({});
  const [error, setError] = useState('');
  const [hide, setHide] = useState(false);
  const [hideBilling, setHideBilling] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [total, setTotal] = useState(billingDetail?.total);
  const [appliedSuccess, setAppliedSuccess] = useState(false);
  const [isLoadingCoupon, setIsLoadingCoupon] = useState(false);
  const [isTermAndCondition, setIsTermAndCondition] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showErrorDetail, setShowErrorDetail] = useState({
    isError: true,
    message: '',
  });

  let statelist = Object.keys(state_city);
  let checkIsSubscribed = Cookies.get('isSubscribed');

  const stripe = useStripe();
  const elements = useElements();
  const formRef = useRef(null);

  useEffect(() => {
    if (personalDetail?.addresses?.length > 0) {
      let billingAddressSaved = personalDetail?.addresses.filter(
        (item) => item.address_type === 'billing'
      )[0];

      setFormData((prevFormData) => ({
        ...prevFormData,
        address: billingAddressSaved?.street_address1,
        address2: billingAddressSaved?.street_address2,
        state: billingAddressSaved?.state,
        zipCode: billingAddressSaved?.postal_code,
        city: billingAddressSaved?.city,
      }));
    }
  }, [personalDetail]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    // if (isMobile) {
    if (!isTermAndCondition) {
      setShowErrorDetail({
        isError: false,
        message: 'Please agree to Terms & Conditions.',
      });
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
        setShowErrorDetail({
          isError: true,
          message: '',
        });
      }, 3000);
      return;
    }
    // }

    try {
      setIsLoading(true);
      setIsPaymentSuccess(true);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
      });

      if (error) {
        SetIsError(true);
        SetErrorMsg(error.message);
        setTimeout(() => {
          SetIsError(false);
        }, 4000);
        setIsLoading(false);
        setIsPaymentSuccess(false);
      }

      if (paymentMethod) {
        const formdata = new FormData();
        formdata.append('payment_method_id', paymentMethod.id);
        formdata.append('referral_code', formData?.referralCode);
        formdata.append('associate_code', formData?.associateCode);

        const { id } = discountApplied;
        if (id) {
          formdata.append('coupon', id);
        }

        let access_token = Cookies.get('userToken');
        if (
          billingDetail.selectedPackage?.billingOption?.priceFrequency ===
          'Lifetime'
        ) {
          billingDetail?.selectedStripeIds.map((item) => {
            formdata.append(`price_id`, item);
          });

          postApi(lifetimeSubscribe, formdata, access_token)
            .then((res) => {
              if (res.data.success) {
                setIsLoading(false);
                setSuccess(true);
                setIsPaymentSuccess(true);
                Cookies.set('isSubscribed', true);
                dispatch(setIsSubscribed(true));
                deleteLocalData('joinEmail');
                deleteLocalData('referralCode');
                // setTimeout(() => {
                //   setSuccess(false);
                //   navigate("/account");
                // }, 4000);
              } else {
                SetIsError(true);
                SetErrorMsg('Something went wrong. Please try again!');
                setIsPaymentSuccess(false);
                setTimeout(() => {
                  SetIsError(false);
                }, 4000);
                setIsLoading(false);
              }
            })
            .catch((e) => {
              SetIsError(true);
              SetErrorMsg(e.response?.data?.message);
              setIsPaymentSuccess(false);
              setTimeout(() => {
                SetIsError(false);
              }, 4000);
              setIsLoading(false);
            });
        } else {
          billingDetail?.selectedStripeIds.map((item) => {
            formdata.append(`stripe_price_ids[]`, item);
          });

          postApi(subscribe, formdata, access_token)
            .then((res) => {
              if (res.data.success) {
                setIsLoading(false);
                setSuccess(true);
                setIsPaymentSuccess(true);
                Cookies.set('isSubscribed', true);
                dispatch(setIsSubscribed(true));
                deleteLocalData('joinEmail');
                deleteLocalData('referralCode');
                // setTimeout(() => {
                //   setSuccess(false);
                //   navigate("/account");
                // }, 4000);
              } else {
                SetIsError(true);
                SetErrorMsg('Something went wrong. Please try again!');
                setIsPaymentSuccess(false);
                setTimeout(() => {
                  SetIsError(false);
                }, 4000);
                setIsLoading(false);
              }
            })
            .catch((e) => {
              SetIsError(true);
              SetErrorMsg(e.response?.data?.message);
              setIsPaymentSuccess(false);
              setTimeout(() => {
                SetIsError(false);
              }, 4000);
              setIsLoading(false);
            });
        }
      }
    } catch (error) {
      console.error('Error:', error.message);
      setIsPaymentSuccess(false);
    }
  };

  const handleClose = () => {
    setShowError(false);
    setShowErrorDetail({
      isError: true,
      message: '',
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleBack = () => {
    navigate('/plan-checkout', {
      state: {
        currentTabId: 'review',
        nextTabId: 'questionnaire',
      },
    });
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
        setHide(false);
        setHideBilling(false);
      } else {
        setHide(false);
        setHideBilling(true);
        console.warn(isMobile, hide);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleApply = (e) => {
    e.preventDefault();
    if (discount) {
      setError('');
      setIsLoadingCoupon(true);
      getApi(checkCouponApi + discount)
        .then((res) => {
          if (res?.data?.success) {
            if (res?.data?.data?.length > 0) {
              let discountRes = res?.data?.data;
              const planName =
                billingDetail?.selectedPackage?.billingOption?.priceFrequency;
              const filterData = discountRes?.filter(
                (item) =>
                  item?.for === planName.toString().toLowerCase() ||
                  item?.for === 'all'
              );

              if (filterData.length > 0) {
                const couponCode = filterData[0];

                setDiscountApplied(couponCode);
                setAppliedSuccess(true);
                let discountAmount, amount;
                if (couponCode?.type === 'percentage') {
                  discountAmount = (total * couponCode?.amount) / 100;
                  if (discountAmount > total) {
                    amount = 0;
                  } else {
                    amount = total - discountAmount;
                  }
                } else {
                  discountAmount = (couponCode?.amount_converted / 100).toFixed(
                    2
                  );
                  if (discountAmount > total) {
                    amount = 0;
                  } else {
                    amount = total - discountAmount;
                  }
                }
                setTotal(amount);
                setIsLoadingCoupon(false);
              } else {
                setError('This coupon code is invalid or expired');
                setIsLoadingCoupon(false);
              }
            } else {
              setError('This coupon code is invalid or expired');
              setIsLoadingCoupon(false);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoadingCoupon(false);
        });
    }
  };

  const handleRemove = (e) => {
    e.preventDefault();
    let discountAmount;
    let amount = 0;
    if (discountApplied?.type === 'percentage') {
      if (!isNaN(total) && !isNaN(discountApplied?.amount)) {
        if (Number(discountApplied.amount) > total) {
          amount = billingDetail?.subTotal;
        } else {
          amount = total / (1 - discountApplied?.amount / 100);
        }
      }
    } else {
      if (Number(discountApplied.amount_converted) > total) {
        amount = billingDetail?.subTotal;
      } else {
        discountAmount = Number(discountApplied?.amount_converted) / 100;
        amount = Number(total) + discountAmount;
      }
    }
    setTotal(amount);
    setDiscount('');
    setDiscountApplied({});
    setAppliedSuccess(false);
  };

  useEffect(() => {}, []);

  const handleManualSubmit = async (e) => {
    e.preventDefault();
    const form = formRef.current;
    if (form.checkValidity()) {
      Array.from(form.elements).forEach((element) => {
        element.classList.remove('error-input-border-red');
      });
      await handleSubmit(e);
    } else {
      // Loop through each form element and add error class if invalid
      Array.from(form.elements).forEach((element) => {
        if (!element.checkValidity()) {
          element.classList.add('error-input-border-red');
        } else {
          element.classList.remove('error-input-border-red');
        }
      });
      form.reportValidity();
    }
  };
  return (
    <form ref={formRef} className="login-height-2">
      {showError && (
        <ErrorBanner error={showErrorDetail} handleClose={handleClose} />
      )}
      <div className="row">
        <div className="col-md-6">
          <div className="pb-5 pb-md-2 pop-list">
            {success === false && !checkIsSubscribed && (
              <>
                <button className="back-bb" type="button" onClick={handleBack}>
                  <i className="fa fa-angle-left" aria-hidden="true"></i>
                  <span className="">Back to previous details</span>
                </button>
                <div className="card card-payment card-bb">
                  <h5 className="bill-address list-bil">CARD DETAILS</h5>
                  <div className="row g-3">
                    <div className="col-lg-5">
                      <CardNumberElement
                        className={'form-control input-outline '}
                      />
                    </div>
                    <div className="col-lg-4 col-6">
                      <CardExpiryElement
                        className={'form-control input-outline '}
                      />
                    </div>
                    <div className="col-lg-3 col-6">
                      <CardCvcElement
                        className={'form-control input-outline '}
                      />
                    </div>
                  </div>
                </div>
                <div className="card card-payment card-bb">
                  <div className="row ">
                    {!associateCode && (
                      <span>
                        <a
                          className="referral-code-css"
                          onClick={() => setAssociateCode(true)}
                        >
                          Do you have an associate code?
                        </a>
                      </span>
                    )}
                    {associateCode && (
                      <>
                        <div className="col-12">
                          <span
                            className="close-input"
                            onClick={() => setAssociateCode(false)}
                          >
                            Close
                          </span>
                        </div>
                        <div className="col-md-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              name="associateCode"
                              className="form-control input-outline"
                              placeholder="Associate Code"
                              value={formData.associateCode}
                              onChange={handleInputChange}
                            />
                            <label htmlFor="name" className="label-text">
                              Associate Code
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              name="referralCode"
                              className="form-control input-outline"
                              placeholder="Referral Code"
                              value={formData.referralCode}
                              onChange={handleInputChange}
                              disabled={
                                getLocalData('referralCode') ? true : false
                              }
                            />
                            <label htmlFor="name" className="label-text">
                              Referral Code
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="card card-payment">
                  <div className="row">
                    <div className="billing-list-add">
                      <h5 className="bill-address list-bil">BILLING ADDRESS</h5>
                      {hideBilling && (
                        <p
                          className="d-block justify-content-evenly"
                          onClick={() => setHideBilling(!hideBilling)}
                        >
                          <span className="me-2"> Hide </span>{' '}
                          <i className="fa fa-angle-up"></i>
                        </p>
                      )}
                      {!hideBilling && (
                        <p
                          className="d-block justify-content-evenly"
                          onClick={() => setHideBilling(!hideBilling)}
                        >
                          <span className="me-2">Show</span>{' '}
                          <i className="fa fa-angle-down"></i>
                        </p>
                      )}
                    </div>
                    {hideBilling && (
                      <>
                        <div className="col-md-12">
                          <div className="floating-group">
                            <input
                              type="address"
                              name="address"
                              className="form-control input-outline"
                              value={formData.address}
                              onChange={handleInputChange}
                              required
                            />
                            <label
                              htmlFor="address"
                              className="form-label label-text"
                            >
                              Street Address 1*
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-floating mb-3 mt-3">
                            <input
                              type="address"
                              name="address2"
                              className="form-control input-outline"
                              onChange={handleInputChange}
                              value={formData.address2}
                              placeholder="Street Address 2"
                            />
                            <label
                              htmlFor="address2"
                              className="form-label label-text"
                            >
                              Street Address 2
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-4">
                          <div className="floating-group select-floating">
                            <select
                              id="state_"
                              className="form-select input-outline"
                              name="state"
                              value={formData.state}
                              onChange={handleInputChange}
                              required
                            >
                              <option value=""> Select State</option>
                              {statelist.map((item, index) => (
                                <option value={item} key={index}>
                                  {item}
                                </option>
                              ))}
                            </select>
                            <label
                              htmlFor="state_"
                              className="form-label label-text"
                            >
                              State*
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                          <div className="floating-group">
                            <input
                              type="text"
                              className="form-control input-outline"
                              id="inputCity"
                              name="city"
                              onChange={handleInputChange}
                              value={formData.city}
                              required
                            />
                            <label
                              htmlFor="inputCity"
                              className="form-label label-text"
                            >
                              City*
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                          <div className="floating-group">
                            <input
                              type="text"
                              className="form-control input-outline"
                              id="inputZip"
                              name="zipCode"
                              onChange={handleInputChange}
                              value={formData.zipCode}
                              maxLength="5"
                              onKeyPress={(e) => {
                                const regex = /^[0-9\b]+$/;
                                if (!regex.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              required
                            />
                            <label
                              htmlFor="inputZip"
                              className="form-label label-text"
                            >
                              Postal/ZIP code*
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="card border-0">
                  <button
                    type="button"
                    className={`theme-button d-none stripe black-button login-button sold  mt-3 ${
                      (!stripe || !elements || success || isLoading) &&
                      'disable'
                    }`}
                    onClick={handleManualSubmit}
                  >
                    {isLoading ? (
                      <ThreeDots
                        height="30"
                        width="30"
                        radius="9"
                        color="#fff"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{ display: 'contents' }}
                        wrapperClassName=""
                        visible={true}
                      />
                    ) : (
                      'Pay now'
                    )}
                  </button>
                  {/* {isError && (
                    <h6 className="text-center text-danger d-none d-md-block error-mag">
                      {errorMsg}
                    </h6>
                  )} */}
                </div>
              </>
            )}
            {(success || checkIsSubscribed) && (
              <>
                <div className="thank-you d-none">
                  <h3 className="text-success text-align-list">
                    Thank you,{' '}
                    {personalDetail?.fullName
                      ? personalDetail?.fullName
                      : personalDetail?.name}{' '}
                    {/* {personalDetail?.last_name || personalDetail?.lastName}  */}
                    !
                  </h3>
                  <h4 className="details-order">Order Details</h4>
                  <div className="row">
                    <div className="col-md-6">
                      <h6 className="information">Contact Information</h6>
                      <p style={{ overflowWrap: 'break-word' }}>
                        {personalDetail?.email}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <h6 className="add-bill mb-0 mb-md-2">Billing Address</h6>
                      {Object.keys(personalDetail).length > 0 && (
                        <p className="account-sub-heading">
                          {personalDetail?.name || personalDetail?.fullName}{' '}
                          {/* {personalDetail?.last_name ||
                            personalDetail?.lastName} */}
                          <br />
                          {formData?.address}
                          {formData?.address2}
                          <br />
                          {formData?.city +
                            ', ' +
                            formData?.state +
                            ', ' +
                            formData?.zipCode}
                          <br />
                          United States
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="make-can">
                    <button
                      type="button"
                      className={`theme-button black-button login-button my-0`}
                      onClick={() => navigate('/account', { replace: true })}
                    >
                      Continue
                    </button>
                    <Link
                      type="button"
                      className="link-text"
                      to="/find-a-range"
                    >
                      Make a Reservation
                    </Link>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="col-md-6 gx-0">
          {success === false && (
            <div className="items-plan">
              <div className="summary-order">
                <h4 className="ps-2">Order Summary</h4>
                {/* {hide && (
                <p
                  className="d-block justify-content-evenly"
                  onClick={() => setHide(!hide)}
                >
                  <span className="me-2"> Hide </span>{' '}
                  <i className="fa fa-angle-up"></i>
                </p>
              )}
              {!hide && (
                <p
                  className="d-block justify-content-evenly"
                  onClick={() => setHide(!hide)}
                >
                  <span className="me-2">Show</span>{' '}
                  <i className="fa fa-angle-down"></i>
                </p>
              )} */}
              </div>
              <table className="table width-cuman">
                <tbody>
                  <>
                    <tr>
                      <td style={{ width: '350px' }}>
                        <p className="plan plan-2">
                          <div>
                            {
                              billingDetail?.selectedPackage?.billingOption
                                ?.planName
                            }
                            {/* <div>
                              <small>
                                {
                                  billingDetail?.selectedPackage?.billingOption
                                    ?.planName
                                }
                              </small>
                            </div> */}
                          </div>
                        </p>
                      </td>

                      <td>
                        <p className="table-text-right">
                          {billingDetail?.selectedPackage?.billingOption
                            ?.planPrice && (
                            <>
                              {generatePrice(
                                billingDetail?.selectedPackage?.billingOption
                                  ?.planPrice
                              )}
                              {billingDetail?.selectedPackage?.billingOption
                                ?.priceFrequency === 'Monthly'
                                ? ' /month'
                                : billingDetail?.selectedPackage?.billingOption
                                      ?.priceFrequency === 'Yearly'
                                  ? ' /year'
                                  : ' /one-time'}
                            </>
                          )}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="plan plan-2">
                          <p className="plan">One Time Registration Fee</p>
                        </div>
                      </td>
                      <td>
                        <p className="table-text-right">{generatePrice(0)}</p>
                      </td>
                    </tr>
                    {/* <tr>
                      <td>
                        <div className="plan plan-2">
                          <p className="plan">Activation Fee</p>
                        </div>
                      </td>
                      <td>
                        <p className="table-text-right">{generatePrice(0)}</p>
                      </td>
                    </tr> */}
                    {/* {billingDetail?.selectedPackage?.addons &&
                      billingDetail?.selectedPackage?.addons.map(
                        (item, index) => (
                          <tr key={index}>
                            <td>
                              <p className="plan">{item.planName}</p>
                              <small>{item.planName}</small>
                            </td>
                            <td>
                              <p className="table-text-right">
                                {item.planPrice &&
                                  generatePrice(item.planPrice)}
                              </p>
                            </td>
                          </tr>
                        )
                      )} */}
                  </>
                  <tr>
                    <td>
                      <p className="plan">Tax</p>
                    </td>
                    <td>
                      <p className="table-text-right">$0.00</p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: '350px' }}>
                      <p className="plan">Subtotal</p>
                    </td>
                    <td>
                      <p className="table-text-right">
                        {billingDetail?.subTotal &&
                          generatePrice(billingDetail?.subTotal)}
                      </p>
                    </td>
                  </tr>
                  {/* We will use this discount field after some time. */}
                  {!success && (
                    <tr>
                      <td className="discount">
                        <input
                          type="text"
                          className="form-control discount-input"
                          placeholder="Discount code"
                          onChange={(e) => {
                            setDiscount(e.target.value);
                            setError('');
                          }}
                          value={discount}
                          name="discount"
                          disabled={appliedSuccess}
                        />
                        {error && (
                          <div className="text-danger m-1">{error}</div>
                        )}
                        {appliedSuccess && (
                          <>
                            <a
                              className={
                                isPaymentSuccess
                                  ? 'discount-remove disable'
                                  : 'discount-remove '
                              }
                              onClick={handleRemove}
                            >
                              Remove
                            </a>
                            <p className="text-success applied">
                              Coupon code applied successfully.
                              <u className="remove-coupon ms-5 "></u>
                            </p>
                          </>
                        )}
                      </td>
                      <td className="table-text-right">
                        <button
                          type="button"
                          className={` apply-button ${
                            appliedSuccess ? 'disabled' : ''
                          }`}
                          onClick={handleApply}
                          disabled={appliedSuccess}
                        >
                          {isLoadingCoupon ? (
                            <ThreeDots
                              height="25"
                              width="40"
                              radius="9"
                              color="#fff"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          ) : (
                            'Apply'
                          )}
                        </button>
                      </td>
                      {/* We will use this discount field after some time. */}
                    </tr>
                  )}
                  {appliedSuccess && (
                    <tr>
                      <td>
                        <p className="plan">
                          Discount{' '}
                          {discountApplied &&
                            discountApplied?.type === 'percentage' &&
                            `(${parseInt(discountApplied?.amount)}%)`}
                        </p>
                      </td>
                      <td>
                        <p className="table-text-right">
                          {discountApplied &&
                          discountApplied?.type === 'percentage'
                            ? '$' +
                              (
                                (billingDetail?.total *
                                  discountApplied?.amount) /
                                100
                              ).toFixed(2)
                            : '$' +
                              (discountApplied?.amount_converted / 100).toFixed(
                                2
                              )}
                        </p>
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td>
                      <p className="plan" style={{ fontWeight: '600' }}>
                        Total
                      </p>
                    </td>
                    <td>
                      <p
                        className="table-text-right text-nowrap
                      "
                        style={{ fontWeight: '600' }}
                      >
                        USD {billingDetail?.total && generatePrice(total)}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* {success === false && ( */}
              <div className="payment-check-now px-2">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck"
                    checked={isTermAndCondition}
                    onChange={(e) => setIsTermAndCondition(e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="gridCheck">
                    I understand that one or more items in my cart is a deferred
                    or recurring purchase. By proceeding you are agreeing to The
                    Caliber Card&apos;s Terms & Conditions mentioned in{' '}
                    <Link to="">Subscribing Member Agreement</Link> and
                    authorize you to charge my payment method at the prices,
                    frequency, and dates listed on this page until my order is
                    fulfilled or I cancel, if permitted.
                  </label>
                </div>

                <button
                  type="button"
                  className={`theme-button w-100 login-button mt-3 ${
                    (!stripe || !elements || success || isLoading) && 'disable'
                  }`}
                  onClick={handleManualSubmit}
                >
                  {isLoading ? (
                    <ThreeDots
                      height="27"
                      width="60"
                      radius="9"
                      color="#fff"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{ display: 'contents' }}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    'Pay now'
                  )}
                </button>
                {isError && (
                  <h6 className="text-center text-danger">{errorMsg}</h6>
                )}
              </div>
              {/* )} */}
            </div>
          )}
        </div>
      </div>
      <>
        {(success || checkIsSubscribed) && (
          <div className="thank-you">
            <h3 className="text-success text-align-list text-center mb-5">
              Thank you,{' '}
              {personalDetail?.fullName
                ? personalDetail?.fullName
                : personalDetail?.name}{' '}
              {/* {personalDetail?.last_name || personalDetail?.lastName}  */}!
            </h3>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6">
                  <h4 className="details-order">Order Details</h4>
                  <div className="row">
                    <div className="col-md-6">
                      <h6 className="information">Contact Information</h6>
                      <p style={{ overflowWrap: 'break-word' }}>
                        {personalDetail?.email}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <h6 className="add-bill mb-0 mb-md-2">Billing Address</h6>
                      {Object.keys(personalDetail).length > 0 && (
                        <p className="account-sub-heading">
                          {personalDetail?.name || personalDetail?.fullName}{' '}
                          {/* {personalDetail?.last_name ||
                            personalDetail?.lastName} */}
                          <br />
                          {formData?.address}
                          {formData?.address2}
                          <br />
                          {formData?.city +
                            ', ' +
                            formData?.state +
                            ', ' +
                            formData?.zipCode}
                          <br />
                          United States
                        </p>
                      )}
                    </div>
                  </div>
                  {/* <div className="make-can">
                    <button
                      type="button"
                      className={`theme-button black-button login-button my-0`}
                      onClick={() => navigate('/account', { replace: true })}
                    >
                      Continue
                    </button>
                    <Link
                      type="button"
                      className="link-text"
                      onClick={() =>
                        navigate('/find-a-range', { replace: true })
                      }
                    >
                      Make a Reservation
                    </Link>
                  </div> */}
                </div>
                <div className="col-md-6 gx-0">
                  <div className="items-plan item-plan-new">
                    <div className="summary-order">
                      <h4 className="ps-2">Order Summary</h4>
                    </div>

                    <table className="table width-cuman">
                      <tbody>
                        <>
                          <tr>
                            <td style={{ width: '350px' }}>
                              <p className="plan plan-2">
                                <div>
                                  {
                                    billingDetail?.selectedPackage
                                      ?.billingOption?.planName
                                  }
                                </div>
                              </p>
                            </td>

                            <td>
                              <p className="table-text-right">
                                {billingDetail?.selectedPackage?.billingOption
                                  ?.planPrice && (
                                  <>
                                    {generatePrice(
                                      billingDetail?.selectedPackage
                                        ?.billingOption?.planPrice
                                    )}
                                    {billingDetail?.selectedPackage
                                      ?.billingOption?.priceFrequency ===
                                    'Monthly'
                                      ? ' /month'
                                      : billingDetail?.selectedPackage
                                            ?.billingOption?.priceFrequency ===
                                          'Yearly'
                                        ? ' /year'
                                        : ' /one-time'}
                                  </>
                                )}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="plan plan-2">
                                <p className="plan">
                                  One Time Registration Fee
                                </p>
                              </div>
                            </td>
                            <td>
                              <p className="table-text-right">
                                {generatePrice(0)}
                              </p>
                            </td>
                          </tr>
                          {/* <tr>
                            <td>
                              <div className="plan plan-2">
                                <p className="plan">Activation Fee</p>
                              </div>
                            </td>
                            <td>
                              <p className="table-text-right">
                                {generatePrice(0)}
                              </p>
                            </td>
                          </tr> */}
                        </>
                        <tr>
                          <td>
                            <p className="plan">Tax</p>
                          </td>
                          <td>
                            <p className="table-text-right">$0.00</p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '350px' }}>
                            <p className="plan">Subtotal</p>
                          </td>
                          <td>
                            <p className="table-text-right">
                              {billingDetail?.subTotal &&
                                generatePrice(billingDetail?.subTotal)}
                            </p>
                          </td>
                        </tr>
                        {/* We will use this discount field after some time. */}

                        {appliedSuccess && (
                          <tr>
                            <td>
                              <p className="plan">
                                Discount{' '}
                                {discountApplied &&
                                  discountApplied?.type === 'percentage' &&
                                  `(${parseInt(discountApplied?.amount)}%)`}
                              </p>
                            </td>
                            <td>
                              <p className="table-text-right">
                                {discountApplied &&
                                discountApplied?.type === 'percentage'
                                  ? '$' +
                                    (
                                      (billingDetail?.total *
                                        discountApplied?.amount) /
                                      100
                                    ).toFixed(2)
                                  : '$' +
                                    (
                                      discountApplied?.amount_converted / 100
                                    ).toFixed(2)}
                              </p>
                            </td>
                          </tr>
                        )}

                        <tr>
                          <td>
                            <p className="plan" style={{ fontWeight: '600' }}>
                              Total
                            </p>
                          </td>
                          <td>
                            <p
                              className="table-text-right text-nowrap
                      "
                              style={{ fontWeight: '600' }}
                            >
                              USD {billingDetail?.total && generatePrice(total)}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="text-center mt-3">
                <Link to="/" className="theme-button">
                  Go To HomePage
                </Link>
                <br />
                <Link type="button" className="link-text" to="/find-a-range">
                  Make a Reservation
                </Link>
              </div>
            </div>
          </div>
        )}
      </>
    </form>
  );
};

const Payment = () => {
  return (
    <div className="border-top">
      <div className="container-fluid">
        <Elements stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      </div>
    </div>
  );
};

export default Payment;
