import React, { useEffect, useState } from 'react';
import AccountMainLayout from '../Layout/AccountMainLayout';
import { Link, useLocation } from 'react-router-dom';
import { getApi } from '../utils/api';
import { invoiceView } from '../utils/endpoint';
import Cookies from 'js-cookie';
import moment from 'moment';
import { generatePrice } from '../utils/helpers';
import { ThreeDots } from 'react-loader-spinner';

const AccountOrder = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState({});
  const [discount, setDiscount] = useState({});
  const [address, setAddress] = useState({});

  const { state } = useLocation();
  const { data } = state || {};

  useEffect(() => {
    let access_token = Cookies.get('userToken');
    setIsLoading(true);
    getApi(invoiceView + data?.id, access_token)
      .then((res) => {
        if (res?.data?.success) {
          setOrder(res?.data?.data);
          let discountDummy = JSON.parse(
            res?.data?.data.total_discount_amounts
          );
          let addressDummy = JSON.parse(res?.data?.data.customer_address);
          setDiscount(discountDummy[0]);
          setAddress(addressDummy);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  return (
    <>
      <AccountMainLayout>
        <div className="account-login">
          {!isLoading && (
            <div className="container-fluid">
              <div className="account-user">
                <div className="row">
                  <div className="col-12">
                    <div className="account-log-out">
                      <h2 className="account-heading">Account</h2>
                      <Link to="/account" className="link-text">
                        Return to Account details
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <div className="order-history">
                      <h2 className="account-heading">
                        Order #{order?.number}
                      </h2>
                      <p>
                        Placed on{' '}
                        {moment
                          .unix(order?.created)
                          .format('MMMM DD, YYYY [at] h:mm a')}
                      </p>
                    </div>
                    <div className="card card-table">
                      <table className="table-list product">
                        <thead>
                          <tr>
                            <th
                              style={{
                                width: '300px',
                                textAlign: 'left',
                                paddingLeft: '20px',
                              }}
                            >
                              PRODUCT
                            </th>
                            <th>PRICE</th>
                            <th>QUANTITY</th>
                            <th>TOTAL</th>
                          </tr>
                        </thead>
                        <tbody>
                          {order?.invoice_items
                            ?.reverse()
                            .map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <ul className="order-id-children">
                                    <li className="minor">
                                      <Link
                                        style={{
                                          pointerEvents: 'none',
                                        }}
                                      >
                                        {item.package_name}
                                      </Link>
                                    </li>
                                    {/* <li>{item.package_name}</li> */}
                                    {/* <li>For Additional Associates: true</li> */}
                                    <li className="fulfilled">
                                      {order?.fulfill === 0
                                        ? 'Not Fulfilled '
                                        : 'Fulfilled '}
                                      {moment
                                        .unix(item.period_start)
                                        .format('MMMM DD, YYYY')}
                                    </li>
                                  </ul>
                                </td>
                                <td>
                                  {generatePrice(Number(item?.amount) / 100)}
                                </td>
                                <td>{item?.quantity}</td>
                                <td>
                                  {generatePrice(Number(item?.amount) / 100)}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan="5" className="total-list-price">
                              <ul className="amount-text">
                                <li>Subtotal</li>
                                {discount && <li>Discount</li>}
                                {/* <li>Tax (Texas State Tax 4.925%)</li>
                                <li>Tax (Harris County Tax 0.0%)</li>
                                <li>Tax (Houston City Tax 0.0%)</li> */}
                                <li>Tax</li>
                                <li className="child-total">Total</li>
                              </ul>
                              <ul className="amount-list">
                                <li>{generatePrice(order?.subtotal / 100)}</li>
                                {discount && (
                                  <li>
                                    <small>
                                      <img src="./images/coupon.svg" />
                                    </small>
                                    - {generatePrice(discount?.amount / 100)}
                                  </li>
                                )}
                                {/* <li>{generatePrice(order?.tax / 100)}</li>
                                <li>{generatePrice(order?.tax / 100)}</li> */}
                                <li>{generatePrice(order?.tax / 100)}</li>
                                <li className="child-total">
                                  {generatePrice(order?.total / 100)} USD
                                </li>
                              </ul>
                            </td>

                            {/* <td style={{ textAlign: "right", paddingRight: "20px" }}>
                                                        <ul>
                                                            <li>
                                                                $35.00
                                                            </li>
                                                            <li>
                                                                -$35.00
                                                            </li>
                                                            <li>
                                                                $0.00
                                                            </li>
                                                            <li>
                                                                $0.00
                                                            </li>
                                                            <li>
                                                                $0.00
                                                            </li>
                                                            <li className="child-total">
                                                                $0.00 USD
                                                            </li>
                                                        </ul>
                                                    </td> */}
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="account-details">
                      <h2 className="account-heading ">Billing Address</h2>
                      <p className="status mb-0">
                        Payment Status: {order?.status === 'paid' ? 'Paid' : ''}
                      </p>
                      <p className="status">
                        {order?.user?.name} {order?.user?.last_name}
                        <br />
                        {address?.line1}
                        {address?.line2 && <br /> + address?.line2}
                        <br />
                        {address?.state + ' '}
                        {address?.city} {address?.postal_code}
                        <br />
                        United States
                      </p>
                      <h2 className="account-heading">Shipping Address</h2>
                      <p className="status">
                        Fulfillment Status:{' '}
                        {order?.fulfill === 0 ? 'Not Fulfilled' : 'Fulfilled'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isLoading && (
            <div className="loader">
              <ThreeDots
                height="110"
                width="110"
                radius="10"
                color="#aeaeae"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          )}
        </div>
      </AccountMainLayout>
    </>
  );
};

export default AccountOrder;
