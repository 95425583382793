import React, { useRef, useState } from 'react';
import { StandaloneSearchBox } from '@react-google-maps/api';
import PropTypes from 'prop-types';

const PlaceComponent = ({
  isLoaded,
  loadError,
  setGeolocationData,
  isValidSearch,
}) => {
  const inputRef = useRef();
  const [selectedAddress, setSelectedAddress] = useState('');

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      const addressComponents = place.address_components;
      console.log(loadError);
      let city = '';
      let state = '';
      let postalCode = '';

      for (const component of addressComponents) {
        const types = component.types;
        if (types.includes('locality')) {
          city = component.long_name;
        } else if (types.includes('administrative_area_level_1')) {
          state = component.short_name;
        } else if (types.includes('postal_code')) {
          postalCode = component.long_name;
        }
      }
      // Construct the complete address
      const completeAddress = `${place.formatted_address}, ${city}, ${state} ${postalCode}`;
      // Update the input field value
      setSelectedAddress(completeAddress);
      // console.log(completeAddress);
      // console.log(place.geometry.location.lat());
      // console.log(place.geometry.location.lng());
      setGeolocationData({
        lat: place.geometry.location.lat(),
        long: place.geometry.location.lng(),
      });
    }
  };

  const handleChangeInput = (e) => {
    setSelectedAddress(e.target.value);
    if (e.target.value === '') {
      isValidSearch(true);
    } else {
      isValidSearch(false);
    }
  };

  return (
    isLoaded && (
      <StandaloneSearchBox
        onLoad={(ref) => (inputRef.current = ref)}
        onPlacesChanged={handlePlaceChanged}
      >
        <input
          type="text"
          placeholder="Enter Location"
          className="form-control input-outline"
          onChange={handleChangeInput}
          value={selectedAddress}
        />
      </StandaloneSearchBox>
    )
  );
};

PlaceComponent.propTypes = {
  isLoaded: PropTypes.bool,
  loadError: PropTypes.bool,
  setGeolocationData: PropTypes.func,
  isValidSearch: PropTypes.func,
};

export default PlaceComponent;
