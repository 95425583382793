import AccountMainLayout from '../Layout/AccountMainLayout';
import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import ErrorBanner from './ErrorBanner';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword, updateProfile } from '../utils/endpoint';
import { postApi } from '../utils/api';
import * as Yup from 'yup';
import { state_city } from '../utils/state_cities';
import { setPersonalDetail } from '../redux/features/signup/signupSlice';
import { ThreeDots } from 'react-loader-spinner';
import { emailRegExp, phoneRegExp } from '../utils/common';
import { AccountAddresses } from './AccountAddresses';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const ProfilePreferences = () => {
  const { personalDetail } = useSelector((state) => state.signup);
  const [formData, setFormData] = useState({
    fullName:
      personalDetail?.fullName || personalDetail?.name
        ? personalDetail?.fullName || personalDetail?.name
        : '',
    // lastName:
    //   personalDetail?.lastName || personalDetail?.last_name
    //     ? personalDetail?.lastName || personalDetail?.last_name
    //     : '',
    email: personalDetail.email ? personalDetail.email : '',
    phoneNumber:
      personalDetail?.phoneNumber || personalDetail?.telephone
        ? personalDetail?.phoneNumber || personalDetail?.telephone
        : '',
    password: personalDetail?.password ? personalDetail?.password : '00000000',
    address: personalDetail?.address ? personalDetail?.address : '',
    address2: personalDetail?.address2 ? personalDetail?.address2 : '',
    state: personalDetail?.state ? personalDetail?.state : '',
    city: personalDetail?.city ? personalDetail?.city : '',
    zipCode: personalDetail?.zipCode
      ? personalDetail?.zipCode
      : personalDetail?.zipcode
        ? personalDetail?.zipcode
        : '',
    isAdditional: false,
    additionalFirstName: '',
    additionalLastName: '',
  });
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  let statelist = Object.keys(state_city);
  // const [fieldErrors, setFieldErrors] = useState({});

  const [showError, setShowError] = useState(false);
  const [showErrorDetail, setShowErrorDetail] = useState({
    isError: true,
    message: '',
  });
  const formRef = useRef(null);
  const access_token = Cookies.get('userToken');
  const [errors, setErrors] = useState('');
  const { state } = useLocation();
  const { dataState } = state || '';

  useEffect(() => {
    if (dataState === 'profileAddresses') {
      const tabEle = document.querySelectorAll('.nav-link');
      // Remove active class from tab list
      const activeElements = document.querySelectorAll('.active');
      activeElements.forEach((element) => {
        element.classList.remove('active');
      });

      // Add active class to Payment History tab
      tabEle.forEach((element) => {
        if (element.id === 'nav-profile-tab') {
          element.classList.add('active');
          const tabContent = document.getElementById('nav-profile');
          tabContent.classList.add('active', 'show');
        }
      });
    }
  }, []);

  const handleChangePassword = (e) => {
    e.preventDefault();
    setLoading(true);
    postApi(changePassword, {}, access_token)
      .then((res) => {
        if (res?.data?.success) {
          setShowErrorDetail({
            isError: false,
            message:
              'An email containing a password-reset link has been sent to you.',
          });
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
            setShowErrorDetail({
              isError: true,
              message: '',
            });
          }, 7000);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleClose = () => {
    setShowError(false);
    setShowErrorDetail({
      isError: true,
      message: '',
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    // Validation
    try {
      validationSchema.validateSyncAt(name, { [name]: value });
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(formData, {
        abortEarly: false,
      });
      setErrors('');
      // const { city, state } = formData;
      // if (!state_city[state].includes(city)) {
      //   setShowError(true);
      //   setShowErrorDetail({
      //     isError: false,
      //     message: 'Not a valid United States city.',
      //   });
      //   setTimeout(() => {
      //     setShowError(false);
      //     setShowErrorDetail({
      //       isError: true,
      //       message: '',
      //     });
      //   }, 3000);
      //   setIsLoading(false);
      // } else {
      const formdata = new FormData();
      formdata.append('name', formData.fullName);
      // formdata.append('last_name', formData.lastName);
      formdata.append('email', formData.email);
      formdata.append('telephone', formData.phoneNumber);
      // formdata.append('city', formData.city);
      // formdata.append('state', formData.state);
      // formdata.append('zipcode', formData.zipCode);
      // formdata.append('address', formData.address);
      // formdata.append('address2', formData.address2);
      postApi(updateProfile, formdata, access_token)
        .then((res) => {
          if (res?.data?.success) {
            window.scrollTo(0, 0);
            setShowErrorDetail({
              isError: false,
              message: 'Updated!',
            });
            setShowError(true);
            setTimeout(() => {
              setShowError(false);
              setShowErrorDetail({
                isError: true,
                message: '',
              });
            }, 7000);

            localStorage.setItem('userData', JSON.stringify(res?.data?.data));
            dispatch(setPersonalDetail(res?.data?.data));
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
      // }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      console.log(error);
      if (Object.entries(validationErrors).length === 0) {
        setShowError(true);
        setShowErrorDetail({
          isError: true,
          message: 'Please fill all the required fields.',
        });
        setTimeout(() => {
          setShowError(false);
          setShowErrorDetail({
            isError: true,
            message: '',
          });
        }, 3000);
      }
      setErrors(validationErrors);
      setIsLoading(false);
    }
  };

  const handleManualSubmit = async (e) => {
    e.preventDefault();
    const form = formRef.current;
    if (form.checkValidity()) {
      setIsLoading(true);
      await handleSubmit(e);
    } else {
      setShowError(true);
      setShowErrorDetail({
        isError: true,
        message: 'Please fill all the required fields.',
      });
      setTimeout(() => {
        setShowError(false);
        setShowErrorDetail({
          isError: true,
          message: '',
        });
      }, 3500);
      // const newFieldErrors = {};
      // for (let i = 0; i < form.elements.length; i++) {
      //   const field = form.elements[i];
      //   if (
      //     field.tagName === 'INPUT' ||
      //     field.tagName === 'TEXTAREA' ||
      //     field.tagName === 'SELECT'
      //   ) {
      //     if (!field.validity.valid) {
      //       newFieldErrors[field.name] = true;
      //     }
      //   }
      // }
      // setFieldErrors(newFieldErrors);
      form.reportValidity();
    }
  };
  const styleClass = {
    color: 'red',
  };

  return (
    <>
      <AccountMainLayout>
        <div className="account-login pt-5">
          <div className="container-fluid">
            <nav className="tabs-bills">
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <button
                  className="nav-link active"
                  id="nav-home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-home"
                  type="button"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                >
                  My Profile
                </button>
                <button
                  className="nav-link"
                  id="nav-profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-profile"
                  type="button"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                >
                  Addresses
                </button>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane fade show active"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                {showError && (
                  <ErrorBanner
                    error={showErrorDetail}
                    handleClose={handleClose}
                  />
                )}
                <form
                  className="user-details account-login"
                  id="earlyAccessForm"
                  ref={formRef}
                >
                  <div className="row">
                    {/* <div className="link-profile">
                      <ul>
                        <li>
                          <Link>My Profile</Link>
                        </li>
                      </ul>
                    </div> */}
                    <div className="col-lg-7">
                      <div className="row">
                        <div className="col-lg-6"></div>
                        {/* <div className="col-lg-6">
                    <button
                      type="button"
                      onClick={handleChangePassword}
                      className="theme-button login-button w-100 mt-4"
                    >
                      Change password
                    </button>
                  </div> */}
                        {/* <form ref={formRef}> */}

                        <div className="col-md-12">
                          <h2 className="primary-heading">
                            Personal Information
                          </h2>
                        </div>
                        <div className="col-lg-12">
                          <ReactTooltip
                            id="fullName"
                            place="top"
                            variant="info"
                            content={errors.fullName}
                          />
                          <div
                            className="floating-group"
                            data-tooltip-id="fullName"
                          >
                            <input
                              type="text"
                              className={
                                'form-control input-outline' +
                                (errors.fullName
                                  ? ' error-input-border-red'
                                  : '')
                              }
                              value={formData.fullName}
                              onChange={handleInputChange}
                              name="fullName"
                              required
                            />
                            <label
                              htmlFor="fullName"
                              className="form-label label-text"
                            >
                              Full Name*
                            </label>
                            {/* {errors.fullName && (
                              <div style={styleClass}>{errors.fullName}</div>
                            )} */}
                          </div>
                        </div>
                        {/* <div className="col-lg-6 d-none">
                          <div className="floating-group">
                            <input
                              type="text"
                              className="form-control input-outline"
                              value={formData.lastName}
                              name="lastName"
                              onChange={handleInputChange}
                              // required
                            />
                            <label
                              htmlFor="lastname"
                              className="form-label label-text"
                            >
                              Last name*
                            </label>
                            {errors.lastName && (
                              <div style={styleClass}>{errors.lastName}</div>
                            )}
                          </div>
                        </div> */}
                        <div className="col-md-6">
                          <ReactTooltip
                            id="email"
                            place="top"
                            variant="info"
                            content={errors.email}
                          />
                          <div
                            className="floating-group select-floating"
                            data-tooltip-id="email"
                          >
                            <input
                              type="email"
                              className={
                                'form-control input-outline' +
                                (errors.email ? ' error-input-border-red' : '')
                              }
                              value={formData.email}
                              onChange={handleInputChange}
                              name="email"
                              readOnly
                              required
                            />
                            <label
                              htmlFor="email"
                              className="form-label label-text"
                            >
                              Email Address
                            </label>
                            {errors.email && (
                              <div style={styleClass}>{errors.email}</div>
                            )}
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                        <label
                          htmlFor="password"
                          className="form-label label-text"
                        >
                          Password
                        </label>
                        <input
                          type="password"
                          className="form-control input-outline"
                          placeholder="Password"
                          value={formData.password}
                          name="password"
                          disabled
                        />
                      </div> */}

                        <div className="col-md-6">
                          <ReactTooltip
                            id="phoneNumber"
                            place="top"
                            variant="info"
                            content={errors.phoneNumber}
                          />
                          <div
                            className="floating-group"
                            data-tooltip-id="phoneNumber"
                          >
                            <input
                              type="tel"
                              maxLength="10"
                              className={
                                'form-control input-outline' +
                                (errors.phoneNumber
                                  ? ' error-input-border-red'
                                  : '')
                              }
                              value={formData.phoneNumber}
                              onChange={handleInputChange}
                              name="phoneNumber"
                              onKeyPress={(e) => {
                                const regex = /^[0-9\b]+$/;
                                if (!regex.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              required
                            />
                            <label
                              htmlFor="phoneNumber"
                              className="form-label label-text"
                            >
                              Phone Number*
                            </label>
                            {/* {errors.phoneNumber && (
                              <div style={styleClass}>{errors.phoneNumber}</div>
                            )} */}
                          </div>
                        </div>
                        <div className="text-end mb-2">
                          <button
                            className={`theme-button login-button ${
                              isLoading ? 'disable' : ''
                            }`}
                            form="earlyAccessForm"
                            onClick={handleManualSubmit}
                          >
                            {isLoading ? (
                              <div className="d-flex justify-content-center">
                                <ThreeDots
                                  height="27"
                                  width="49"
                                  radius="2"
                                  color="#fff"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              </div>
                            ) : (
                              'Save'
                            )}
                          </button>
                        </div>
                        <div className="t-center ">
                          <span>Do you want to change your password?</span>
                          <Link
                            type="button"
                            onClick={!loading ? handleChangePassword : null}
                            className="link-text ps-2"
                            disabled
                          >
                            {loading ? <p>Loading...</p> : 'Click here'}
                          </Link>
                        </div>
                        {formData?.isAdditional && (
                          <>
                            <div className="col-md-12">
                              <h2 className="primary-heading">
                                ADDITIONAL ASSOCIATE
                              </h2>
                            </div>
                            <div className="col-md-6">
                              <label
                                htmlFor="additionalFirstName"
                                className="form-label label-text"
                              >
                                First name*
                              </label>
                              <input
                                type="text"
                                className="form-control input-outline"
                                placeholder="First name*"
                                value={formData.additionalFirstName}
                                name="additionalFirstName"
                                required
                                onChange={handleInputChange}
                              />
                              {errors.additionalFirstName && (
                                <div style={styleClass}>
                                  {errors.additionalFirstName}
                                </div>
                              )}
                            </div>
                            <div className="col-md-6">
                              <label
                                htmlFor="additionalLastName"
                                className="form-label label-text"
                              >
                                Last name*
                              </label>
                              <input
                                type="text"
                                className="form-control input-outline"
                                placeholder="Last name*"
                                value={formData.additionalLastName}
                                name="additionalLastName"
                                required
                                onChange={handleInputChange}
                              />
                              {errors.additionalLastName && (
                                <div style={styleClass}>
                                  {errors.additionalLastName}
                                </div>
                              )}
                            </div>
                          </>
                        )}
                        <div className="col-md-12 d-none">
                          <h2 className="primary-heading">BILLING ADDRESS</h2>
                        </div>
                        <div className="col-md-12 d-none">
                          <label
                            htmlFor="address"
                            className="form-label label-text"
                          >
                            Street Address 1*
                          </label>
                          <input
                            type="address"
                            className="form-control input-outline"
                            placeholder="Street Address 1*"
                            value={formData.address}
                            onChange={handleInputChange}
                            name="address"
                            required
                          />
                          {errors.address && (
                            <div style={styleClass}>{errors.address}</div>
                          )}
                        </div>
                        <div className="col-md-12 d-none">
                          <label
                            htmlFor="address2"
                            className="form-label label-text"
                          >
                            Street Address 2
                          </label>
                          <input
                            type="address"
                            className="form-control input-outline"
                            placeholder="Street Address 2"
                            value={formData.address2}
                            onChange={handleInputChange}
                            name="address2"
                          />
                        </div>
                        <div className="col-md-6 d-none">
                          <label
                            htmlFor="input_State"
                            className="form-label label-text"
                          >
                            State*
                          </label>
                          <select
                            id="input_State"
                            className="form-select input-outline"
                            name="state"
                            value={formData.state}
                            onChange={handleInputChange}
                            required
                          >
                            <option disabled>Select State</option>
                            {statelist.map((item, index) => (
                              <option value={item} key={index}>
                                {item}
                              </option>
                            ))}
                          </select>
                          {/* <p className="form-membership">
                      We do not offer memberships for those in the following
                      states: New York, New Jersey, Washington
                    </p> */}
                        </div>
                        <div className="col-md-6 d-none">
                          <label
                            htmlFor="inputCity"
                            className="form-label label-text"
                          >
                            City*
                          </label>
                          <input
                            type="text"
                            className="form-control input-outline"
                            id="inputCity"
                            placeholder="City"
                            name="city"
                            onChange={handleInputChange}
                            value={formData.city}
                            required
                          />
                        </div>
                        <div className="col-md-12 d-none">
                          <label
                            htmlFor="inputZip"
                            className="form-label label-text"
                          >
                            Postal/ZIP code*
                          </label>
                          <input
                            type="text"
                            className="form-control input-outline"
                            id="inputZip"
                            placeholder="Postal/ZIP code"
                            name="zipCode"
                            value={formData.zipCode}
                            onChange={handleInputChange}
                            maxLength="5"
                            onKeyPress={(e) => {
                              const regex = /^[0-9\b]+$/;
                              if (!regex.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            required
                          />
                        </div>
                        <div className="text-end mb-5 d-none">
                          <button
                            className={`theme-button login-button ${
                              isLoading ? 'disable' : ''
                            }`}
                            form="earlyAccessForm"
                            onClick={handleManualSubmit}
                          >
                            {isLoading ? (
                              <div className="d-flex justify-content-center">
                                <ThreeDots
                                  height="30"
                                  width="35"
                                  radius="2"
                                  color="#fff"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              </div>
                            ) : (
                              'Save'
                            )}
                          </button>
                        </div>
                      </div>
                      {/* </form> */}
                    </div>
                    <div className="col-lg-5"></div>
                  </div>
                </form>
              </div>
              <div
                className="tab-pane fade"
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
              >
                <AccountAddresses />
              </div>
            </div>
          </div>
        </div>
      </AccountMainLayout>
    </>
  );
};

export default ProfilePreferences;

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required('First name is required'),
  // lastName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required')
    .matches(emailRegExp, 'Please enter a valid Email Address'),
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .matches(phoneRegExp, 'Please enter valid mobile number'),
  address: Yup.string().required('Address is required'),
  state: Yup.string().required('State is required'),
});
