import React from 'react';
import Header from '../components/Header';
// import Footer from '../components/Footer';
import Headroom from 'react-headroom';
import PropTypes from 'prop-types';

const SignUpLayout = ({ children, className }) => {
  return (
    <>
      <div className="wrapper">
        <Headroom>
          <Header isMenuRequired={true} />
        </Headroom>
        <div className={`wrapper-body ${className} `}>{children}</div>
        {/* <Footer /> */}
      </div>
    </>
  );
};

SignUpLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default SignUpLayout;
