import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const Header = ({ isMenuRequired }) => {
  const [activeItem, setActiveItem] = useState('');
  const [show, setShow] = useState(false);
  const { isSubscribed } = useSelector((state) => state.signup);

  useEffect(() => {
    const path = window.location.pathname;
    setActiveItem(path);
  }, []);

  const handelNavClick = () => {
    document.body.classList.toggle('overflow__hidden');
  };

  return (
    <>
      <div className="navbar d-lg-block d-none">
        <div className="container">
          <header>
            <a href="/" className="logo">
              <img src="../../images/logo-header.svg" alt="" />
            </a>
            <nav className={isSubscribed || isMenuRequired ? 'd-none' : ''}>
              <ul className="menu-bar">
                {/* <li className={activeItem === '/how-to-works' ? 'active' : ''}>
                  <Link to="/how-to-works">How it Works</Link>
                </li>
                <li className={activeItem === '/why-to-join' ? 'active' : ''}>
                  <Link to="/why-to-join">Why Join?</Link>
                </li> */}

                <li className={activeItem === '/find-a-range' ? 'active' : ''}>
                  <Link to="/find-a-range">
                    {activeItem === '/find-a-range' ? (
                      <img
                        src="../images/location_active.png"
                        alt="location_active"
                      />
                    ) : (
                      <img src="../images/location.png" alt="location" />
                    )}
                    Find a Range
                  </Link>
                </li>
                <li
                  className={
                    activeItem === '/experiences' ? 'active me-5' : ' me-5'
                  }
                >
                  <Link to="/experiences">Experiences</Link>
                </li>
                <li className="link-join">
                  <Link
                    to="/join-now"
                    className={
                      activeItem === '/join-now'
                        ? 'btn-join active-btn '
                        : 'btn-join'
                    }
                  >
                    Join Now
                  </Link>
                </li>
                <li className="link-join">
                  <Link
                    to="/login"
                    className={
                      activeItem === '/login'
                        ? 'btn-join active-btn '
                        : 'btn-join'
                    }
                  >
                    Login
                  </Link>
                </li>
              </ul>
            </nav>
          </header>
        </div>
      </div>
      <div className="navbar navbar-mobile-menu d-lg-none">
        <div className="container-fluid">
          <header>
            <Link to="/" className="logo">
              <img src="../../images/logo-header.svg" alt="" />
            </Link>
            <nav className={isSubscribed || isMenuRequired ? 'd-none' : ''}>
              <input type="checkbox" id="click" />
              <label
                htmlFor="click"
                className="menu-btn"
                onClick={handelNavClick}
              >
                <i className="fa fa-align-right"></i>
              </label>
              <ul className="menu-bar">
                <li className={activeItem === '/' ? 'active' : ''}>
                  <Link to="/">Home</Link>
                </li>
                <li className={activeItem === '/find-a-range' ? 'active' : ''}>
                  <Link to="/find-a-range">Find a Range</Link>
                </li>
                <li className={activeItem === '/experiences' ? 'active' : ''}>
                  <Link to="/experiences">Experiences</Link>
                </li>
                <li
                  className={
                    activeItem === '/join-now'
                      ? 'active join-set-2'
                      : 'join-set-2'
                  }
                >
                  <Link
                    to="/join-now"
                    className="dropdown-img"
                    onClick={() => setShow(!show)}
                  >
                    Join Now
                  </Link>
                </li>
                <li className={activeItem === '/login' ? 'active' : ''}>
                  <Link to="/login">Login</Link>
                </li>

                {show ? (
                  <ul className="d-none">
                    <li>
                      <Link to="/join-now">Become a Member</Link>
                    </li>
                    <li>
                      <Link to="/participating">
                        Become a Participating Range
                      </Link>
                    </li>
                  </ul>
                ) : null}

                {/* <li className={activeItem === '/how-to-works' ? 'active' : ''}>
                  <Link to="/how-to-works">How it Works</Link>
                </li>
                <li className={activeItem === '/why-to-join' ? 'active' : ''}>
                  <Link to="/why-to-join">Why Join?</Link>
                </li> */}

                <li
                  className={
                    activeItem === 'https://therangenetwork.com/'
                      ? 'active'
                      : ''
                  }
                >
                  <Link to="https://therangenetwork.com/" target="_blank">
                    About The Range Network
                  </Link>
                </li>
                <li className={activeItem === '/support' ? 'active' : ''}>
                  <Link to="/support" target="_blank">
                    Support
                  </Link>
                </li>
                <li
                  className={
                    activeItem ===
                    'https://enroll.mycalibercard.com/terms-conditions'
                      ? 'active'
                      : ''
                  }
                >
                  <Link
                    to="https://enroll.mycalibercard.com/terms-conditions"
                    target="_blank"
                  >
                    Card Member Agreement
                  </Link>
                </li>
                <li
                  className={
                    activeItem === 'https://therangenetwork.com/join-now/'
                      ? 'active'
                      : ''
                  }
                >
                  <Link
                    to="https://therangenetwork.com/join-now/"
                    target="_blank"
                  >
                    Become a Partner Range
                  </Link>
                </li>
                <li className={activeItem === '/privacy' ? 'active' : ''}>
                  <Link to="/privacy">Privacy</Link>
                </li>
                {/* <li
                  className={activeItem === '/terms-conditions' ? 'active' : ''}
                >
                  <Link to="/terms-conditions">Member Agreement</Link>
                </li> */}
                {/* <li className="d-flex">
                  <Link to="#">
                    <i className="fa fa-twitter"></i>
                  </Link>
                  <Link to="#">
                    <i className="fa fa-facebook-official"></i>
                  </Link>
                  <Link to="#">
                    <i className="fa fa-youtube-play"></i>
                  </Link>
                </li> */}
              </ul>
            </nav>
          </header>
        </div>
      </div>
    </>
  );
};

Header.propTypes = {
  isMenuRequired: PropTypes.bool,
};
export default Header;
