import React from 'react';
import MainLayout from '../Layout/MainLayout';
import { Link } from 'react-router-dom';
import FindRangeMap from '../components/FindRangeMap';

const About = () => {
  return (
    <>
      <MainLayout>
        <div className="post-log-video">
          <video className="video-add" autoPlay loop muted>
            <source src="../video/video_preview_h264.mp4" type="video/mp4" />
          </video>
          <div className="hero-section">
            <h3>OUR GOAL IS TO MAKE YOUR TRIPS UNFORGETTABLE.</h3>
          </div>
        </div>
        <div className="liberty-outer">
          <div className="container">
            <div className="liberty-text">
              {/* <h4 className="sub-title-2">How it Works?</h4> */}
              <p className="bear-text pt-5">
                The Caliber Card is your passport to exploring the premier
                ranges around the country. We are bringing the shooting sports
                community together like never before. Ranges and shooting sports
                enthusiasts from across the country connect to focus on one
                thing, “Experience shooting like never before!”
              </p>
            </div>
            <div className="right-status d-none">
              <div className="row">
                <div className="col-md-4">
                  <div className="status-right-bear">
                    <h4>Join</h4>
                    <p>
                      The most important and yet, the simplest step – join! In
                      an instant, you will have access to a network of premier
                      ranges around the country at a special discount and with
                      special offers.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="status-right-bear px-md-3">
                    <h4>Explore</h4>
                    <p>
                      The Caliber Card® connects you with top ranges across the
                      country. Every time you travel you have access to a great
                      variety of experiences, instructors, and benefits. You can
                      browse the city of your destination and discover the
                      perfect way to enjoy your Membership.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="status-right-bear">
                    <h4>Experience</h4>
                    <p>
                      Every Caliber Card Member is greeted by the happy faces of
                      the range staff. From special events to “share-worthy”
                      shooting experiences, there’s no limit to the excitement
                      of being on a new range!
                    </p>
                  </div>
                </div>
                <div className="join-btn d-none">
                  <Link
                    to="/coverage"
                    className="theme-button login-button py-4"
                  >
                    join now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="user-create-card">
          <div className="card">
            <div className="img-set">
              <img
                src="../images/join_ico.svg"
                className="card-img-top"
                alt="..."
              />
            </div>
            <div className="card-body">
              <h5 className="card-title">JOIN</h5>
              <p>THE MOST IMPORTANT AND YET, THE SIMPLEST STEP – JOIN!</p>
              <p>
                In an instant, you will have access to a network of premier
                ranges around the country at a special discount and with special
                offers.
              </p>
            </div>
          </div>
          <div className="card">
            <div className="img-set">
              <img
                src="../images/searching.svg"
                className="card-img-top"
                alt="..."
              />
            </div>
            <div className="card-body">
              <h5>EXPLORE</h5>
              <p>
                THE CALIBER CARD® CONNECTS YOU WITH TOP RANGES ACROSS THE
                COUNTRY.
              </p>
              <p>
                Every time you travel you have access to a great variety of
                experiences, instructors, and benefits. You can browse the city
                of your destination and discover the perfect way to enjoy your
                Membership.
              </p>
            </div>
          </div>
          <div className="card">
            <div className="img-set">
              <img
                src="../images/experience_ico.svg"
                className="card-img-top"
                alt="..."
              />
            </div>
            <div className="card-body">
              <h5 className="card-title">EXPERIENCE</h5>
              <p>
                EVERY CALIBER CARD MEMBER IS GREETED BY THE HAPPY FACES OF THE
                RANGE STAFF.
              </p>
              <p>
                From special events to “share-worthy” shooting experiences,
                there’s no limit to the excitement of being on a new range!
              </p>
            </div>
          </div>
        </div>
        <div className="clint">
          <p>
            DON’T MISS OPPORTUNITIES TO VISIT AMAZING SHOOTING RANGES, SUPPORT
            BUSINESSES WHO PROTECT OUR COMMUNITIES, AND HANG OUT WITH
            LIKE-MINDED PEOPLE WHO SHARE A COMMON AFFINITY FOR THE SHOOTING
            SPORTS.
          </p>
        </div>
        <div className="caliber-card cal-card-2">
          <div className="container">
            <div className="row">
              <span className="brand-emblem">
                <img
                  src="../images/brand_emblem.svg"
                  alt="brand_emblem_logo"
                  width={119}
                  height={131}
                />
              </span>
              <h4>GET OFFERS AND UPDATES TO YOUR INBOX</h4>
              <p>
                Caliber Card Members get access to exclusive offers and access
                to unique events at each of our participating partner Ranges.
                Walking into a partner range is like entering an exciting world
                of new experiences, fun and community
              </p>
              <div className="join-form">
                <input
                  type="email"
                  className="form-control input-outline mb-0"
                  placeholder="your email"
                />
                <button className="theme-button m-0">Join Now</button>
              </div>
            </div>
          </div>
        </div>
        <FindRangeMap />
        <div className="liberty-outer about-net">
          <div className="container">
            <div className="liberty-text">
              <h4 className="sub-title-2">ABOUT THE RANGE NETWORK</h4>
              <p className="bear-text">
                Every visit and each shooting activity to one of our network
                ranges is designed to bring friends and family together to share
                an exhilarating experience and create lasting memories. When
                planning your next family vacation, road trip with friends or
                business trip, explore each range in our network for more
                information on how Caliber Card Members can benefit while
                traveling.
              </p>
            </div>
          </div>
        </div>
        <div className="lear-more">
          <img src="../images/the_range_network_logo.svg" alt="" />
          <button className="theme-button">Lear More</button>
        </div>
      </MainLayout>
    </>
  );
};

export default About;
