import React from 'react';
import AccountHeader from '../components/AccountHeader';
import AccountFooter from '../components/AccountFooter';
import Headroom from 'react-headroom';
import PropTypes from 'prop-types';

const AccountMainLayout = ({ children, className }) => {
  return (
    <>
      <div className="wrapper">
        <Headroom>
          <AccountHeader />
        </Headroom>
        <div className={`wrapper-body ${className}`}>{children}</div>
        <AccountFooter />
      </div>
    </>
  );
};

AccountMainLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default AccountMainLayout;
