import React from 'react';
import MainLayout from '../Layout/MainLayout';
import FindRangeMap from '../components/FindRangeMap';
import PackagePlan from '../components/PackagePlan';

const Education = () => {
  return (
    <>
      <MainLayout>
        <div className="post-log-video">
          <video className="video-add" autoPlay loop muted>
            <source src="../video/video_preview-2.mp4" type="video/mp4" />
          </video>
          <div className="hero-section">
            <h3>EXPLORE + SHOOT + FUN</h3>
            <p>
              Make the most of the Caliber Card benefits with insider range
              guides, upcoming events, and one-of-a-kind shooting experiences
              from the finest ranges in the United States.
            </p>
          </div>
        </div>
        <div className="liberty-outer">
          <div className="container">
            <div className="liberty-text">
              <p className="bear-text pt-5">
                Every shooting sports enthusiast agrees that ‘any day on the
                range is a good day.’ The Caliber Car is there so that even when
                you travel, ‘any day is a range day!’. Whether on the road for
                business or vacationing in another great city, the Caliber Card
                from The Range Network is there as you travel. Also, your
                membership helps support your local Participating Range and the
                community they serve. We put money into network ranges to help
                improve facilities and access to the shooting sport we all love!
                This helps ensure you will continue to have a place to go shoot
                now, and in the future.
              </p>
            </div>
          </div>
        </div>
        <div className="being-caliber-card">
          <div className="container-fluid">
            <div className="row">
              <h3>BENEFITS OF BEING CALIBER CARD MEMBER</h3>
              <div className="col-lg-4">
                <div className="net-work-card">
                  <div className="net-emblem">
                    <p>GAIN ACCESS TO NETWORK RANGES AROUND THE COUNTRY</p>
                    <div className="emblem">
                      <img src="../images/brand_emblem.svg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="net-work-card second">
                  <div className="net-emblem">
                    <p>SUPPORT THE SAFETY AND SECURITY OF OUR COMMUNITIES</p>
                    <div className="emblem">
                      <img src="../images/brand_emblem.svg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="net-work-card third">
                  <div className="net-emblem">
                    <p>
                      PROVIDE SKILLS PROGRESSION PROGRAMS AND TRAINING FOR LOCAL
                      YOUTH
                    </p>
                    <div className="emblem">
                      <img src="../images/brand_emblem.svg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="caliber-card cal-card-2 mt-5">
          <div className="container">
            <div className="row">
              <span className="brand-emblem">
                <img
                  src="../images/brand_emblem.svg"
                  alt="brand_emblem_logo"
                  width={119}
                  height={131}
                />
              </span>
              <h4>GET OFFERS AND UPDATES TO YOUR INBOX</h4>
              <p>
                Caliber Card Members get access to exclusive offers and access
                to unique events at each of our participating partner Ranges.
                Walking into a partner range is like entering an exciting world
                of new experiences, fun and community
              </p>
              <div className="join-form">
                <input
                  type="email"
                  className="form-control input-outline mb-0"
                  placeholder="your email"
                />
                <button className="theme-button m-0">Join Now</button>
              </div>
            </div>
          </div>
        </div>
        <FindRangeMap />
        <div className="join-card">
          <div className="container">
            <div className="row">
              <div className="join">
                <h4>JOIN NOW</h4>
              </div>
            </div>
          </div>
        </div>
        <PackagePlan />
        <div className="apply-21 mb-5">
          <div className="container">
            <div className="row">
              <p>Conditions Apply | Must be at least 21 years old to join</p>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default Education;
