import * as Yup from 'yup';
import { emailRegExp, passwordRegExp, phoneRegExp } from './common';

// Validation Schema
export const validationInformationSchema = Yup.object().shape({
  userType: Yup.string().required('User Type is required'),
  fullName: Yup.string().required('Full name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required')
    .matches(emailRegExp, 'Please enter a valid Email Address'),
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .matches(phoneRegExp, 'Please enter valid mobile number'),
  password: Yup.string()
    .required('Password is required')
    .matches(
      passwordRegExp,
      'Password must be between 8-40 characters, it must contain 1 upper case, 1 lower case, 1 number and 1 special character.'
    ),
  confirmPassword: Yup.string()
    .nullable()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password')], 'Password and Confirm Password should match'),
  DOB: Yup.date()
    .nullable()
    .required('Date of Birth is required')
    .transform((value, originalValue) => {
      if (originalValue === '') return null;
      return value;
    })
    .test('age', 'Invalid age', (value) => {
      if (!value) return false;
      const currentDate = new Date();
      const selectedDate = new Date(value);
      const minAgeDate = new Date();
      const maxAgeDate = new Date();
      minAgeDate.setFullYear(currentDate.getFullYear() - 18); // Minimum age of 18
      maxAgeDate.setFullYear(currentDate.getFullYear() - 95); // Maximum age of 95
      if (selectedDate > minAgeDate) {
        // Age less than 18
        throw new Yup.ValidationError('You have to be older than 18');
      } else if (selectedDate < maxAgeDate) {
        // Age greater than 95
        throw new Yup.ValidationError('You have to be younger than 95');
      }
      return true;
    }),
  address: Yup.string().required('Address is required'),
  state: Yup.string().required('State is required'),
  city: Yup.string().required('City is required'),
  zipCode: Yup.string().required('zipCode is required'),
  billingAddress: Yup.string(),
  billingState: Yup.string(),
  billingCity: Yup.string(),
  billingZipCode: Yup.string(),
});

// Validation Schema
export const validationInformationRangeSchema = Yup.object().shape({
  userTitle: Yup.string(),
  firstName: Yup.string().required('First name is required'),
  middleName: Yup.string(),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string(),
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .matches(phoneRegExp, 'Please enter valid mobile number'),
  secondaryPhone: Yup.string(),
  DOB: Yup.date()
    .nullable()
    .required('Date of Birth is required')
    .transform((value, originalValue) => {
      if (originalValue === '') return null;
      return value;
    })
    .test('age', 'Invalid age', (value) => {
      if (!value) return false;
      const currentDate = new Date();
      const selectedDate = new Date(value);
      const minAgeDate = new Date();
      const maxAgeDate = new Date();
      minAgeDate.setFullYear(currentDate.getFullYear() - 18); // Minimum age of 18
      maxAgeDate.setFullYear(currentDate.getFullYear() - 95); // Maximum age of 95
      if (selectedDate > minAgeDate) {
        // Age less than 18
        throw new Yup.ValidationError('You have to be older than 18');
      } else if (selectedDate < maxAgeDate) {
        // Age greater than 95
        throw new Yup.ValidationError('You have to be younger than 95');
      }
      return true;
    }),
  address: Yup.string().required('Address is required'),
  state: Yup.string().required('State is required'),
  billingAddress: Yup.string(),
});

export function generatePrice(digit) {
  return '$' + Number(digit).toFixed(2);
}

export const handleTabAction = (currentTabId, nextTabId) => {
  const currentTabButton = document.querySelector(`#${currentTabId}-tab`);
  currentTabButton.classList.remove('active');

  const currentTabContent = document.querySelector(`#${currentTabId}`);
  currentTabContent.classList.remove('active');
  currentTabContent.classList.remove('show');

  const nextTabButton = document.querySelector(`#${nextTabId}-tab`);
  nextTabButton.classList.add('active');

  const nextTabContent = document.querySelector(`#${nextTabId}`);
  nextTabContent.classList.add('show', 'active');
  window.scrollTo(0, 0);
};

export const getGreeting = () => {
  const myDate = new Date();
  const hrs = myDate.getHours();
  if (hrs < 12) {
    return 'Good Morning';
  } else if (hrs >= 12 && hrs <= 17) {
    return 'Good Afternoon';
  } else if (hrs >= 17 && hrs <= 24) {
    return 'Good Evening';
  }
};

export const generateSVGUrl = (color) => {
  // Customize the SVG content as per your requirements
  const svgString = `<svg
                    version="1.1"
                    id="_x32_"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="50"
                    height="50"
                    viewBox="0 0 512 512"
                    xmlSpace="preserve"
                    fill="${color}"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <style type="text/css">.st0{fill:${color};}</style>
                      <g>
                        <path
                          className="st0"
                          d="M256,0C159.969,0,82.125,77.859,82.125,173.906C82.125,269.938,236.797,512,256,512 c19.219,0,173.875-242.063,173.875-338.094C429.875,77.859,352.031,0,256,0z M256,240.406c-36.719,0-66.5-29.781-66.5-66.5 c0-36.75,29.781-66.531,66.5-66.531s66.516,29.781,66.516,66.531C322.516,210.625,292.719,240.406,256,240.406z"
                        ></path>
                      </g>
                    </g>
                  </svg>`;
  const svgDataURL = `data:image/svg+xml;base64,${btoa(svgString)}`;
  return svgDataURL;
};

export const convertUtcToTimezone = (utcDate) => {
  const targetTimezone = 'America/New_York'; // Target timezone (Eastern Standard Time)

  // Create a new date object representing the same time in the target timezone
  const convertedDate = new Date(
    utcDate.toLocaleString('en-US', {
      timeZone: targetTimezone,
    })
  );

  return convertedDate;
};
