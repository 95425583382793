import React from 'react';
import MainLayout from '../Layout/MainLayout';
import { Link } from 'react-router-dom';

const ThankYouUser = () => {
  return (
    <>
      <MainLayout>
        <div className="log-in-from login-height">
          <div className="container">
            <div className="row">
              <div className="t-y-u log-in-form">
                <h3 className="log-account-text">Thank you, ajay kumar !</h3>
                <Link
                  to="/find-a-range"
                  className="button-golden px-5 login-button mt-2"
                >
                  Make a Reservation
                </Link>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default ThankYouUser;
