import React from 'react';
// import MainLayout from '../Layout/MainLayout';
import Payment from './Payment';
import SignUpLayout from '../Layout/SignUpLayout';

const CheckOut = () => {
  return (
    <>
      <SignUpLayout>
        <Payment />
      </SignUpLayout>
    </>
  );
};

export default CheckOut;
