import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { logoutUser } from '../utils/Storage';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';

const AccountHeader = () => {
  const [activeItem, setActiveItem] = useState('');
  const { personalDetail } = useSelector((state) => state.signup);

  useEffect(() => {
    const path = window.location.pathname;
    setActiveItem(path);
  }, []);

  const handleLogout = () => {
    logoutUser();
    window.location.replace('/');
  };
  const handelNavClick = () => {
    document.body.classList.toggle('overflow__hidden');
  };

  return (
    <>
      <div className="navbar d-lg-block d-none">
        <div className="container">
          <header>
            <Link to="/" className="logo">
              <img src="../../images/logo-caliber.png" alt="logo" />
            </Link>
            <nav>
              <ul className="menu-bar account-menu">
                <li className={activeItem === '/overview' ? 'active' : ''}>
                  <Link to="/overview">Overview</Link>
                </li>

                <li
                  className={activeItem === '/account-coverage' ? 'active' : ''}
                >
                  <Link to="/account-coverage">Reservations</Link>
                </li>
                <li
                  className={activeItem === '/account-billing' ? 'active' : ''}
                >
                  <Link to="/account-billing">Billing</Link>
                </li>
                <li
                  className={`${activeItem === '/support' ? 'active' : ''}
                  ${
                    personalDetail?.subscriptions?.length > 0 ||
                    Cookies.get('isSubscribed') === 'true'
                      ? 'but-show'
                      : 'btn-no-show'
                  }`}
                >
                  <Link to="/support">Support</Link>
                </li>
                {(personalDetail?.subscriptions?.length > 0 ||
                  Cookies.get('isSubscribed') === 'true') && (
                  <li
                    className={
                      activeItem === '/find-a-range'
                        ? 'link-join active btn-no-show '
                        : 'link-join'
                    }
                  >
                    <Link
                      to="/find-a-range"
                      className={
                        activeItem === '/find-a-range'
                          ? 'btn-join pd active-btn '
                          : 'btn-join pd'
                      }
                    >
                      RSVP NOW
                    </Link>
                  </li>
                )}
              </ul>
            </nav>

            <div className="dropdown btn-group dropstart">
              <Link
                className="account_log_in"
                to="/profile-preferences"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fa fa-user-o" aria-hidden="true"></i>
              </Link>

              <ul
                className="dropdown-menu drop-m"
                aria-labelledby="dropdownMenuLink"
              >
                <li>
                  <Link
                    to="/account"
                    className={
                      activeItem === '/account'
                        ? ' dropdown-item border-list active-color'
                        : 'dropdown-item border-list'
                    }
                  >
                    Account
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      activeItem === '/profile-preferences'
                        ? ' dropdown-item border-list active-color'
                        : 'dropdown-item border-list'
                    }
                    to="/profile-preferences"
                  >
                    Profile & Preferences
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" onClick={handleLogout}>
                    Log out
                  </Link>
                </li>
              </ul>
            </div>
            {/* <Link to="/profile-preferences" className="log-in account_log_in">
              <i className="fa fa-user-o" aria-hidden="true"></i>
            </Link> */}
          </header>
        </div>
      </div>
      <div className="navbar navbar-mobile-menu d-lg-none d-block">
        <div className="container-fluid">
          <header>
            <Link to="/" className="logo">
              <img src="../../images/logo-caliber.png" alt="logo-caliber" />
            </Link>
            <nav>
              <input type="checkbox" id="click" />
              <label
                htmlFor="click"
                className="menu-btn"
                onClick={handelNavClick}
              >
                <i className="fa fa-align-right"></i>
              </label>
              <ul className="menu-bar">
                <li className={activeItem === '/overview' ? 'active' : ''}>
                  <Link to="/overview">Overview</Link>
                </li>
                <li
                  className={activeItem === '/account-coverage' ? 'active' : ''}
                >
                  <Link to="/account-coverage">Reservations</Link>
                </li>
                <li
                  className={activeItem === '/account-billing' ? 'active' : ''}
                >
                  <Link to="/account-billing">Billing</Link>
                </li>
                <li className={activeItem === '/support' ? 'active' : ''}>
                  <Link to="/support">Support</Link>
                </li>
                {(personalDetail?.subscriptions?.length > 0 ||
                  Cookies.get('isSubscribed') === 'true') && (
                  <li
                    className={activeItem === '/find-a-range' ? 'active' : ''}
                  >
                    <Link to="/find-a-range">RSVP Now</Link>
                  </li>
                )}
                <li className={activeItem === '/account' ? 'active' : ''}>
                  <Link to="/account">Account</Link>
                </li>
                <li
                  className={
                    activeItem === '/profile-preferences' ? 'active' : ''
                  }
                >
                  <Link to="/profile-preferences">Profile & preferences</Link>
                </li>
                <li>
                  <Link to="" onClick={handleLogout}>
                    Log out
                  </Link>
                </li>
                {/* <li className={activeItem === '/get-help-now' ? 'active' : ''}>
                  <Link to="/get-help-now">Get Help Now!</Link>
                </li> */}
              </ul>
            </nav>
          </header>
        </div>
      </div>
    </>
  );
};

export default AccountHeader;
