import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPackage } from '../redux/features/packages/packageSlice';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const PackagePlan = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.package.packages);
  const navigate = useNavigate();
  const { personalDetail } = useSelector((state) => state.signup);
  // const [registrationFee, setRegistrationFee] = useState(0);
  const [isPlan, setIsPlan] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(fetchPackage());
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (data) {
      data.map((item) => {
        {
          item.packages.map((package_data) => (
            <>
              {package_data.package_prices
                ?.filter(
                  (price_record) => price_record.price_frequency === 'One-Time'
                )
                .map(
                  (price) => console.log(price.price, 'Price')
                  // setRegistrationFee(price.price)
                )}
            </>
          ));
        }
      });
    }
  }, [data]);

  const handlePlan = (data) => {
    // let newData = data;
    // newData.selected_plan = isPlan ? 'Monthly' : 'Yearly';
    // console.log(newData);
    let isLifeTime = '';
    data.packages.map((package_data) => (
      <>
        {package_data.package_prices
          ?.filter(
            (price_record) => price_record.price_frequency === 'Lifetime'
          )
          .map((price) => {
            isLifeTime = price.price_frequency;
          })}
      </>
    ));
    localStorage.setItem('selectedPlan', JSON.stringify(data));
    localStorage.setItem('selectedTerm', !isPlan ? 'month' : 'year');

    if (isLifeTime !== '') {
      localStorage.setItem('selectedPlanType', 'Lifetime');
    } else {
      localStorage.setItem('selectedPlanType', !isPlan ? 'Monthly' : 'Yearly');
    }

    if (
      personalDetail?.subscriptions?.length === 0 &&
      Cookies.get('isSubscribed') === undefined
    ) {
      localStorage.setItem('joinEmail', personalDetail?.email);
      navigate('/plan-checkout');
    } else {
      navigate('/joinform');
    }
  };

  return (
    <>
      <div className="plan-select-mon-yer">
        <div className="form-check form-switch">
          <label
            className="form-check-label m"
            htmlFor="monthly"
            style={{ textAlign: 'left' }}
          >
            Monthly
          </label>
          <input
            className={`form-check-input ${!isPlan ? 'month' : 'yearly'}`}
            type="checkbox"
            id={!isPlan ? 'annually' : 'monthly'}
            onChange={() => setIsPlan(!isPlan)}
            checked={isPlan}
          />
          <label className="form-check-label" htmlFor="annually">
            Annually
          </label>
        </div>
      </div>
      <div
        className={
          data && data.length === 3 ? 'card-price ' : 'card-price pd-outer'
        }
      >
        {data && (
          <>
            {!isPlan ? (
              <>
                {data.map((item, index) => (
                  // <div className="col-md-4">
                  <div className="card" key={index}>
                    {item.packages.map((package_data, ind) => (
                      <React.Fragment key={ind}>
                        {package_data.package_prices
                          ?.filter(
                            (price_record) =>
                              price_record.price_frequency === 'Monthly' ||
                              price_record.price_frequency === 'Lifetime'
                          )
                          .map((price, id) => (
                            <div className="price-dcpt" key={id}>
                              <h5 className="description-price" key={id}>
                                ${price.price + ' '}
                                <span className="font-c">
                                  {price.description}
                                </span>
                                <br />
                                <span>{package_data?.description}</span>
                              </h5>
                            </div>
                          ))}
                      </React.Fragment>
                    ))}

                    {item.name === 'Annual' && (
                      <div className="card-img-price">
                        <img
                          src="../images/Caliber_Cards_Card.png"
                          className="card-img-top img-fluid"
                          alt="Silver Card"
                        />
                      </div>
                    )}

                    {item.name === 'GOLD' && (
                      <div className="card-img-price">
                        <img
                          src="../images/Caliber_Cards_Card.png"
                          className="card-img-top img-fluid"
                          alt="Gold Member Card"
                        />
                      </div>
                    )}
                    {item.name === 'Lifetime' && (
                      <div className="card-img-price">
                        <img
                          src="../images/Caliber_Cards_Lifetime.png"
                          className="card-img-top img-fluid"
                          alt="Lifetime MemberShip Card"
                        />
                      </div>
                    )}

                    <div className="card-body">
                      {/* <h4>{item.name}</h4> */}
                      <h5>Benefits Include:</h5>
                      <ul>
                        {item.features?.map((feature) => (
                          <li key={feature.id}>{feature.name}</li>
                        ))}
                      </ul>
                      <p>(**Registration fee: $0)</p>
                      <button
                        className="theme-button select-plan-btn"
                        onClick={() => handlePlan(item)}
                      >
                        Select Plan
                      </button>
                    </div>
                  </div>
                  // </div>
                ))}
                {/* <a href="/active-account">active-account</a> */}
              </>
            ) : (
              <>
                {data.map((item, index) => (
                  // <div className="col-md-4">

                  <div className="card" key={index}>
                    {item.packages
                      //  ((item) => item.add_on === 0)
                      .map((package_data, ind) => (
                        <React.Fragment key={ind}>
                          {package_data.package_prices
                            ?.filter(
                              (price_record) =>
                                price_record.price_frequency === 'Yearly' ||
                                price_record.price_frequency === 'Lifetime'
                            )
                            .map((price, id) => (
                              <div className="price-dcpt" key={id}>
                                <h5 className="description-price" key={id}>
                                  ${price.price + ' '}
                                  <span className="font-c">
                                    {price.description}
                                  </span>
                                  <br />
                                  <span>
                                    Unlimited access to our indoor, outdoor and
                                    shotgun ranges during business hours.
                                    {/* {package_data?.description} */}
                                  </span>
                                </h5>
                              </div>
                            ))}
                        </React.Fragment>
                      ))}

                    {item.name === 'Annual' && (
                      <img
                        src="../images/Caliber_Cards_Card.png"
                        className="card-img-top"
                        alt="..."
                      />
                    )}

                    {item.name === 'GOLD' && (
                      <img
                        src="../images/Caliber_Cards_Card.png"
                        className="card-img-top"
                        alt="..."
                      />
                    )}
                    {item.name === 'Lifetime' && (
                      <img
                        src="../images/Caliber_Cards_Lifetime.png"
                        className="card-img-top"
                        alt="..."
                      />
                    )}

                    <div className="card-body">
                      {/* <h4>{item.name}</h4> */}
                      <h5>Benefits Include:</h5>
                      <ul>
                        {item.features?.map((feature) => (
                          <li key={feature.id}>{feature.name}</li>
                        ))}
                      </ul>
                      <p>(**Registration fee: $0)</p>
                      <button
                        className="theme-button select-plan-btn"
                        onClick={() => handlePlan(item)}
                      >
                        Select Plan
                      </button>
                    </div>
                  </div>
                  // </div>
                ))}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default PackagePlan;
