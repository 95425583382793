import React from 'react';
import MainLayout from '../Layout/MainLayout';

const LostGiftCard = () => {
  return (
    <>
      <MainLayout>
        <div className="log-in-from login-height">
          <div className="container">
            <div className="row">
              <div className="lost">Lost your gift card?</div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default LostGiftCard;
