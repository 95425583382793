import React, { useEffect, useState } from 'react';
import AccountMainLayout from '../Layout/AccountMainLayout';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  STRIPE_PUBLISHABLE_KEY,
  billingOverview,
  paymentMethodApi,
} from '../utils/endpoint';
import { deleteApi, getApi, putApi } from '../utils/api';
import Cookies from 'js-cookie';
import { generatePrice } from '../utils/helpers';
import { ThreeDots } from 'react-loader-spinner';
import ErrorBanner from './ErrorBanner';
import { useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from '../components/PaymentForm';
// import { logoutUser } from '../utils/Storage';
import moment from 'moment';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const AccountBilling = () => {
  const [show, setShow] = useState(false);
  const [invoice, setInvoice] = useState();
  const [upcomingInvoice, setUpcomingInvoice] = useState();
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isPrimary, setIsPrimary] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedDeleteId, setSelectedDeleteId] = useState();
  const [showError, setShowError] = useState(false);
  const [showErrorDetail, setShowErrorDetail] = useState({
    isError: true,
    message: '',
  });
  const [isError, setIsError] = useState('');

  const { personalDetail } = useSelector((state) => state.signup);
  const navigate = useNavigate();
  const access_token = Cookies.get('userToken');
  const { state } = useLocation();
  const { dataState } = state || '';

  useEffect(() => {
    if (
      personalDetail?.subscriptions?.length === 0 &&
      Cookies.get('isSubscribed') === undefined
    ) {
      navigate('/plans');
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getApi(billingOverview, access_token)
      .then((res) => {
        if (res?.data?.success) {
          setInvoice(res?.data.data.invoices);
          setUpcomingInvoice(res?.data.data.upcomingInvoice);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    fetchPaymentMethod();
  }, []);

  const fetchPaymentMethod = () => {
    getApi(paymentMethodApi, access_token)
      .then((res) => {
        if (res?.data?.success) {
          setPaymentMethodList(res?.data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const handleTabAction = (currentTabId, nextTabId) => {
  //   const currentTabButton = document.querySelector(`#${currentTabId}-tab`);
  //   currentTabButton.classList.remove("active");

  //   const currentTabContent = document.querySelector(`#${currentTabId}`);
  //   currentTabContent.classList.remove("active");
  //   currentTabContent.classList.remove("show");

  //   const nextTabButton = document.querySelector(`#${nextTabId}-tab`);
  //   nextTabButton.classList.add("active");

  //   const nextTabContent = document.querySelector(`#${nextTabId}`);
  //   nextTabContent.classList.add("show", "active");
  //   window.scrollTo(0, 0);
  // };

  const handleClose = () => {
    setShowError(false);
    setShowErrorDetail({
      isError: true,
      message: '',
    });
  };

  // const handleUpdatePayment = (e) => {
  //   setShowErrorDetail({
  //     isError: false,
  //     message: 'We have sent you an email to update your payment method',
  //   });
  //   setShowError(true);
  //   setTimeout(() => {
  //     setShowError(false);
  //     setShowErrorDetail({
  //       isError: true,
  //       message: '',
  //     });
  //   }, 4000);
  // };
  function getPlanType(upcomingInvoice) {
    const periodStart = moment.unix(upcomingInvoice.period_start);
    const periodEnd = moment.unix(upcomingInvoice.period_end);

    // Calculate the difference in years
    const yearsDifference = periodEnd.diff(periodStart, 'years', true);
    console.log(yearsDifference, 'yearsDifference');
    // Check if the difference is greater than or equal to 1 (approximately one year)
    if (yearsDifference >= 1) {
      return 'Yearly';
    } else {
      return 'Monthly';
    }
  }

  const handleNavigate = () => {
    const tabEle = document.querySelectorAll('.nav-link');
    // Remove active class from tab list
    const activeElements = document.querySelectorAll('.active');
    activeElements.forEach((element) => {
      if (element?.classList?.length > 1) {
        element.classList.remove('active');
      }
    });

    // Add active class to Payment History tab
    tabEle.forEach((element) => {
      if (element.id === 'nav-profile-tab') {
        element.classList.add('active');
        const tabContent = document.getElementById('nav-profile');
        tabContent.classList.add('active', 'show');
      }
    });
  };

  const handleDelete = (e) => {
    e.preventDefault();
    setIsDelete(true);
    setIsError();
    deleteApi(paymentMethodApi + '/' + selectedDeleteId, access_token)
      .then((res) => {
        if (res?.data?.success) {
          fetchPaymentMethod();
          setIsDelete(false);
          setSelectedDeleteId();
          const modalClose = document.getElementsByClassName('cancel-sure')[0];
          modalClose.click();
        }
      })
      .catch((err) => {
        setIsError(err?.response?.data?.message);
        setIsDelete(false);
      });
  };

  const handleDeleteConfirmation = (id) => {
    setSelectedDeleteId(id);
  };

  const handleCloseSure = () => {
    setSelectedDeleteId();
    setIsError();
  };

  const handleUpdateDefault = (e, id, index) => {
    e.preventDefault();
    setIsPrimary(true);
    setSelectedIndex(index);
    putApi(paymentMethodApi + '/' + id + '/default', {}, access_token)
      .then((res) => {
        if (res?.data?.success) {
          fetchPaymentMethod();
          setIsPrimary(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsPrimary(false);
      });
  };

  useEffect(() => {
    if (dataState === 'paymentMethod') {
      const tabEle = document.querySelectorAll('.nav-link');
      // Remove active class from tab list
      const activeElements = document.querySelectorAll('.active');
      activeElements.forEach((element) => {
        if (element?.classList?.length > 1) {
          element.classList.remove('active');
        }
      });

      // Add active class to Payment History tab
      tabEle.forEach((element) => {
        if (element.id === 'nav-contact-tab') {
          element.classList.add('active');
          const tabContent = document.getElementById('nav-contact');
          tabContent.classList.add('active', 'show');
          console.log(dataState, element.id, 'paymentMethod ');
        }
      });
    }
  }, [paymentMethodList]);

  const cardBrandImages = {
    visa: '../images/cards/visa.svg',
    mastercard: '../images/cards/master-card.svg',
    amex: '../images/cards/amex.svg',
    discover: '../images/cards/discover.svg',
    circle: '../images/cards/circle.svg',
  };

  return (
    <>
      <AccountMainLayout>
        <div className="account-login pt-5">
          {isLoading && (
            <div className="loader">
              <ThreeDots
                height="110"
                width="110"
                radius="10"
                color="#aeaeae"
                ariaLabel="three-dots-loading"
                wrapperStyle={{ display: 'contents' }}
                wrapperClassName=""
                visible={true}
              />
            </div>
          )}

          {!isLoading && (
            <div className="container-fluid">
              {showError && (
                <ErrorBanner
                  error={showErrorDetail}
                  handleClose={handleClose}
                />
              )}
              <div className="row">
                <nav className="tabs-bills">
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                      className="nav-link active"
                      id="nav-home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-home"
                      type="button"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      Billing Overview
                    </button>
                    <button
                      className="nav-link"
                      id="nav-profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-profile"
                      type="button"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                    >
                      Payment History
                    </button>
                    <button
                      className="nav-link"
                      id="nav-contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-contact"
                      type="button"
                      role="tab"
                      aria-controls="nav-contact"
                      aria-selected="false"
                    >
                      Payment Methods
                    </button>
                  </div>
                </nav>
                <div className="tab-content pane-bill" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    {upcomingInvoice && upcomingInvoice?.total !== 0 && (
                      <>
                        <h3 className="up-coming-bill">Upcoming Bills</h3>
                        <div className="card bills-card">
                          <table className="amount-card mobile-responsive">
                            <thead>
                              <tr>
                                <th>Due Date</th>
                                <th>Description</th>
                                <th>Amount Due</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td data-label="Due Date">
                                  {upcomingInvoice?.date &&
                                    upcomingInvoice?.date}
                                </td>

                                <td data-label="Description">
                                  {upcomingInvoice.product_name}
                                </td>
                                <td data-label="Amount Due">
                                  {upcomingInvoice?.amount &&
                                    upcomingInvoice?.amount}
                                </td>
                                <td data-label="Status">
                                  {upcomingInvoice?.status === 'draft'
                                    ? 'Upcoming'
                                    : ''}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}
                    <div className="history">
                      <h3 className="up-coming-bill">
                        Payment History (Last 90 Days)
                      </h3>
                      <Link
                        className="link-text"
                        type="button"
                        onClick={handleNavigate}
                      >
                        View Full Payment History
                      </Link>
                    </div>
                    <div className="card bills-card mb-4">
                      <table className="amount-card mobile-responsive">
                        <thead>
                          <tr>
                            <th>Payment Date</th>
                            <th>Description</th>
                            <th>Amount</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoice?.map((item, index) => (
                            <tr key={index}>
                              <td data-label="Payment Date-">{item?.date}</td>
                              <td data-label="Description">
                                {item.product_name}
                              </td>
                              <td data-label="Amount">{item?.amount}</td>
                              <td data-label="Status">
                                {item?.status.charAt(0).toUpperCase() +
                                  item?.status.slice(1)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-profile"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                  >
                    <div className="card bills-card mt-3 table-responsive">
                      <table className="amount-card mobile-responsive">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Description</th>
                            <th>Amount</th>
                            <th>Mode Of Payment</th>
                            <th>Card Description</th>
                            <th>Status</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoice?.map((item, index) => (
                            <tr key={index}>
                              <td data-label="Date">{item?.date}</td>
                              <td data-label="Description">
                                {item.product_name}
                              </td>
                              <td data-label="Amount">{item?.amount}</td>
                              <td data-label="Mode Of Payment">Credit Card</td>
                              <td data-label="Card Description">
                                <p
                                  style={{
                                    whiteSpace: 'wrap',
                                  }}
                                >
                                  {' '}
                                  {item?.card_info?.brand
                                    .charAt(0)
                                    .toUpperCase() +
                                    item?.card_info?.brand.slice(1)}
                                  (****{item?.card_info?.last4}) - Expires:
                                  {' ' + item?.card_info?.exp_month}/
                                  {item?.card_info?.exp_year}
                                </p>
                              </td>
                              <td data-label="Status">
                                {item?.status.charAt(0).toUpperCase() +
                                  item?.status.slice(1)}
                              </td>
                              <td>
                                <Link
                                  to="#"
                                  className="details-list2"
                                  onClick={() => setShow(!show)}
                                >
                                  Details
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    {show ? (
                      <div className="card bills-card mt-3 mt-md-5 mb-4">
                        <table className="amount-card mobile-responsive">
                          <thead>
                            <tr>
                              <th>Plan</th>
                              <th>Amount</th>
                              <th>Term</th>
                              <th>Order</th>
                              <th>Tax</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {invoice?.map((item, index) => (
                              <tr key={index}>
                                <td data-label="Plan">{item.product_name}</td>
                                <td data-label="Amount">{item?.amount}</td>
                                <td data-label="Term">{getPlanType(item)}</td>
                                <td data-label="Order">{item?.number}</td>
                                <td data-label="Tax">
                                  {item?.tax && generatePrice(item?.tax)}
                                </td>
                                <td data-label="Total">
                                  {item?.total &&
                                    generatePrice(item?.total / 100)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : null}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-contact"
                    role="tabpanel"
                    aria-labelledby="nav-contact-tab"
                  >
                    {paymentMethodList?.length > 0 && (
                      <>
                        <Link
                          className="link-text m-0 d-block d-md-none text-end mt-4"
                          to="/payment-method-new"
                          // data-bs-toggle="modal"
                          // data-bs-target="#staticBackdrop-payment-update"
                        >
                          Add Payment Method
                        </Link>
                        <div className="card bills-card mt-3 mb-4">
                          <table className="amount-card mobile-responsive">
                            <thead>
                              <tr>
                                <th>Type</th>
                                <th colSpan="9">Description</th>
                                <th style={{ textAlign: 'right' }}>
                                  {/* <Link
           onClick={handleUpdatePayment}
           className="button-golden size-font m-0"
           data-bs-toggle="modal"
           data-bs-target="#staticBackdrop-payment-update"
         >
           update payment method
         </Link> */}
                                  <Link
                                    className="link-text"
                                    // data-bs-toggle="modal"
                                    // data-bs-target="#staticBackdrop-payment-update"
                                    to="/payment-method-new"
                                  >
                                    Add Payment Method
                                  </Link>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {paymentMethodList?.map((item, index) => (
                                <tr key={index}>
                                  <td data-label="Type">
                                    {item?.card?.funding === 'credit'
                                      ? 'Credit '
                                      : 'Debit '}
                                    Card
                                  </td>
                                  <td
                                    data-label="Description"
                                    style={{
                                      whiteSpace: 'wrap',
                                    }}
                                  >
                                    <img
                                      className="me-2"
                                      src={cardBrandImages[item?.card?.brand]}
                                      alt={`${item?.card?.brand} Card `}
                                    />
                                    **** **** **** {item?.card?.last4}
                                  </td>
                                  <td colSpan="2">
                                    {item.is_default ? 'Primary' : 'Secondary'}
                                  </td>
                                  <td className="delete-payment">
                                    <button
                                      className={`button-golden border-golden size-font 
                                    ${isDelete ? 'disabled-delete' : ''}
                                    `}
                                      data-bs-toggle="modal"
                                      data-bs-target="#staticBackdrop-confirmation"
                                      onClick={() =>
                                        handleDeleteConfirmation(item.id)
                                      }
                                    >
                                      Delete
                                    </button>
                                  </td>
                                  <td>
                                    {!item.is_default && (
                                      <button
                                        className={`details-list2`}
                                        onClick={(e) =>
                                          handleUpdateDefault(e, item.id, index)
                                        }
                                      >
                                        {isPrimary &&
                                        selectedIndex === index ? (
                                          <ThreeDots
                                            height="15"
                                            width="25"
                                            radius="10"
                                            color="#1A2A3F"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                          />
                                        ) : (
                                          ' Set as primary'
                                        )}
                                      </button>
                                    )}
                                  </td>
                                  {/* <td className="d-none"></td> */}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}
                    {paymentMethodList?.length === 0 && (
                      <div className="method-found">
                        <p>
                          No Payment method found, Please add a new payment
                          method.
                        </p>
                        <Link
                          className="link-text"
                          // data-bs-toggle="modal"
                          // data-bs-target="#staticBackdrop-payment-update"
                          to="/payment-method-new"
                        >
                          Add Payment Method
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className="modal fade"
            id="staticBackdrop-payment-update"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body d-flex">
                  <Elements stripe={stripePromise}>
                    <PaymentForm
                      // Heading="Add a new payment method"
                      ButtonFirst="Add"
                      fetchPaymentMethod={fetchPaymentMethod}
                    />
                  </Elements>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="staticBackdrop-confirmation"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body delete-text">
                <p>Are you sure you wish to delete this card detail?</p>
              </div>
              <div className="modal-footer cancel-button">
                <button
                  type="button"
                  className={`cancel ok` + (isDelete ? ' disabled-btn' : '')}
                  onClick={handleDelete}
                >
                  {isDelete ? (
                    <ThreeDots
                      height="30"
                      width="30"
                      radius="9"
                      color="#fff"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    'Yes'
                  )}
                </button>
                <button
                  type="button"
                  className="cancel cancel-sure"
                  data-bs-dismiss="modal"
                  onClick={handleCloseSure}
                >
                  No
                </button>
              </div>
              {isError && (
                <div className="mb-3 text-center text-danger">{isError}</div>
              )}
            </div>
          </div>
        </div>
      </AccountMainLayout>
    </>
  );
};

export default AccountBilling;
