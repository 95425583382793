import React from 'react';
import { Link } from 'react-router-dom';
import { addAddress, updateAddress } from '../utils/endpoint';
import Cookies from 'js-cookie';
import { patchApi, postApi } from '../utils/api';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { state_city } from '../utils/state_cities';
import PropTypes from 'prop-types';
import { ThreeDots } from 'react-loader-spinner';

const AccountForm = ({
  Heading,
  personalDetail,
  ButtonFirst,
  fetchAddressList,
}) => {
  const statelist = Object.keys(state_city);
  // const [isError, setIsError] = useState(false);
  // const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    fullName:
      personalDetail?.fullName ||
      personalDetail?.name ||
      personalDetail?.firstname
        ? personalDetail?.fullName ||
          personalDetail?.name ||
          personalDetail?.firstname
        : '',
    // lastName:
    //   personalDetail?.lastName ||
    //   personalDetail?.last_name ||
    //   personalDetail?.lastname
    //     ? personalDetail?.lastName ||
    //       personalDetail?.last_name ||
    //       personalDetail?.lastname
    //     : '',
    phoneNumber:
      personalDetail?.phoneNumber ||
      personalDetail?.telephone ||
      personalDetail?.phone_number
        ? personalDetail?.phoneNumber ||
          personalDetail?.telephone ||
          personalDetail?.phone_number
        : '',
    company: personalDetail?.company ? personalDetail?.company : '',
    address:
      personalDetail?.address || personalDetail?.street_address1
        ? personalDetail?.address || personalDetail?.street_address1
        : '',
    address2:
      personalDetail?.address2 || personalDetail?.street_address2
        ? personalDetail?.address2 || personalDetail?.street_address2
        : '',
    state: personalDetail?.state ? personalDetail?.state : '',
    city: personalDetail?.city ? personalDetail?.city : '',
    country: personalDetail?.country
      ? personalDetail?.country
      : 'United States',
    zipCode:
      personalDetail?.zipcode || personalDetail?.postal_code
        ? personalDetail?.zipcode || personalDetail?.postal_code
        : '',
    isDefault: personalDetail?.default ? personalDetail?.default : 0,
    addressType: personalDetail?.address_type
      ? personalDetail?.address_type
      : 'billing',
  });

  const [isDefault, setIsDefault] = useState(formData.isDefault);
  const access_token = Cookies.get('userToken');
  const formRef = useRef(null);
  const [fieldErrors, setFieldErrors] = useState({});
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  useEffect(() => {
    handleReset();
  }, []);

  useEffect(() => {
    setFormData({
      fullName:
        personalDetail?.fullName ||
        personalDetail?.name ||
        personalDetail?.firstname
          ? personalDetail?.fullName ||
            personalDetail?.name ||
            personalDetail?.firstname
          : '',
      // lastName:
      //   personalDetail?.lastName ||
      //   personalDetail?.last_name ||
      //   personalDetail?.lastname
      //     ? personalDetail?.lastName ||
      //       personalDetail?.last_name ||
      //       personalDetail?.lastname
      //     : '',
      phoneNumber:
        personalDetail?.phoneNumber ||
        personalDetail?.telephone ||
        personalDetail?.phone_number
          ? personalDetail?.phoneNumber ||
            personalDetail?.telephone ||
            personalDetail?.phone_number
          : '',
      company: personalDetail?.company ? personalDetail?.company : '',
      address:
        personalDetail?.address || personalDetail?.street_address1
          ? personalDetail?.address || personalDetail?.street_address1
          : '',
      address2:
        personalDetail?.address2 || personalDetail?.street_address2
          ? personalDetail?.address2 || personalDetail?.street_address2
          : '',
      state: personalDetail?.state ? personalDetail?.state : '',
      city: personalDetail?.city ? personalDetail?.city : '',
      country: personalDetail?.country
        ? personalDetail?.country
        : 'United States',
      zipCode:
        personalDetail?.zipcode || personalDetail?.postal_code
          ? personalDetail?.zipcode || personalDetail?.postal_code
          : '',
      isDefault: personalDetail?.default ? personalDetail?.default : 0,
      addressType: personalDetail?.address_type
        ? personalDetail?.address_type
        : 'billing',
    });
    let selected = personalDetail?.default === 1 ? true : false;
    setIsDefault(selected);
  }, [personalDetail]);

  const handleAddAddress = (e) => {
    e.preventDefault();
    let obj = {
      firstname: formData?.fullName,
      lastname: '',
      street_address1: formData?.address,
      city: formData?.city,
      country: formData?.country,
      state: formData?.state,
      postal_code: formData?.zipCode,
      phone_number: formData?.phoneNumber,
      company: '',
      street_address2: formData?.address2,
      address_type: formData?.addressType,
      default: isDefault,
    };
    // if (!state_city[formData?.state].includes(formData?.city)) {
    //   console.log("Not a valid United States city.");
    //   setIsError(true);
    //   setErrorMessage("Not a valid United States city.");
    //   setTimeout(() => {
    //     setIsError(false);
    //     setErrorMessage("");
    //   }, 3000);
    // } else {
    setIsLoading(true);
    postApi(addAddress, obj, access_token)
      .then((res) => {
        if (res?.data?.success) {
          const modalClose = document.getElementsByClassName('cancel-add');
          for (let i = 0; i < modalClose.length; i++) {
            modalClose[i].click();
          }
          fetchAddressList();
          handleReset();
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
    // }
  };

  const handleUpdateAddress = (e) => {
    e.preventDefault();
    let obj = {
      firstname: formData?.fullName,
      lastname: '',
      street_address1: formData?.address,
      city: formData?.city,
      country: formData?.country,
      state: formData?.state,
      postal_code: formData?.zipCode,
      phone_number: formData?.phoneNumber,
      company: formData?.company,
      street_address2: formData?.address2,
      address_type: formData?.addressType,
      default: isDefault,
    };
    setIsLoading(true);

    patchApi(updateAddress + personalDetail?.id, obj, access_token)
      .then((res) => {
        if (res?.data?.success) {
          const modalClose = document.getElementsByClassName('btn-close');
          for (let i = 0; i < modalClose.length; i++) {
            modalClose[i].click();
          }
          handleReset();
          fetchAddressList();
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleManualSubmit = async (e) => {
    e.preventDefault();
    const form = formRef.current;
    if (form.checkValidity()) {
      // if (!state_city[formData?.state].includes(formData?.city)) {
      //   setIsError(true);
      //   setErrorMessage('Not a valid United States city.');
      //   setTimeout(() => {
      //     setIsError(false);
      //     setErrorMessage('');
      //   }, 3000);
      // } else
      setFieldErrors({});

      if (personalDetail?.id) {
        await handleUpdateAddress(e);
      } else {
        await handleAddAddress(e);
      }
    } else {
      const newFieldErrors = {};
      for (let i = 0; i < form.elements.length; i++) {
        const field = form.elements[i];
        if (
          field.tagName === 'INPUT' ||
          field.tagName === 'TEXTAREA' ||
          field.tagName === 'SELECT'
        ) {
          if (!field.validity.valid) {
            newFieldErrors[field.name] = true;
          }
        }
      }
      setFieldErrors(newFieldErrors);
      form.reportValidity();
    }
  };
  const handleDefault = (e) => {
    let selected = e.target.checked === true ? 1 : 0;
    setIsDefault(selected);
  };

  const handleReset = () => {
    setFormData({
      fullName: '',
      // lastName: '',
      phoneNumber: '',
      company: '',
      address: '',
      address2: '',
      state: '',
      city: '',
      country: 'United States',
      zipCode: '',
      isDefault: 0,
      addressType: 'billing',
    });
    setIsDefault(false);
    setFieldErrors({});
  };
  const handleResetUpdate = () => {
    setFormData({
      fullName:
        personalDetail?.fullName ||
        personalDetail?.name ||
        personalDetail?.firstname
          ? personalDetail?.fullName ||
            personalDetail?.name ||
            personalDetail?.firstname
          : '',
      // lastName:
      //   personalDetail?.lastName ||
      //   personalDetail?.last_name ||
      //   personalDetail?.lastname
      //     ? personalDetail?.lastName ||
      //       personalDetail?.last_name ||
      //       personalDetail?.lastname
      //     : '',
      phoneNumber:
        personalDetail?.phoneNumber ||
        personalDetail?.telephone ||
        personalDetail?.phone_number
          ? personalDetail?.phoneNumber ||
            personalDetail?.telephone ||
            personalDetail?.phone_number
          : '',
      company: personalDetail?.company ? personalDetail?.company : '',
      address:
        personalDetail?.address || personalDetail?.street_address1
          ? personalDetail?.address || personalDetail?.street_address1
          : '',
      address2:
        personalDetail?.address2 || personalDetail?.street_address2
          ? personalDetail?.address2 || personalDetail?.street_address2
          : '',
      state: personalDetail?.state ? personalDetail?.state : '',
      city: personalDetail?.city ? personalDetail?.city : '',
      country: personalDetail?.country
        ? personalDetail?.country
        : 'United States',
      zipCode:
        personalDetail?.zipcode || personalDetail?.postal_code
          ? personalDetail?.zipcode || personalDetail?.postal_code
          : '',
      isDefault: personalDetail?.default ? personalDetail?.default : 0,
      addressType: personalDetail?.address_type
        ? personalDetail?.address_type
        : 'billing',
    });
    let selected = personalDetail?.default === 1 ? true : false;
    setIsDefault(selected);
    setFieldErrors({});
  };

  return (
    <>
      <div className="container">
        <form id="earlyForm" ref={formRef}>
          <div className="row">
            <h2 className="profile pro-changes">{Heading}</h2>
            <div className="col-lg-6">
              <div className="floating-group floating-b ">
                <input
                  type="name"
                  className={
                    'form-control' +
                    (fieldErrors.fullName ? ' error-input-border-red' : '')
                  }
                  required
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleInputChange}
                />
                <label htmlFor="fullName">Full Name*</label>
              </div>
            </div>
            <div className="col-lg-6 d-none">
              <div className="floating-group floating-b">
                {/* <input
                  type="name"
                  className="form-control"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  // required
                /> */}
                <label htmlFor="LastName">Last name</label>
              </div>
            </div>
            <div className="col-lg-6 d-none">
              <div className="floating-group floating-b">
                <input
                  type="text"
                  className="form-control"
                  name="company"
                  value={formData.company}
                  onChange={handleInputChange}
                  // required
                />
                <label htmlFor="floatingThree">Company</label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-floating floating-b">
                <input
                  type="tel"
                  maxLength="10"
                  minLength="10"
                  className={
                    'form-control' +
                    (fieldErrors.phoneNumber ? ' error-input-border-red' : '')
                  }
                  required
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onKeyPress={(e) => {
                    const regex = /^[0-9\b]+$/;
                    if (!regex.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={handleInputChange}
                  id="floatingNumber"
                  placeholder="Phone Number*"
                />
                <label htmlFor="floatingNumber">Phone Number*</label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="floating-group floating-b">
                <input
                  type="address"
                  required
                  name="address"
                  value={formData.address}
                  className={
                    'form-control' +
                    (fieldErrors.address ? ' error-input-border-red' : '')
                  }
                  onChange={handleInputChange}
                />
                <label htmlFor="floatingFour">Street Address 1*</label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-floating floating-b">
                <input
                  type="address"
                  className="form-control"
                  name="address2"
                  value={formData.address2}
                  onChange={handleInputChange}
                  placeholder="Street Address 2"
                />
                <label htmlFor="floatingFive">Street Address 2</label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="floating-group floating-b select-floating">
                <select
                  id="inputState"
                  className="form-select unite"
                  onChange={handleInputChange}
                  value={formData.country}
                >
                  <option value={'United States'} defaultValue="United States">
                    United States
                  </option>
                </select>
                <label htmlFor="inputState">Country</label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="floating-group floating-b select-floating">
                <select
                  id="inputState1"
                  onChange={handleInputChange}
                  value={formData.state}
                  name="state"
                  className={
                    'form-select unite' +
                    (fieldErrors.state ? ' error-input-border-red' : '')
                  }
                  required
                >
                  <option value="" disabled defaultValue="">
                    State
                  </option>
                  {statelist.map((item, index) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
                </select>
                <label htmlFor="inputState1">State*</label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="floating-group floating-b">
                <input
                  type="text"
                  className={
                    'form-control' +
                    (fieldErrors.city ? ' error-input-border-red' : '')
                  }
                  required
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                />
                <label htmlFor="floatingSix">City*</label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="floating-group floating-b">
                <input
                  type="text"
                  className={
                    'form-control' +
                    (fieldErrors.zipCode ? ' error-input-border-red' : '')
                  }
                  required
                  name="zipCode"
                  value={formData.zipCode}
                  onChange={handleInputChange}
                  maxLength="5"
                  onKeyPress={(e) => {
                    const regex = /^[0-9\b]+$/;
                    if (!regex.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
                <label htmlFor="floatingZip">Postal/ZIP code*</label>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="billing-list-check mt-4">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="flexRadioDefault1"
                    value="home"
                    name="addressType"
                    checked={formData.addressType === 'home'}
                    onChange={handleInputChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    Home Address
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="flexRadioDefault2"
                    value="billing"
                    name="addressType"
                    checked={formData.addressType === 'billing'}
                    onChange={handleInputChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault2"
                  >
                    Billing Address
                  </label>
                </div>
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input check-focus"
                  type="checkbox"
                  id="gridCheck"
                  name="isDefault"
                  checked={isDefault}
                  onChange={handleDefault}
                />
                <label className="form-check-label df" htmlFor="gridCheck">
                  Set as default address
                </label>
              </div>
            </div>
            <div className="col-lg-12">
              {/* {isError && (
                <div className="fw-bold badge bg-danger text-center w-100 fs-6">
                  {errorMessage}
                </div>
              )} */}
            </div>
            <div className="col-lg-12">
              <div className="d-flex gap-2">
                <Link
                  className={
                    'button-black size-button' +
                    (isLoading ? ' disabled-btn' : '')
                  }
                  onClick={handleManualSubmit}
                >
                  {isLoading ? (
                    <div className="d-flex justify-content-center">
                      <ThreeDots
                        height="20"
                        width="93"
                        radius="2"
                        color="#fff"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName="loader-style"
                        visible={true}
                      />
                    </div>
                  ) : (
                    ButtonFirst
                  )}
                </Link>
                <Link
                  to="#"
                  id="cancel-id"
                  className="button-black  size-button size-border cancel-add"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={
                    ButtonFirst === 'Add address'
                      ? handleReset
                      : handleResetUpdate
                  }
                >
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

AccountForm.propTypes = {
  personalDetail: PropTypes.object,
  Heading: PropTypes.string,
  ButtonFirst: PropTypes.string,
  fetchAddressList: PropTypes.func,
};

export default AccountForm;
