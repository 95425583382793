import React, { useEffect, useState } from 'react';
import MainLayout from '../Layout/MainLayout';
import CardItems from '../components/CardItems';
import { eventsApi } from '../utils/endpoint';
import { getApi } from '../utils/api';
import { ThreeDots } from 'react-loader-spinner';
import moment from 'moment';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const Events = () => {
  const [eventList, setEventList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getApi(eventsApi)
      .then((res) => {
        if (res?.data?.success) {
          setEventList(res?.data?.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <MainLayout>
        <div className="post-log shoot-img">
          <div className="container-fluid">
            <div className="row">
              <div className="hero-section">
                <h3>Shoot more when you get away.</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="liberty-outer liberty pb-0">
          <div className="container">
            <div className="liberty-text">
              {/* <h4 className="sub-title-2">Choose Membership Plan</h4> */}
              <h4 className="sub-title-2">Experiences</h4>
              <p>
                Looking for a comprehensive listing of upcoming activities and
                happenings? Look no further; from engaging workshops to exciting
                range activities, our events page offers a diverse range of
                opportunities for you to explore and participate in. Stay
                up-to-date with the latest events by regularly visiting our
                website&apos;s dedicated events section.
              </p>
            </div>
          </div>
        </div>
        <div className="event-card-list">
          <div className="container">
            <div className="row">
              <div className="event-user-man">
                <div className="com-12">
                  <div className="event-heading d-none">
                    <h4>Experiences</h4>
                    <p>
                      Looking for a comprehensive listing of upcoming activities
                      and happenings? Look no further; from engaging workshops
                      to exciting range activities, our events page offers a
                      diverse range of opportunities for you to explore and
                      participate in. Stay up-to-date with the latest events by
                      regularly visiting our website&apos;s dedicated events
                      section.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={isLoading ? 'event-dote padding-add' : 'event-dote'}
              >
                <h4 className="event-mane-heading">All Experiences</h4>

                {isLoading && (
                  <div className={isLoading ? 'loading-dote ' : ''}>
                    <ThreeDots
                      height="120"
                      width="120"
                      radius="9"
                      color="var(--black)"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                )}
                {!isLoading && (
                  <Carousel
                    responsive={responsive}
                    className="gd-carousel"
                    showDots={true}
                  >
                    {eventList?.map((item, index) => (
                      <div className="experiences-card" key={index}>
                        <CardItems
                          ImagesCard={
                            item.image ? item.image : '../../images/event-3.jpg'
                          }
                          CardHeading={item.title}
                          DateCard={`${moment(item.date).format('M/D/YY')} ${
                            item.location
                          }`}
                          CardText={item.description}
                          HrefLink={item.link}
                          eventData={item}
                        />
                      </div>
                    ))}
                  </Carousel>
                )}
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default Events;
