import React from 'react';
import MainLayout from '../Layout/MainLayout';
import { Link } from 'react-router-dom';

const FaqPage = () => {
  return (
    <>
      <MainLayout>
        <div className="liberty-outer liberty">
          <div className="container">
            <div className="liberty-text">
              <h4 className="sub-title-2">About the Range Network</h4>
              <p className="bear-text pb-md-5 pb-0">
                Every visit and each shooting activity to one of our network
                ranges is designed to bring friends and family together to share
                an exhilarating experience and create lasting memories. When
                planning your next family vacation, road trip with friends or
                business trip, explore each range in our network for more
                information on how Caliber Card Members can benefit while
                traveling.
              </p>
              <p className="bear-text pb-md-5 pb-0">
                At the Range Network, we believe that positive, family friendly
                and safe firearm experiences, which can come about only when we
                all work together to invest in the heritage of sport, our
                children’s understanding of our Constitutional rights, and the
                safety of our neighbors. The Caliber Card represents the
                strengthening of community and the necessity of individual
                skills progression in the safe and welcoming environments our
                partner ranges provide.
              </p>
              <p className="bear-text pb-md-5 pb-0">
                The partners of the Range Network are an essential part of our
                mission to strengthen each community we serve. Thank you for
                your membership!
              </p>

              <Link
                to="https://rangenetwork.yatilabs.com/about-the-range-network"
                className="mb-4"
              >
                Link to Range Network site
              </Link>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default FaqPage;
