import { createSlice } from '@reduxjs/toolkit';
import { postApi } from '../../../utils/api';
import { signup, updateProfile } from '../../../utils/endpoint';
import Cookies from 'js-cookie';

const initialState = {
  personalDetail: {},
  billingDetail: {},
  questionnaireDetail: {},
  isSubscribed: false,
};

const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    setPersonalDetail: (state, action) => {
      state.personalDetail = action.payload;
    },
    setBillingDetail: (state, action) => {
      state.billingDetail = action.payload;
    },
    setQuestionnaireDetail: (state, action) => {
      state.questionnaireDetail = action.payload;
    },
    setIsSubscribed: (state, action) => {
      state.isSubscribed = action.payload;
    },
  },
});

export const {
  setPersonalDetail,
  setBillingDetail,
  setQuestionnaireDetail,
  setIsSubscribed,
} = signupSlice.actions;

export const signupApi = (data) => async (dispatch) => {
  try {
    const formdata = new FormData();
    formdata.append('name', data?.fullName);
    formdata.append('last_name', '');
    formdata.append('email', data.email);
    formdata.append('telephone', data.phoneNumber);
    formdata.append('password', data.password);
    formdata.append('password_confirmation', data.confirmPassword);
    formdata.append('user_type', data.userType);
    formdata.append('company_name', data.company);
    formdata.append('dob', data.DOB);
    formdata.append('gender', 'Male');
    formdata.append('city', data.city);
    formdata.append('state', data.state);
    formdata.append('zipcode', data.zipCode);
    formdata.append('address', data.address);
    formdata.append('address2', data.address2);
    formdata.append('country', 'US');

    if (data.isSameAddress === false) {
      formdata.append('billing_city', data.billingCity);
      formdata.append('billing_state', data.billingState);
      formdata.append('billing_zipcode', data.billingZipCode);
      formdata.append('billing_address', data.billingAddress);
      formdata.append('billing_address2', data.billingOptionalAddress);
      formdata.append('billing_country', 'US');
    }
    const res = await postApi(signup, formdata);
    if (res.data && res.data.data) {
      Cookies.set('userToken', res.data.data.token);
      let resData = res.data.data;
      resData.password = data.password;
      resData.isSameAddress = data.isSameAddress;
      resData.confirmPassword = data.confirmPassword;
      dispatch(setPersonalDetail(resData));
    } else {
      throw new Error('Failed to sign up. Please try again.');
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateProfileApi = (data, access_token) => async (dispatch) => {
  try {
    const formdata = new FormData();
    formdata.append('name', data?.fullName);
    formdata.append('last_name', '');
    formdata.append('email', data.email);
    formdata.append('telephone', data.phoneNumber);
    formdata.append('password', data.password);
    formdata.append('password_confirmation', data.confirmPassword);
    formdata.append('user_type', data.userType);
    formdata.append('company_name', data.company);
    formdata.append('dob', data.DOB);
    formdata.append('gender', 'Male');
    formdata.append('city', data.city);
    formdata.append('state', data.state);
    formdata.append('zipcode', data.zipCode);
    formdata.append('address', data.address);
    formdata.append('address2', data.address2);
    formdata.append('country', 'US');
    if (data.isSameAddress === false) {
      formdata.append('billing_city', data.billingCity);
      formdata.append('billing_state', data.billingState);
      formdata.append('billing_zipcode', data.billingZipCode);
      formdata.append('billing_address', data.billingAddress);
      formdata.append('billing_address2', data.billingOptionalAddress);
      formdata.append('billing_country', 'US');
    }
    const res = await postApi(updateProfile, formdata, access_token);
    if (res.data && res.data.data) {
      let resData = res.data.data;
      resData.password = data.password;
      resData.confirmPassword = data.confirmPassword;
      dispatch(setPersonalDetail(resData));
    } else {
      throw new Error('Failed to sign up. Please try again.');
    }
  } catch (error) {
    console.log(error);
  }
};

export default signupSlice.reducer;
