import React from 'react';
import MainLayout from '../Layout/MainLayout';
import { Link } from 'react-router-dom';

const TermsConditions = () => {
  return (
    <>
      <MainLayout>
        <div className="terms-and-conditions">
          <div className="container">
            <div className="row">
              <div className="conditions">
                <img src="../images/logo.svg" alt="" className="my-5 d-none" />
                <h3 className="my-5">SUBSCRIBING MEMBER AGREEMENT</h3>
                <p>
                  As a ‘Subscribing Member’ of The Range Network, I understand
                  and accept the responsibility, conditions associated. I
                  further understand that by accessing, browsing, or otherwise
                  using The Range Network website or any other digital
                  application or platform, or by completing the Subscriber
                  Membership Registration, or by obtaining benefits offered
                  through this agreement, that I hereby agree to be bound by the
                  terms and conditions of this Subscription Agreement
                  hereinafter referred to as Agreement.
                </p>
                <h3>AGREEMENT</h3>
                <p>
                  I hereby acknowledge that I have read, understand, and agree
                  with the terms, conditions, policies and procedures
                  established by The Range Network, LLC.
                </p>
                <h3>PARTIES</h3>
                <p>
                  This Agreement is entered into by and between you, the
                  Subscribing Member, and The Range Network, LLC, herein ‘TRN’,
                  the representative for Participating Network Ranges.
                </p>
                <h4>TERMS</h4>
                <ul>
                  <li>
                    The TERMS “we,” “us,” “our,” “TRN,” “Company,” or “GRUSA”
                    shall refer to, The Range Network, LLC, and Shooting Sports
                    Group, USA, LLC dba Gun Range USA The terms “you,” “your,”
                    “User,” or “customer” shall refer to any individual and/or
                    public or private entity who accepts this Agreement.
                  </li>
                  <li>
                    Subscriber and/or Subscribing Member shall mean the
                    individual who signs an Agreement to establish a TRN
                    account, has access to an account, or uses TRN services,
                    whatever they may be.
                  </li>
                  <li>
                    The Term “Network” shall mean the collective group known as
                    The Range Network, to include ALL associated entities that
                    agree to provide benefits and services, at any level, to a
                    Subscribing Member.
                  </li>
                  <li>
                    The Term ‘Participating Range’ shall mean any range that has
                    accepted the Agreement to provide services to TRN
                    Subscribing Members.
                  </li>
                  <li>
                    The Term ‘Prohibited Person’ shall mean the definition by
                    the Gun Control Act (GCA), codified at 18 U.S.C. § 922(g){' '}
                    <Link to="#">
                      {' '}
                      [Identify Prohibited Persons | Bureau of Alcohol, Tobacco,
                      Firearms and Explosives (atf.gov)]
                    </Link>{' '}
                    and the definition of each applicable state
                    <ul className="ps-list pt-1">
                      <li>
                        By signing this agreement, you acknowledge that the laws
                        vary from state to state, and it is your responsibility
                        to know and comply with the law.
                      </li>
                      <li>
                        Nothing in this Agreement shall be deemed to confer any
                        third-party rights or benefits, nor shall any part of
                        this Agreement confer support for any illegal or
                        unlawful activity of any kind.
                      </li>
                    </ul>
                  </li>
                </ul>

                <h3>EFFECTIVE DATE</h3>
                <p className="mb-1">
                  This Agreement is made effective as of YOUR first use of this
                  website (“Site”) and, or,
                </p>
                <ul>
                  <li>
                    First use of any other associated digital application,
                  </li>
                  <li>Date of electronic acceptance of this Agreement.</li>
                </ul>
                <h3>SCOPE</h3>
                <p className="mb-1">
                  This Agreement sets forth the general terms and conditions of
                  your use of the Site and the products and services purchased
                  or accessed through this Site (individually and collectively,
                  the “Services”).
                </p>
                <ul>
                  <li>
                    Services Agreements and additional policies apply to certain
                    Services and are in addition to (not in lieu of) this
                    Agreement.
                  </li>
                  <li>
                    In the event of a conflict between the provisions of a
                    Services Agreement and the provisions of this Agreement, the
                    provisions of the applicable Services Agreement shall
                    control.
                  </li>
                </ul>
                <h3>SERVICES PROVIDED</h3>
                <ul>
                  <li>
                    TRN is a National Range Subscription Service that provides
                    discounted access to Participating Ranges around the
                    country.
                  </li>
                  <li>Subscribing Member benefits are defined as</li>
                </ul>
                <ul className="ps-list">
                  <li className="color-h">Gold Membership</li>
                  <ul>
                    <li>
                      Twelve (12) visits to participating ranges<sup>1</sup>
                    </li>
                    <li>
                      Free Range Pass for Subscribing Member<sup>2</sup>
                    </li>
                    <li>50% off Range Pass for one (1) Guest</li>
                    <li>
                      Free Handgun Rental<sup>3</sup>
                    </li>
                    <li>One (1) Free Target</li>
                    <li>Free Hearing and Eye Protection</li>
                    <li>
                      10% Off Same Day Training<sup>4</sup>
                    </li>
                  </ul>
                </ul>
                <ul className="ps-list">
                  <li className="color-h">Silver Membership</li>
                  <ul>
                    <li>
                      Six (6) Visits to Participating Ranges Annually
                      <sup>1</sup>
                    </li>
                    <li>
                      50% Off Range Pass for Subscribing Member<sup>2</sup>
                    </li>
                    <li>
                      Free Standard Handgun Rental<sup>3</sup>
                    </li>
                    <li>One (1) Free Target</li>
                    <li>
                      Hearing and Eye Protection Available<sup>4</sup>
                    </li>
                    <li>
                      10% Off Same Day Training<sup>5</sup>
                    </li>
                  </ul>
                </ul>
                <h4>Notes:</h4>
                <p className="mb-2 ps-md-3">
                  <sup>1</sup>Subscribing Members may only visit the same Range
                  once per annual membership cycle unless otherwise permitted by
                  local Range.
                </p>
                <p className="mb-2 ps-md-3">
                  <sup>2</sup>Range pass is for Subscribing Member Only.
                </p>
                <p className="mb-2 ps-md-3">
                  <sup>3</sup>Conditions Apply. Some ranges require guests to
                  bring a friend. Must purchase ammunition at regular price
                </p>
                <p className="mb-2 ps-md-3">
                  <sup>4</sup>Eye and Hearing Protection may come with a fee for
                  Silver Memberships. Local Host Range Policy Applies.
                </p>
                <p className="mb-2 ps-md-3">
                  <sup>5</sup>
                  Training course availability is subject to the Terms,
                  Conditions, and Availability of Host range.
                </p>
                <h3>USER REGISTRATION INFORMATION AND ACCOUNT PROFILE</h3>
                <ul>
                  <li>
                    Subscribing Member shall provide all requested information
                    on the User Registration/Member Profile Form.
                  </li>
                  <li>
                    Subscribing Member shall promptly update the User
                    Information on the Site when changes occur.
                  </li>
                  <li>
                    In order to access some of the features of this Site or use
                    some of the Services, subscriber must create an Account.
                  </li>
                  <li>
                    You represent and warrant to The Range Network that all
                    information you submit when you create your Account is
                    accurate, current, and complete and that you will keep your
                    Account information accurate, current, and complete.
                  </li>
                  <li>
                    If The Range Network has reason to believe that your Account
                    information is untrue, inaccurate, out-of-date or
                    incomplete, we reserve the right, in our sole and absolute
                    discretion, to suspend or terminate your Account.
                  </li>
                  <li>
                    Subscriber is solely responsible for the activity that
                    occurs on your Account, whether authorized by you or not,
                    and you must keep your Account information secure, including
                    without limitation your customer number/login, password,
                    Payment Method(s) (as defined below), and User Profile INFO.
                    For security purposes, The Range Network recommends that you
                    change your password and User Profile INFO at least once
                    every three (3) months for each Account.
                  </li>
                </ul>
                <h3>FEES AND PAYMENTS</h3>
                <ul>
                  <li>
                    You agree that unless you obtain a Membership to The Range
                    Network through a Network Range Provider, your Subscription
                    shall be paid directly to The Range Network.
                  </li>
                  <li>
                    If, during your purchase, your payment was identified as
                    being processed in the United States, your transaction will
                    be processed by Payment System Gateways as contracted by
                    Shooting Sports Group, USA.
                  </li>
                </ul>
                <h4 className="mt-0">FEES PAID TO NETWORK RANGES</h4>
                <ul>
                  <li>
                    If you are subscribing through an authorized Network Range,
                    you agree that your Payment Method may be charged by one of
                    our affiliated entities.
                  </li>
                  <li>
                    Payments made through local Network Ranges are subject to
                    the Terms and Conditions of their payment gateway providers
                  </li>
                </ul>
                <h4>
                  GENERAL PAYMENT TERMS, INCLUDING AUTOMATIC RENEWAL TERMS
                </h4>
                <ul>
                  <li>
                    Payment is Due at Time of Order and may be Non-Refundable.
                  </li>
                  <li>
                    You agree to pay all amounts due for Services at the time
                    you order them.
                  </li>
                  <li>All Subscriptions, Network Fees and Dues Auto-pay</li>
                  <ul>
                    <li>
                      You agree that, unless you cancel your applicable
                      Subscription Membership prior to the effective date of the
                      change, you will be charged the new applicable periodic
                      Subscription fee (plus applicable taxes) on each periodic
                      Payment Date after the effective date of such change and
                      you authorize The Range Network to charge the new periodic
                      Subscription fee (plus applicable taxes) to your Payment
                      Card.
                    </li>
                    <li>
                      The Range Network is a Membership based Network Program.
                      As such, the amount due is based on services available,
                      NOT SERVICES RENDERED.
                    </li>
                    <li>
                      Membership fees are due whether or not you utilize the
                      services.
                    </li>
                    <li>
                      All monies paid to The Range Network, including Subscriber
                      Dues, are non-refundable unless otherwise noted in the
                      Refund Policy.
                    </li>
                  </ul>
                </ul>
                <h4>PRICE CHANGES</h4>
                <ul>
                  <li>
                    The Range Network reserves the right to change its prices
                    and fees at any time, and such changes shall be posted
                    online at this Site and effective immediately without the
                    need for further notice to you
                  </li>
                  <li>
                    If you have purchased or obtained Services for a period of
                    months or years, changes in prices and fees shall be
                    effective when the Services in question come up for renewal,
                    as further described below
                  </li>
                  <li>
                    You agree that Subscription Membership at The Range Network
                    does not involve an extension of credit or a retail
                    installment sale since The Range Network does not impose a
                    finance charge, and you may choose to continue.
                  </li>
                </ul>
                <h4>NETWORK PARTNERS, PREMIUM RANGES AND AMBASSADOR CLUBS</h4>
                <ul>
                  <li>
                    If you are a member of a Network Partner or Premium Range or
                    an Ambassador Club, your TRN Subscription may be included
                    with your membership or sold separately.
                  </li>
                  <li>
                    Check with your range to determine their participation level
                    and subscription plan.
                  </li>
                  <li>
                    If your TRN subscription is purchased through your range,
                    then you will manage all payment issues through them.
                  </li>
                </ul>
                <h4>
                  EXPIRATION DATE AND REFUSAL OF CHARGES TO YOUR PAYMENT CARD.
                </h4>
                <ul>
                  <li>
                    If your Payment Card, for any reason, will not accept
                    charges for any Membership fees, your Subscribing Member
                    Membership will automatically be suspended.
                  </li>
                  <li>
                    In order for your Subscription to be reinstated, you must
                    update your Payment Information at TheRangeNetwork.Com by
                    logging into the Membership Portal and navigating to your
                    Subscription Membership to update the payment information.
                  </li>
                </ul>
                <h3>SUSPENSION/TERMINATION</h3>
                <ul>
                  <li>
                    The Range Network reserves the right to suspend, modify or
                    end the Subscription Membership without prior notice, in its
                    sole discretion in accordance with local, state and federal
                    regulations.
                  </li>
                  <li>
                    The Range Network may suspend or terminate your Subscription
                    Membership at any time for any breach of this Agreement.
                  </li>
                  <li>
                    In the case of suspension, termination, revocation, or other
                    derogatory action, TRN will provide written or verbal
                    notification.
                  </li>
                  <li>
                    In the event of Termination, Suspension, Revocation, or
                    Cancellation, you remain liable for unpaid dues and other
                    charges against your Account, including late fees or fees
                    due to lack of funds.
                  </li>
                  <li>
                    Subscription Fees are non-refundable except in minimal
                    circumstances.
                  </li>
                </ul>
                <h3>MODIFICATION OF AGREEMENT, SITE, OR SERVICES</h3>
                <ul>
                  <li>
                    The Range Network may, in its sole and absolute discretion,
                    change or modify this Agreement and any policies or
                    agreements which are incorporated herein at any time, and
                    such changes or modifications shall be effective immediately
                    upon posting to this Site.
                  </li>
                  <li>
                    Your use of this Site or Services after such changes or
                    modifications have been made shall constitute your
                    acceptance of this Agreement as last revised.
                  </li>
                  <li>
                    If you do not agree to be bound by this Agreement as last
                    revised, do not use (or continue to use) this Site or
                    Services.
                  </li>
                  <li>
                    In addition, The Range Network may occasionally notify you
                    of changes or modifications to this Agreement by email.
                  </li>
                  <ul>
                    <li>
                      It is, therefore, very important that you keep your User
                      Profile account (“Account”) information current.
                    </li>
                    <li>
                      The Range Network assumes no liability or responsibility
                      for your failure to receive an email notification if such
                      failure results from an inaccurate email address.
                    </li>
                  </ul>
                  <li>
                    THE RANGE NETWORK RESERVES THE RIGHT TO MODIFY, CHANGE, OR
                    DISCONTINUE ANY ASPECT OF THIS SITE OR SERVICES, INCLUDING
                    WITHOUT LIMITATION PRICES AND FEES FOR THE SAME, AT ANY
                    TIME.
                  </li>
                  <ul>
                    <li>
                      The Range Network may introduce new venues or programs
                      from time to time, which may be available to Subscribing
                      Members for an additional fee.
                    </li>
                    <li>
                      The Range Network may publish and amend policies and
                      regulations for the venues or modify its fees for
                      Subscription Membership, from time to time and for any
                      reason.
                    </li>
                    <li>
                      You acknowledge that The Range Network may change
                      Subscription Membership fees at any time, provided,
                      however, that The Range Network will give prior notice to
                      Subscription Membership holders of any change to the
                      periodic Subscription Membership fees.
                    </li>
                  </ul>
                </ul>
                <h3>WAIVER OF LIABILITY, HOLD-HARMLESS, AND INDEMNIFICATION</h3>
                <ul>
                  <li>
                    You acknowledge that the shooting sports are inherently
                    dangerous and that you participate solely at your own risk.
                  </li>
                  <ul>
                    <li>
                      Participation in The Range Network Network Range’s is
                      completely voluntary.
                    </li>
                    <li>
                      By participating, you hereby agree to now, and forever in
                      perpetuity, hold-harmless and waive all liability of The
                      Range Network, its Parent Company, Shooting Sports Group,
                      USA, LLC, its employees, partners, vendors, and
                      Participating Network Range Facilities, their officers,
                      employees, and vendors.
                    </li>
                  </ul>
                  <li>
                    You agree to sign all host Network Range waivers prior to
                    using their facilities or as needed for updates and/or
                    changes to waivers. You hereby agree that you assume
                    liability for all risk and any injury, loss, theft, or
                    damage to personal property or any other loss, whatever it
                    may be, regardless of cause, and without limitation.
                  </li>
                  <li>
                    You agree to indemnify, defend and hold harmless TRN and TRN
                    Network Ranges, including but not limited to any TRN or TRN
                    Network Range’s affiliate officers or employees against any
                    third-party claim, action, loss demands, damages, expense or
                    other liability (including without limitations, attorneys’
                    fees and expenses) incidental to or arising out of or
                    relating to the acts or omissions by TRN or TRN Network
                    Ranges and Subscribing Member guests while using or
                    occupying TRN Network Range facilities.
                  </li>
                  <li>
                    You understand that TRN or TRN Network Ranges are not
                    responsible for any damage caused by another Subscribing
                    Member, non-Subscribing Member, or guest of TRN or TRN
                    Network Ranges.
                  </li>
                  <li>
                    Subscribing Members assume sole responsibility for his or
                    her property and the property of all those included in the
                    Subscribing Member and any of the Subscribing Member’s
                    guests. The TRN or TRN Network Ranges shall not be
                    responsible for any loss or damage to any private property
                    used or stored on TRN or TRN Network Ranges premises.
                  </li>
                  <li>
                    Subscribing Members hereby agree to abide by S.A.F.E.
                    Firearms practices at all times including following the Four
                    Fundamental Rules of Firearm Safety: S.A.F.E.
                  </li>
                  <ul>
                    <li>
                      SAFE DIRECTION: I will keep all firearms pointed in a safe
                      direction at all times. A SAFE Direction means that should
                      the firearm discharge unintentionally, no person, animal
                      or property would be damaged, destroyed, injured or
                      killed.
                    </li>
                    <li>
                      ALWAYS LOADED: Treat all firearms as loaded. Never assume
                      a firearm is unloaded. Check, Double Check, and then Check
                      again! Physically and visually check the firearm is clear.
                      If you don’t know how, ask! Don’t “Try to figure it out”.
                    </li>
                    <li>
                      FINGER OFF THE TRIGGER: Keep your finger off the trigger
                      until you are ready to shoot. Keep your finger extended
                      along the frame of the firearm. Only move your finger to
                      the trigger when you are ready to shoot.
                    </li>
                    <li>
                      ENVIRONMENT, KNOW YOUR TARGET AND BEYOND: Always check
                      what’s around you, and what’s beyond your target when
                      shooting. Make sure your target and the area beyond the
                      target are clear.
                    </li>
                  </ul>
                </ul>
                <h3>VIOLATION OF ANY POLICIES AND PROCEDURES</h3>
                <ul>
                  <li>
                    All Subscribing Members must comply with all Policies,
                    Procedures, and Range Rules of host Participating Network
                    Range Facilities, as well as comply with all local, state,
                    and federal firearm and ammunition laws.
                  </li>
                  <li>
                    I understand that Ranges Participate by Agreement and that
                    each Range ALWAYS reserves the right to refuse service to
                    any person for any reason.
                  </li>
                  <li>
                    I acknowledge that each Participating Range is locally
                    owned, operated, and completely independent of The Range
                    Network.
                  </li>
                  <li>
                    I understand that Participating Network Ranges do not
                    represent the owners, employees and/or partners of Shooting
                    Sports Group, USA, or The Range Network.
                  </li>
                  <li>
                    In the event that a Subscribing Member fails to comply with
                    said rules and regulations, TRN may:
                  </li>
                  <ul>
                    <li>Suspend all Subscription Membership privileges or</li>
                    <li>
                      Void this Agreement and revoke all Subscription Membership
                      privileges.
                    </li>
                    <li>
                      TRN reserves the right to amend or alter these rules and
                      regulations at any time in our sole discretion.
                    </li>
                  </ul>
                  <li>
                    All amendments to these rules and regulations shall be
                    effective immediately.
                  </li>
                  <li>Damage to TRN Network Ranges</li>
                  <ul>
                    <li>
                      As a Range Network Subscribing Member, you are hereby
                      liable for any damage to a Host Network Range caused by
                      you, regardless of causality.
                    </li>
                    <li>
                      You are responsible for any damage caused by you and your
                      guest.
                    </li>
                    <li>
                      You hereby agree to reimburse the host Participating
                      Network Range for any damages to property as a result of
                      your actions or the actions of your guest, regardless if
                      the damage was caused by negligence, carelessness, or by
                      accident.
                    </li>
                    <li>
                      In case of damage or injury, you may notify The Range
                      Network Operations Center to assist you in determining the
                      damage amount in order to ensure you are not over-charged
                      for damages.
                    </li>
                  </ul>
                </ul>
                <h3>ENTIRE AGREEMENT AND DEFINITION</h3>
                <ul>
                  <li>
                    This document DOES NOT represent the Entire Agreement
                    between Subscribing Members and Shooting Sports Group, USA
                    dba The Range Network. This Agreement is the Subscribing
                    Member Agreement Only.
                  </li>
                  <li>
                    All persons are further subject to the “End User Licensing
                    Agreement” (EULA) as part of their use of the website and
                    digital applications. (SEE WEBSITE)
                  </li>
                  <li>
                    The Range Network reserves the right to modify this
                    Agreement at any time.
                  </li>
                  <li>
                    Subscribing Members may access agreements via their
                    Subscribing Member portal.
                  </li>
                  <li>
                    There are no representations, warranties, terms, conditions,
                    undertakings, or collateral agreements, express, implied, or
                    statutory, between the parties hereto except as specified in
                    this Agreement.
                  </li>
                </ul>
                <h3>PRIVACY AND DATA PROTECTION</h3>
                <ul>
                  <li>
                    <h4 className="mb-0 pb-0">BREACH OF SECURITY</h4>
                  </li>
                  <ul>
                    <li>
                      You must notify The Range Network immediately of any
                      breach of security or unauthorized use of your Account.
                    </li>
                    <li>
                      THE RANGE NETWORK WILL NOT BE LIABLE FOR ANY LOSS YOU
                      INCUR DUE TO ANY UNAUTHORIZED USE OF YOUR ACCOUNT
                    </li>
                    <li>
                      You, however, may be liable for any loss The Range Network
                      or others incur caused by your Account, whether caused by
                      you, by an authorized person, or by an unauthorized
                      person.
                    </li>
                    <li>
                      TRN shall not sell, give, or otherwise provide any
                      Subscribing Members Personal Protected Information (PPI)
                      to any other Participating Network Range or other Third
                      Party without the Subscribing Member&#39;s express
                      consent.
                    </li>
                    <li>
                      TRN utilizes some third-party applications to manage the
                      Network. User and Range information may be available to
                      these partners. However, TRN ensures all partners comply
                      with strict data privacy policies.
                    </li>
                  </ul>
                </ul>
                <h3>TERMINATING MEMBERSHIP SUBSCRIPTION</h3>
                <ul>
                  <li>
                    Subscribing Membership shall terminate upon expiration of
                    the current agreement unless renewed by the Subscribing
                    Member.
                  </li>
                  <li>
                    Subscription may be terminated by TRN at any time for any
                    reason including breach of contract, failure to pay,
                    violations of host range codes of conduct, or any other
                    violations considered unacceptable to TRN.
                  </li>
                  <li>
                    Termination of Subscription by TRN shall be effective
                    immediately.
                  </li>
                  <li>Members may terminate their agreement at any time.</li>
                  <li>
                    Subscriptions terminated by the Member shall end on the
                    45-days from the date of notice
                  </li>
                  <ul>
                    <li>
                      Subscribing Members are still responsible for payment.
                    </li>
                    <li>
                      This means that if the subscribing member cancels their
                      membership prior to end of the contracted term, the
                      Subscribing Member shall pay the next two monthly
                      payments.
                    </li>
                    <li>
                      If subscription is on auto-pay, the auto-pay system will
                      stop debiting or billing the subscribing member account
                      after the second billing cycle from confirmed receipt of
                      notification of cancellation.
                    </li>
                    <li>
                      Upon cancellation, any uploaded content shall remain
                      available to the public. It is the Subscribing Members
                      responsibility to delete any content or notify TRN of
                      desire to delete posted content.
                    </li>
                  </ul>
                </ul>
                <h3>GOVERNING LAW</h3>
                <p>
                  Where applicable, all legal action shall be determined under
                  the laws of the State of Texas.
                </p>
                <h3>ARBITRATION</h3>
                <p>
                  Any dispute arising under this contract shall be resolved
                  under the commercial arbitration rules of the American
                  Arbitration Association.
                </p>
                <h3>LIMITATION OF LIABILITY</h3>
                <p>
                  Subscriber shall not hold TRN and TRN Network Ranges (and its
                  officers, directors, agents, subsidiaries, joint ventures, and
                  employees) responsible for other user Subscribers&apos;
                  content, actions, or inactions.
                </p>
                <h3>MODELING AND PHOTOGRAPHY AGREEMENT</h3>
                <p>
                  As a condition of acceptance of my application for Membership
                  Subscription, I give The Range Network and its agents and
                  designees permission to use or distribute, without limitation
                  or obligation, my image, name, voice, and words for any
                  purpose connected with its mission to promote the growth and
                  development of The Range Network, including marketing,
                  training, informational, promotional and archival uses.
                </p>
                <p className="mb-5 mt-4">
                  I HAVE READ, UNDERSTAND, AND AGREE TO ALL OF THE INFORMATION
                  UNDER THE TERMS, CONDITIONS, AND POLICIES OF THIS MEMBERSHIP
                  AGREEMENT WHICH CAN ALSO BE FOUND ONLINE. IN ADDITION, I
                  UNDERSTAND AND AGREE THAT THE ASSUMPTION OF RISK, WAIVER, AND
                  RELEASE OF ALL CLAIMS PROVISIONS SET FORTH IN THE TERMS AND
                  CONDITIONS OF THIS AGREEMENT ARE IN EFFECT THROUGHOUT MY
                  MEMBERSHIP WITH THE RANGE NETWORK. I ALSO UNDERSTAND AND AGREE
                  THAT IF MY Membership Subscription IS INTERRUPTED FOR ANY
                  REASON, THIS AGREEMENT WILL REMAIN IN EFFECT DURING THE PERIOD
                  OF INTERRUPTION AS WELL AS AFTER THE Membership Subscription
                  IS REINSTATED. THIS AGREEMENT IS THE ENTIRE AGREEMENT, AND NO
                  VERBAL OR OTHER AGREEMENTS HAVE BEEN MADE TO ALTER THE TERMS
                  OF THIS SUBSCRIBER/USER AGREEMENT.
                </p>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default TermsConditions;
