import React from 'react';
import { Link } from 'react-router-dom';

const AccountFooter = () => {
  return (
    <>
      <footer>
        <div className="footer__content-bottom">
          <div className="container">
            <div className="row">
              <div className="right-to-bear-footer">
                <div className="copy-right order-3 order-lg-1">
                  <span>
                    <Link to="#">
                      © Copyright 2024 | Calliber Card All Rights Reserved
                    </Link>
                  </span>
                </div>
                <div className="bottom-footer-term-condition order-2 order-lg-2 d-none">
                  <a href="/terms-conditions">Terms & Conditions</a>
                </div>
                <div className="footer__list-social order-1 order-lg-3">
                  <ul>
                    <li>
                      <Link
                        to="https://www.facebook.com/profile.php?id=100083097770977"
                        target="_blank"
                      >
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://twitter.com/theRangeNetwork"
                        target="_blank"
                      >
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.instagram.com/shotshow?igsh=MWZvdjdmYXNkMTNiNg=="
                        target="_blank"
                      >
                        <i className="fa fa-instagram" aria-hidden="true"></i>
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="https://www.linkedin.com/company/gunrangeusa/about/">
                        <i className="fa fa-linkedin" aria-hidden="true"></i>
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default AccountFooter;
