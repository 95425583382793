import React, { useRef, useState } from 'react';
import MainLayout from '../Layout/MainLayout';
import { forgotPassword } from '../utils/endpoint';
import { postApi } from '../utils/api';
import { Link } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const formRef = useRef(null);

  const handleForm = (e) => {
    e.preventDefault();
    setError(false);
    setIsLoading(true);
    const formdata = new FormData();
    formdata.append('email', email);
    postApi(forgotPassword, formdata)
      .then((res) => {
        if (res?.data?.success) {
          setEmail('');
          setIsSent(true);
        } else {
          setError(true);
          setIsSent(false);
          setEmail('');
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setError(true);
        setIsLoading(false);
        setIsSent(false);
        setEmail('');
        console.log(e);
      });
  };

  const handleManualSubmit = (e) => {
    e.preventDefault();
    setError(false);
    const form = formRef.current;
    if (form.checkValidity()) {
      handleForm(e);
    } else {
      const errorClass = document.getElementsByClassName('reset-password')[0];
      errorClass.classList.add('border-alert');
      setError(true);
      form.reportValidity();
    }
  };

  const handleInput = (e) => {
    setEmail(e.target.value);
    handleError();
  };

  const handleError = () => {
    const errorClass = document.getElementsByClassName('reset-password')[0];
    errorClass.classList.remove('border-alert');
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleManualSubmit(event);
    }
  };

  return (
    <>
      <MainLayout>
        <div className="log-in-from login-height">
          <div className="container">
            <div className="row">
              <div className="log-in-form">
                {isSent && (
                  <>
                    <center>
                      <img
                        src="../../images/check_green.svg"
                        alt=""
                        className="img-fluid m-2"
                        width={50}
                        height={50}
                      />
                    </center>
                    <div className="reset-alert border-alert mt-2 text-center">
                      <h5 tabIndex={'-1'} autoFocus="" className="mb-0">
                        We&apos;ve sent you an email with a link to update your
                        password.
                      </h5>
                    </div>
                    <div className="text-center">
                      <Link
                        to="/login"
                        className="button-golden px-5 login-button"
                      >
                        Log In
                      </Link>
                    </div>
                    <Link to="/" className="forgot-text mt-3">
                      Go To Homepage
                    </Link>
                  </>
                )}
                {!isSent && (
                  <>
                    <h3
                      className="log-account-text reset-password"
                      tabIndex={'-1'}
                    >
                      Reset your password
                    </h3>
                    <form ref={formRef}>
                      <p className="reset-password-change">
                        We will send you an email to reset your password
                      </p>
                      <div className="floating-group">
                        <input
                          type="mail"
                          className={
                            'form-control input-outline mb-1 ' +
                            (error && 'error-input-border-red')
                          }
                          value={email}
                          onChange={handleInput}
                          required
                          onKeyPress={handleKeyPress}
                        />
                        <label>Email Address</label>
                        {error && (
                          <>
                            <small>
                              <img
                                src="../../images/red_error.svg"
                                alt=""
                                className="img-fluid m-2"
                              />
                              No account found with that email.
                            </small>
                          </>
                        )}
                      </div>

                      <div className="text-center">
                        <Link
                          className={
                            'button-golden px-5 login-button mt-2' +
                            (isLoading ? ' disabled-btn' : '')
                          }
                          onClick={handleManualSubmit}
                        >
                          {isLoading ? (
                            <ThreeDots
                              height="28"
                              width="69"
                              radius="1"
                              color="#fff"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          ) : (
                            'Submit'
                          )}
                        </Link>
                      </div>
                      <Link to="/login" className="forgot-text mt-3">
                        Cancel
                      </Link>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default PasswordReset;
