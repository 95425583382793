import React from 'react';
import AccountMainLayout from '../Layout/AccountMainLayout';
import CustomerService from '../components/CustomerService';
import Accordion from '../components/Accordion';

const GetHelpNow = () => {
  return (
    <>
      <AccountMainLayout>
        <div className="box-card-price">
          <div className="container">
            <div className="row">
              <div className="col-md-6 mb-3">
                <CustomerService
                  Title="Give Us a Call:"
                  Customer="Customer Service"
                  Number="000-000-0000"
                  EmergencyTitle="Attorney Hotline Emergency Number"
                  EmergencyNumber="000-000-0000"
                />
              </div>
              <div className="col-md-6 mb-3">
                <div className="first-card-price">
                  <h3 className="call">Contact Emails:</h3>
                  <div className="service">
                    <h3>General</h3>
                    <p>
                      <strong>
                        <a href="mailto:Info@protectwithbear.com">
                          Info@protectwithbear.com
                        </a>
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Accordion heading="LOOKING FOR INFORMATION?" />
      </AccountMainLayout>
    </>
  );
};

export default GetHelpNow;
