import React, { useEffect, useRef, useState } from 'react';
import Bill from '../../components/Bill';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {
  // generatePrice,
  validationInformationSchema,
} from '../../utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import {
  signupApi,
  updateProfileApi,
} from '../../redux/features/signup/signupSlice';
import { ThreeDots } from 'react-loader-spinner';
import ErrorBanner from '../ErrorBanner';
import { state_city } from '../../utils/state_cities';
import { Link } from 'react-router-dom';
import { memo } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { getLocalData } from '../../utils/Storage';

const Information = ({ handleInformation }) => {
  const { personalDetail } = useSelector((state) => state.signup);
  const [userId] = useState(personalDetail?.id ? personalDetail?.id : '');
  const [errors, setErrors] = useState('');
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorDetail, setShowErrorDetail] = useState({
    isError: true,
    message: '',
  });
  const [isSameAddress, setIsSameAddress] = useState(
    personalDetail?.isSameAddress === false ? false : true
  );
  const [passwordToggle, setpasswordToggle] = useState(true);
  const [confirmPasswordToggle, setConfirmPasswordToggle] = useState(true);
  const formRef = useRef(null);
  let packagePlan = localStorage.getItem('selectedPlan');
  let packagePlanType = localStorage.getItem('selectedPlanType');

  let selectedPlan = packagePlan ? JSON.parse(packagePlan) : false;
  //fetch email from local storage
  let defaultEmail = localStorage.getItem('joinEmail');

  const [isPlan, setIsPlan] = useState(
    packagePlanType === 'Yearly' ? true : false
  );

  let statelist = Object.keys(state_city);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    userType: personalDetail?.userType
      ? personalDetail?.userType
      : 'individual',
    fullName: personalDetail?.fullName
      ? personalDetail?.fullName
      : personalDetail?.name
        ? personalDetail?.name
        : '',
    email: defaultEmail ? defaultEmail : '',
    phoneNumber: personalDetail?.phoneNumber
      ? personalDetail?.phoneNumber
      : personalDetail?.telephone
        ? personalDetail?.telephone
        : '',
    company: personalDetail?.company ? personalDetail?.company : '',
    DOB: personalDetail?.DOB
      ? personalDetail?.DOB
      : personalDetail?.dob
        ? personalDetail?.dob
        : '',
    password: personalDetail?.password ? personalDetail?.password : '',
    confirmPassword: personalDetail?.confirmPassword
      ? personalDetail?.confirmPassword
      : '',
    address: personalDetail?.address ? personalDetail?.address : '',
    address2: personalDetail?.address2 ? personalDetail?.address2 : '',
    state: personalDetail?.state ? personalDetail?.state : '',
    city: personalDetail?.city ? personalDetail?.city : '',
    zipCode: personalDetail?.zipCode
      ? personalDetail?.zipCode
      : personalDetail?.zipcode
        ? personalDetail?.zipcode
        : '',
    billingZipCode: personalDetail?.billingZipCode
      ? personalDetail?.billingZipCode
      : '',
    billingCity: personalDetail?.billingCity ? personalDetail?.billingCity : '',
    billingOptionalAddress: personalDetail?.billingOptionalAddress
      ? personalDetail?.billingOptionalAddress
      : '',
    billingAddress: personalDetail?.billingAddress
      ? personalDetail?.billingAddress
      : '',
    billingState: personalDetail?.billingState
      ? personalDetail?.billingState
      : '',
  });

  useEffect(() => {
    if (personalDetail?.addresses?.length > 0) {
      let billingAddressSaved = personalDetail?.addresses.filter(
        (item) => item.address_type === 'billing'
      )[0];
      // let homeAddressSaved = personalDetail?.addresses.filter(
      //   (item) => item.address_type === 'home'
      // )[0];
      if (personalDetail?.addresses.length > 1) {
        setIsSameAddress(false);
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        billingAddress: billingAddressSaved?.street_address1,
        billingOptionalAddress: billingAddressSaved?.street_address2,
        billingState: billingAddressSaved?.state,
        billingZipCode: billingAddressSaved?.postal_code,
        billingCity: billingAddressSaved?.city,
      }));
    }
  }, [personalDetail]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    // Validation
    try {
      const form = formRef.current;
      validationInformationSchema.validateSyncAt(name, { [name]: value });
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
      Array.from(form.elements).forEach((element) => {
        if (element.checkValidity()) {
          element.classList.remove('error-input-border-red');
        }
      });
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const handlePasswordChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    try {
      validationInformationSchema.validateSyncAt(name, { [name]: value });
      if (name === 'password' && value !== formData.confirmPassword) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: 'Password and Confirm Password should match',
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: '',
        }));
      }
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    } catch (error) {
      if (name === 'confirmPassword' && value === formData.password) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: '',
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: error.message,
        }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationInformationSchema.validate(formData, {
        abortEarly: false,
      });
      setErrors('');
      const {
        userType,
        company,
        address2,
        city,
        state,
        billingAddress,
        ...personalDetail
      } = formData;
      const obj = {
        userType: userType === 'organization' ? 1 : 0,
        company: userType === 'organization' ? company : '',
        address2,
        city,
        state,
        billingAddress,
        ...personalDetail,
        isSameAddress: isSameAddress,
      };

      // if (!state_city[state].includes(city)) {
      //   setShowError(true);
      //   setShowErrorDetail({
      //     isError: false,
      //     message: 'Not a valid United States city.',
      //   });
      //   setTimeout(() => {
      //     setShowError(false);
      //     setShowErrorDetail({
      //       isError: true,
      //       message: '',
      //     });
      //   }, 3000);
      //   setIsLoading(false);
      // } else
      if (!isSameAddress && billingAddress === '') {
        setShowError(true);
        setShowErrorDetail({
          isError: false,
          message: 'Please provide the billing options first!',
        });
        setTimeout(() => {
          setShowError(false);
          setShowErrorDetail({
            isError: true,
            message: '',
          });
        }, 3000);
        setIsLoading(false);

        return false;
      } else {
        if (!userId) {
          await dispatch(signupApi(obj));
        }
        const access_token = Cookies.get('userToken');
        if (userId && access_token) {
          await dispatch(updateProfileApi(obj, access_token));
        }
        localStorage.setItem('userData', JSON.stringify(obj));
        setIsLoading(false);
        handleInformation('billing');
        window.scrollTo(0, 0);
      }
    } catch (error) {
      console.log(error);

      // setShowError(true);
      // setShowErrorDetail({
      //   isError: false,
      //   message: 'Error occurred. Please try again.', // Display a generic error message
      // });
      // setTimeout(() => {
      //   setShowError(false);
      //   setShowErrorDetail({
      //     isError: true,
      //     message: '',
      //   });
      // }, 3000);
      // setIsLoading(false);
      const validationErrors = {};
      error?.inner?.forEach((err) => {
        if (err.path) {
          validationErrors[err.path] = err.message;
        } else {
          validationErrors['DOB'] = err.message;
        }
      });
      if (Object.entries(validationErrors).length === 0) {
        setShowError(true);
        setShowErrorDetail({
          isError: true,
          message: !error
            ? 'Please fill all the required fields.'
            : error?.response?.data?.message,
        });
        setTimeout(() => {
          setShowError(false);
          setShowErrorDetail({
            isError: true,
            message: '',
          });
        }, 3000);
      }
      setErrors(validationErrors);
      setIsLoading(false);
    }
  };

  const handleManualSubmit = async (e) => {
    e.preventDefault();
    const form = formRef.current;
    if (form.checkValidity()) {
      setIsLoading(true);
      await handleSubmit(e);
    } else {
      setShowError(true);
      setShowErrorDetail({
        isError: true,
        message: 'Please fill all the required fields.',
      });
      setTimeout(() => {
        setShowError(false);
        setShowErrorDetail({
          isError: true,
          message: '',
        });
      }, 3000);
      // Loop through each form element and add error class if invalid
      Array.from(form.elements).forEach((element) => {
        if (!element.checkValidity()) {
          element.classList.add('error-input-border-red');
        } else {
          element.classList.remove('error-input-border-red');
        }
      });
      form.reportValidity();
    }
  };

  const styleClass = {
    color: 'red',
  };

  const handleClose = () => {
    setShowError(false);
    setShowErrorDetail({
      isError: true,
      message: '',
    });
  };

  // const SelectedPlanPrice = selectedPlan?.packages[0]?.package_prices.filter(
  //   (price) => price.price_frequency === packagePlanType
  // );
  // console.log(SelectedPlanPrice);

  const handlePlanSelection = (data) => {
    setIsPlan(data);
    localStorage.setItem('selectedPlanType', !data ? 'Monthly' : 'Yearly');
  };

  // const tooltipTriggerList = document.querySelectorAll(
  //   '[data-bs-toggle="tooltip"]'
  // );
  // const tooltipList = [...tooltipTriggerList].map(
  //   (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl)
  // );
  const isRedemption = getLocalData('IsRedemption');

  const handleSameAsAbove = (e) => {
    const checked = e.target.checked;
    setIsSameAddress(checked);
    if (!checked) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        billingAddress: '',
        billingOptionalAddress: '',
        billingState: '',
        billingZipCode: '',
        billingCity: '',
      }));
    }
  };
  return (
    <>
      <div className="join-form-user p-0 p-md-2">
        <div className="container-fluid">
          <div className="row">
            {showError && (
              <ErrorBanner error={showErrorDetail} handleClose={handleClose} />
            )}
            <div className="col-lg-8">
              <form className="details-user-form" ref={formRef} noValidate>
                <div className="row g-3">
                  <div className="col-12 col-md-6 d-none">
                    <div className="radioBtn">
                      <input
                        className="radio-input"
                        type="radio"
                        name="userType"
                        id="myradio1"
                        value="individual"
                        checked={formData.userType === 'individual'}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="myradio1" className="radio__label">
                        <p className="text-left">For individual</p>
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 d-none">
                    <div className="radioBtn d-none">
                      <input
                        className="radio-input"
                        type="radio"
                        name="userType"
                        id="myradio2"
                        value="organization"
                        checked={formData.userType === 'organization'}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="myradio2" className="radio__label">
                        <p>For organization</p>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group-2">
                      <div className="row">
                        <h4 className="details">ACCOUNT DETAILS</h4>
                        <div className="col-md-12">
                          <div className="floating-group select-floating">
                            <input
                              type="email"
                              className="form-control input-outline"
                              placeholder="Email address*"
                              value={formData.email}
                              name="email"
                              onChange={handleInputChange}
                              required
                              disabled
                              id="form_email"
                            />
                            <label
                              htmlFor="form_email"
                              className="form-label label-text"
                            >
                              Email
                            </label>
                            {errors.email && (
                              <div style={styleClass}>{errors.email}</div>
                            )}
                          </div>
                        </div>
                        <ReactTooltip
                          id="my-tooltip-2"
                          place="top"
                          variant="info"
                          content={errors.password}
                        />
                        <div className="col-md-12">
                          <div className="floating-group">
                            <div
                              className="input-set"
                              data-tooltip-id="my-tooltip-2"
                            >
                              <input
                                type={
                                  passwordToggle === true ? 'password' : 'text'
                                }
                                className={
                                  'form-control input-outline' +
                                  (errors.password &&
                                    ' error-input-border-red ')
                                }
                                aria-describedby="basic-addon1"
                                value={formData.password}
                                name="password"
                                minLength={8}
                                maxLength={40}
                                onChange={handlePasswordChange}
                                required
                                // id="form_password"
                              />

                              <label
                                htmlFor="form_password"
                                className="label-text"
                              >
                                Password*
                              </label>
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                {!passwordToggle ? (
                                  <img
                                    src="../../images/eye-password-hide.svg"
                                    alt=""
                                    onClick={() =>
                                      setpasswordToggle(!passwordToggle)
                                    }
                                  />
                                ) : (
                                  <img
                                    src="../../images/eye-password-show.svg"
                                    alt=""
                                    onClick={() =>
                                      setpasswordToggle(!passwordToggle)
                                    }
                                  />
                                )}
                              </span>
                              {/* {errors.password && (
                                <div style={styleClass}>{errors.password}</div>
                              )} */}
                            </div>
                          </div>
                        </div>

                        <ReactTooltip
                          id="my-tooltip-3"
                          place="top"
                          variant="info"
                          content={errors.confirmPassword}
                        />
                        <div className="col-md-12">
                          <div className="floating-group">
                            <div
                              className="input-set"
                              data-tooltip-id="my-tooltip-3"
                            >
                              <input
                                type={
                                  confirmPasswordToggle === true
                                    ? 'password'
                                    : 'text'
                                }
                                className={
                                  'form-control input-outline' +
                                  (errors.confirmPassword &&
                                    ' error-input-border-red ')
                                }
                                // placeholder="Confirm Password*"
                                aria-describedby="basic-addon1"
                                name="confirmPassword"
                                value={formData.confirmPassword}
                                onChange={handlePasswordChange}
                                maxLength="40"
                                required
                              />

                              <label htmlFor="cpassword" className="label-text">
                                Confirm Password*
                              </label>
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                {!confirmPasswordToggle ? (
                                  <img
                                    src="../../images/eye-password-hide.svg"
                                    alt=""
                                    onClick={() =>
                                      setConfirmPasswordToggle(
                                        !confirmPasswordToggle
                                      )
                                    }
                                  />
                                ) : (
                                  <img
                                    src="../../images/eye-password-show.svg"
                                    alt=""
                                    onClick={() =>
                                      setConfirmPasswordToggle(
                                        !confirmPasswordToggle
                                      )
                                    }
                                  />
                                )}
                              </span>
                              {/* {errors.confirmPassword && (
                                <div style={styleClass}>
                                  {errors.confirmPassword}
                                </div>
                              )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group-2">
                      <div className="row">
                        <h4 className="details">PERSONAL DETAILS</h4>
                        <ReactTooltip
                          id="fullName-tooltip"
                          place="top"
                          variant="info"
                          content={errors.fullName}
                        />
                        <ReactTooltip
                          id="number-tooltip"
                          place="top"
                          variant="info"
                          content={errors.phoneNumber}
                        />
                        <ReactTooltip
                          id="db-tooltip"
                          place="top"
                          variant="info"
                          content={errors.DOB}
                        />
                        <div className="col-md-12">
                          <div className="floating-group">
                            <input
                              data-tooltip-id="fullName-tooltip"
                              id="form_name1"
                              type="text"
                              className={
                                'form-control' +
                                (errors.fullName && ' error-input-border-red ')
                              }
                              value={formData.fullName}
                              name="fullName"
                              onChange={handleInputChange}
                              required
                            />

                            <label htmlFor="form_name1">Full Name*</label>
                            {/* {errors.fullName && (
                              <div style={styleClass}>{errors.fullName}</div>
                            )} */}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div
                            className="floating-group"
                            data-tooltip-id="number-tooltip"
                          >
                            <input
                              id="form_number"
                              type="tel"
                              maxLength="10"
                              className={
                                'form-control input-outline' +
                                (errors.phoneNumber &&
                                  ' error-input-border-red ')
                              }
                              // placeholder="Phone Number*"
                              value={formData.phoneNumber}
                              name="phoneNumber"
                              onChange={handleInputChange}
                              onKeyPress={(e) => {
                                const regex = /^[0-9\b]+$/;
                                if (!regex.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              required
                            />
                            <label
                              htmlFor="form_number"
                              className="form-label label-text"
                            >
                              Phone Number*
                            </label>
                            {/* {errors.phoneNumber && (
                              <div style={styleClass}>{errors.phoneNumber}</div>
                            )} */}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div
                            className="floating-group select-floating"
                            data-tooltip-id="db-tooltip"
                          >
                            <input
                              id="form_dfb"
                              type="date"
                              className={
                                'form-control input-outline' +
                                (errors.DOB && ' error-input-border-red ')
                              }
                              placeholder="Date of Birth*"
                              value={formData.DOB}
                              name="DOB"
                              onChange={handleInputChange}
                              pattern="(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)"
                              max="2100-12-31"
                              required
                            />
                            <label
                              htmlFor="form_dfb"
                              className="form-label label-text"
                            >
                              Date of Birth*
                            </label>
                            {/* {errors.DOB && (
                              <div style={styleClass}>{errors.DOB}</div>
                            )} */}
                          </div>
                        </div>
                        <div className="col-md-12">
                          {formData.userType === 'organization' && (
                            <>
                              <label
                                htmlFor="company"
                                className="form-label label-text"
                              >
                                Company*
                              </label>
                              <input
                                type="text"
                                className="form-control input-outline"
                                // placeholder="Company*"
                                value={formData.company}
                                name="company"
                                onChange={handleInputChange}
                              />
                            </>
                          )}
                        </div>
                        {/* {!personalDetail?.id && ( */}
                        <>
                          <div className="col-md-6 d-none">
                            <label htmlFor="password" className="label-text">
                              Password*
                            </label>
                            <div className="input-group ">
                              <input
                                type={
                                  passwordToggle === true ? 'password' : 'text'
                                }
                                className="form-control input-outline"
                                // placeholder="Password*"
                                aria-describedby="basic-addon1"
                                value={formData.password}
                                name="password"
                                minLength={8}
                                maxLength={40}
                                onChange={handlePasswordChange}
                                required
                              />
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                {!passwordToggle ? (
                                  <img
                                    src="../../images/hide_password.png"
                                    alt=""
                                    onClick={() =>
                                      setpasswordToggle(!passwordToggle)
                                    }
                                  />
                                ) : (
                                  <img
                                    src="../../images/eye-open.png"
                                    alt=""
                                    onClick={() =>
                                      setpasswordToggle(!passwordToggle)
                                    }
                                  />
                                )}
                              </span>
                            </div>
                            {errors.password && (
                              <div style={styleClass}>{errors.password}</div>
                            )}
                          </div>
                          <div className="col-md-6 d-none">
                            <label htmlFor="cpassword" className="label-text">
                              Confirm Password*
                            </label>
                            <div className="input-group ">
                              <input
                                type={
                                  confirmPasswordToggle === true
                                    ? 'password'
                                    : 'text'
                                }
                                className="form-control input-outline"
                                // placeholder="Confirm Password*"
                                aria-describedby="basic-addon1"
                                name="confirmPassword"
                                value={formData.confirmPassword}
                                onChange={handlePasswordChange}
                                maxLength="40"
                                required
                              />
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                {!confirmPasswordToggle ? (
                                  <img
                                    src="../../images/hide_password.png"
                                    alt=""
                                    onClick={() =>
                                      setConfirmPasswordToggle(
                                        !confirmPasswordToggle
                                      )
                                    }
                                  />
                                ) : (
                                  <img
                                    src="../../images/eye-open.png"
                                    alt=""
                                    onClick={() =>
                                      setConfirmPasswordToggle(
                                        !confirmPasswordToggle
                                      )
                                    }
                                  />
                                )}
                              </span>
                            </div>
                            {errors.confirmPassword && (
                              <div style={styleClass}>
                                {errors.confirmPassword}
                              </div>
                            )}
                          </div>
                        </>
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                  <h4 className="address-details-1">
                    HOME ADDRESS* <span>(NO PO BOXES ALLOWED)</span>
                  </h4>
                  <div className="outer-form">
                    <div className="row">
                      <ReactTooltip
                        id="address"
                        place="top"
                        variant="info"
                        content={errors.address}
                      />
                      <div className="col-md-12">
                        <div
                          className="floating-group"
                          data-tooltip-id="address"
                        >
                          <input
                            type="address"
                            name="address"
                            className={
                              'form-control input-outline' +
                              (errors.address && ' error-input-border-red ')
                            }
                            // placeholder="Street Address 1*"
                            value={formData.address}
                            onChange={handleInputChange}
                            required
                          />
                          <label
                            htmlFor="address"
                            className="form-label label-text"
                          >
                            Street Address 1*
                          </label>
                          {/* {errors.address && (
                            <div style={styleClass}>{errors.address}</div>
                          )} */}
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-floating mb-3 mt-3">
                          <input
                            type="address"
                            className="form-control"
                            id="floatingInput"
                            name="address2"
                            placeholder="Street Address 2"
                            onChange={handleInputChange}
                            value={formData.address2}
                          />
                          <label htmlFor="floatingInput">
                            Street Address 2
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="floating-group select-floating">
                          <select
                            id="state_"
                            className="form-select input-outline"
                            name="state"
                            value={formData.state}
                            onChange={handleInputChange}
                            required
                          >
                            <option value=""> Select State</option>
                            {statelist.map((item, index) => (
                              <option value={item} key={index}>
                                {item}
                              </option>
                            ))}
                          </select>
                          {/* <p className="form-membership">
                          We do not offer memberships for those in the following
                          states: New York, New Jersey, Washington
                        </p> */}
                          <label
                            htmlFor="state_"
                            className="form-label label-text"
                          >
                            State*
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="floating-group">
                          <input
                            type="text"
                            className="form-control input-outline"
                            id="inputCity"
                            // placeholder="City"
                            name="city"
                            onChange={handleInputChange}
                            value={formData.city}
                            required
                          />
                          <label
                            htmlFor="inputCity"
                            className="form-label label-text"
                          >
                            City*
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="floating-group">
                          <input
                            type="text"
                            className="form-control input-outline"
                            id="inputZip"
                            // placeholder="Postal/ZIP code"
                            name="zipCode"
                            onChange={handleInputChange}
                            value={formData.zipCode}
                            maxLength="5"
                            onKeyPress={(e) => {
                              const regex = /^[0-9\b]+$/;
                              if (!regex.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            required
                          />
                          <label
                            htmlFor="inputZip"
                            className="form-label label-text"
                          >
                            Postal/ZIP code*
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="address-details-1">BILLING ADDRESS</p>
                  <div className="col-12">
                    <div className="put-filed-same">
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        Same as above?
                      </label>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={isSameAddress}
                          onChange={handleSameAsAbove}
                          id="flexCheckDefault"
                        />
                      </div>
                    </div>
                  </div>
                  {!isSameAddress && (
                    <div className="outer-form">
                      <div className="row">
                        <ReactTooltip
                          id="address-first-tooltip"
                          place="top"
                          variant="info"
                          content={errors.billingAddress}
                        />
                        <div className="col-md-12">
                          <div
                            className="floating-group"
                            data-tooltip-id="address-first-tooltip"
                          >
                            <input
                              type="address"
                              name="billingAddress"
                              className={
                                'form-control input-outline' +
                                (errors.billingAddress &&
                                  ' error-input-border-red ')
                              }
                              value={formData.billingAddress}
                              onChange={handleInputChange}
                              required
                              id="address"
                            />
                            <label
                              htmlFor="address"
                              className="form-label label-text"
                            >
                              Street Address 1*
                            </label>
                            {/* {errors.billingAddress && (
                              <div style={styleClass}>
                                {errors.billingAddress}
                              </div>
                            )} */}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-floating mt-3">
                            <input
                              type="address"
                              name="billingOptionalAddress"
                              className="form-control input-outline"
                              onChange={handleInputChange}
                              value={formData.billingOptionalAddress}
                              id="address-2"
                              placeholder="Street Address 2"
                            />
                            <label
                              htmlFor="address-2"
                              className="form-label label-text"
                            >
                              Street Address 2
                            </label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="floating-group select-floating">
                            <select
                              id="inputState1"
                              className="form-select input-outline"
                              name="billingState"
                              onChange={handleInputChange}
                              value={formData.billingState}
                              required
                            >
                              <option value="">Select State</option>
                              {statelist.map((item, index) => (
                                <option value={item} key={index}>
                                  {item}
                                </option>
                              ))}
                            </select>
                            {/* <p className="form-membership">
                            We do not offer memberships for those in the
                            following states: New York, New Jersey, Washington
                          </p> */}
                            <label
                              htmlFor="inputState1"
                              className="form-label label-text"
                            >
                              State*
                            </label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="floating-group">
                            <input
                              type="text"
                              className="form-control input-outline"
                              id="inputCity1"
                              name="billingCity"
                              onChange={handleInputChange}
                              value={formData.billingCity}
                              required
                            />
                            <label
                              htmlFor="inputCity1"
                              className="form-label label-text"
                            >
                              City*
                            </label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="floating-group">
                            <input
                              type="text"
                              className="form-control input-outline"
                              id="inputZip1"
                              name="billingZipCode"
                              onChange={handleInputChange}
                              value={formData.billingZipCode}
                              maxLength="5"
                              onKeyPress={(e) => {
                                const regex = /^[0-9\b]+$/;
                                if (!regex.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              required
                            />
                            <label
                              htmlFor="inputZip1"
                              className="form-label label-text"
                            >
                              Postal/ZIP code*
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="button-back px-1">
                    <Link to="/joinform" className="back-text">
                      Back
                    </Link>
                    <button
                      className={`theme-button mobile-button-view ${
                        isLoading ? 'disable' : ''
                      }`}
                      type="button"
                      onClick={handleManualSubmit}
                    >
                      {isLoading ? (
                        <div className="d-flex justify-content-center ">
                          <ThreeDots
                            height="27"
                            width="50"
                            radius="2"
                            color="#fff"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        'Next'
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-4">
              {!isRedemption && (
                <Bill
                  setPlanInfo={{ isPlan, handlePlanSelection }}
                  Heading="PLAN SUMMARY"
                  Yearly={selectedPlan ? selectedPlan?.name : false}
                  Dollar={
                    selectedPlan
                      ? '$' +
                        selectedPlan?.packages[0]?.package_prices.filter(
                          (price) => price.price_frequency === packagePlanType
                        )[0]?.price
                      : false
                  }
                  PlanUser="Plan"
                  PlanType={
                    selectedPlan
                      ? packagePlanType === 'Yearly'
                        ? '/ Year'
                        : packagePlanType === 'Monthly'
                          ? '/ Month'
                          : '/ One-time'
                      : isPlan
                        ? '/ Year'
                        : '/ Month'
                  }
                  Price={
                    selectedPlan
                      ? '$' +
                        selectedPlan?.packages[0]?.package_prices.filter(
                          (price) => price.price_frequency === packagePlanType
                        )[0]?.price
                      : false
                  }
                  Tax="Tax ()"
                  TaxPrice="$0.00"
                  Total="Total"
                  TotalPrice={
                    selectedPlan
                      ? '$' +
                        parseFloat(
                          selectedPlan?.packages[0]?.package_prices.filter(
                            (price) => price.price_frequency === packagePlanType
                          )[0]?.price
                        ).toFixed(2)
                      : false
                  }
                  Time="One Time Registration Fee"
                  TimePrice="$0.00"
                  isTab={true}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
Information.propTypes = {
  handleInformation: PropTypes.func,
};

export default memo(Information);
