import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAuthToken } from '../utils/Storage';
const Footer = () => {
  const [, setAuth] = useState(true);
  useEffect(() => {
    let userToken = getAuthToken('userToken');
    userToken ? setAuth(false) : setAuth(true);
  }, []);

  return (
    <>
      <footer>
        {/* {auth && ( */}
        <div className="footer-menu">
          <div className="container">
            <div className="row ">
              <div className="col-lg-6">
                <div className="logo-footer">
                  <Link to="/">
                    <img
                      src="../../images/logo_vertical.svg"
                      alt="logo_vertical"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="footer-linking">
                  <ul>
                    <li>
                      <Link to="https://therangenetwork.com/" target="_blank">
                        About the Range Network
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://therangenetwork.com/contact/"
                        target="_blank"
                      >
                        Support
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://enroll.mycalibercard.com/terms-conditions"
                        target="_blank"
                      >
                        Card Member Agreement
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://therangenetwork.com/join-now/"
                        target="_blank"
                      >
                        Become a Partner Range
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy">Privacy</Link>
                    </li>
                    {/* <li>
                      <Link to="/terms-conditions">Member Agreement</Link>
                    </li> */}
                  </ul>
                  <ul className="icon-link">
                    <li>
                      <Link
                        to="https://www.facebook.com/profile.php?id=100083097770977"
                        target="_blank"
                      >
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://twitter.com/theRangeNetwork"
                        target="_blank"
                      >
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.instagram.com/shotshow?igsh=MWZvdjdmYXNkMTNiNg=="
                        target="_blank"
                      >
                        <i className="fa fa-instagram" aria-hidden="true"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <div className="footer-linking d-none">
                  <p className="title-footer-link">RESOURCES</p>
                  <ul>
                    <li>
                      <Link to="/Company-vision">Company Vision</Link>
                    </li>
                    <li>
                      <Link to="/carryresponsibly">Carry Responsibly</Link>
                    </li>
                    <li>
                      <Link to="/support">Help & Support</Link>
                    </li>
                    <li>
                      <Link to="/affiliate-net-work">Affiliate Network</Link>
                    </li>
                    <li>
                      <Link to="/terms-conditions">Terms & Condition</Link>
                    </li>
                    <li>
                      <Link to="/ccw-insurance">What Is Ccw Insurance?</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* )} */}

        <div className="footer__content-bottom">
          <div className="container">
            <div className="row">
              <div className="right-to-bear-footer">
                <div className="copy-right order-3 order-lg-1">
                  <span>
                    <Link to="#">
                      © Copyright 2024 | Calliber Card All Rights Reserved
                    </Link>
                  </span>
                </div>
                <div className="bottom-footer-term-condition order-2 order-lg-2 d-none">
                  <Link to="/terms-conditions">Terms & Conditions</Link>
                </div>
                <div className="footer__list-social order-1 order-lg-3 d-block d-lg-none">
                  <ul>
                    <li>
                      <Link
                        to="https://www.facebook.com/profile.php?id=100083097770977"
                        target="_blank"
                      >
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://twitter.com/theRangeNetwork"
                        target="_blank"
                      >
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.instagram.com/shotshow?igsh=MWZvdjdmYXNkMTNiNg=="
                        target="_blank"
                      >
                        <i className="fa fa-instagram" aria-hidden="true"></i>
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="https://www.linkedin.com/company/gunrangeusa/about/">
                        <i className="fa fa-linkedin" aria-hidden="true"></i>
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
