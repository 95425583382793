import React, { useState, useEffect } from 'react';
import MainLayout from '../Layout/MainLayout';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchPackage } from '../redux/features/packages/packageSlice';
import PackagePlan from '../components/PackagePlan';
import Cookies from 'js-cookie';

const JoinNow = () => {
  const [selectedPlan, setSelectedPlan] = useState({});
  const [selectedPlanID, setSelectedPlanID] = useState('');
  const packageList = useSelector((state) => state.package.packages);
  const { personalDetail } = useSelector((state) => state.signup);
  const [registrationFee, setRegistrationFee] = useState(0);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    if (packageList?.length === 0) {
      const fetchData = async () => {
        dispatch(fetchPackage());
      };
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (packageList) {
      packageList.map((item) => {
        {
          item.packages.map((package_data) => (
            <>
              {package_data.package_prices
                ?.filter(
                  (price_record) => price_record.price_frequency === 'One-Time'
                )
                .map((price) => setRegistrationFee(price.price))}
            </>
          ));
        }
      });
    }
  }, [packageList]);

  const handlePlan = (e, data) => {
    setSelectedPlanID(data.id);
    setSelectedPlan(data);
  };

  const handleProceed = () => {
    localStorage.setItem('selectedPlan', JSON.stringify(selectedPlan));
    if (
      personalDetail?.subscriptions?.length === 0 &&
      Cookies.get('isSubscribed') === undefined
    ) {
      localStorage.setItem('joinEmail', personalDetail?.email);
      navigate('/plan-checkout');
    } else {
      navigate('/joinform');
    }
  };

  return (
    <>
      <MainLayout>
        <div className="range-network-registration account-login">
          <div className="container">
            <div className="row">
              {/* <h3>THE RANGE NETWORK | REGISTRATION</h3> */}
              <h3>Enrollment</h3>
              <PackagePlan />
              <h4 className="d-none">Membership Plan</h4>
              <form onSubmit={handleProceed}>
                {packageList ? (
                  <>
                    {packageList.map((item, index) => (
                      <div className="plan-select d-none" key={index}>
                        {item.packages.map((package_data) => (
                          <React.Fragment key={index}>
                            {package_data.package_prices
                              ?.filter(
                                (price_record) =>
                                  price_record.price_frequency === 'Monthly'
                              )
                              .map((price, index) => (
                                <React.Fragment key={index}>
                                  <div
                                    className="form-check"
                                    key={package_data.id}
                                  >
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="Plan"
                                      id={item.id}
                                      checked={selectedPlanID === item.id}
                                      onChange={(e) => handlePlan(e, item)}
                                      required
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={item.id}
                                    >
                                      Core ({package_data.name}) Membership -
                                      {price.price_frequency === 'Monthly'
                                        ? ' Monthly '
                                        : ' Lifetime '}
                                      Retail
                                    </label>
                                  </div>

                                  <p key={index}>
                                    $ {price.price} {''} USD
                                    {price.price_frequency === 'Monthly'
                                      ? '/month'
                                      : '/year'}
                                    <br />
                                    <span>(reg. fee: $ {registrationFee})</span>
                                  </p>
                                </React.Fragment>
                              ))}
                          </React.Fragment>
                        ))}
                      </div>
                    ))}
                  </>
                ) : (
                  <p>Loading...</p>
                )}
                {/* <div className="plan-select">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="Plan"
                    id="Monthly"
                  />
                  <label className="form-check-label" htmlFor="Monthly">
                    Core (Silver) Membership - Monthly Retail
                  </label>
                </div>
                <p>
                  $4.99/month USD
                  <br />
                  <span>(reg. fee: $ 20.00)</span>
                </p>
              </div> */}
                <div className="discount-code d-none">
                  {/* <div>
                  <label htmlFor="Name">Discount Code:</label>{' '}
                  <input type="text" className="form-control input-outline" />
                  <p>
                    If you have been given a Discount Code please enter it
                    above. (EX: TRN1234)
                  </p>
                </div> */}
                  <div className="proceed d-none">
                    <button className="theme-button" type="submit">
                      Proceed
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default JoinNow;
