// import { configureStore } from "@reduxjs/toolkit";
// import packageReducer from "./features/packages/packageSlice";
// import signupReducer from "./features/signup/signupSlice";

// const store = configureStore({
//   reducer: {
//     package: packageReducer,
//     signup: signupReducer,
//   },
// });

// export default store;

import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import packageReducer from './features/packages/packageSlice';
import signupReducer from './features/signup/signupSlice';
import findRangeReducer from './features/FindRange/findRangeSlice';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedPackageReducer = persistReducer(persistConfig, packageReducer);
const persistedSignupReducer = persistReducer(persistConfig, signupReducer);
const persistedFindRangeReducer = persistReducer(
  persistConfig,
  findRangeReducer
);

export const store = configureStore({
  reducer: {
    package: persistedPackageReducer,
    signup: persistedSignupReducer,
    findRange: persistedFindRangeReducer,
  },
});

export const persistor = persistStore(store);
