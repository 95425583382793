import React, { useEffect, useState } from 'react';
// import AccountMainLayout from '../Layout/AccountMainLayout';
// import { Link, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AccountForm from '../components/AccountForm';
import Cookies from 'js-cookie';
import { addAddress, updateAddress } from '../utils/endpoint';
import { deleteApi, getApi } from '../utils/api';

export const AccountAddresses = () => {
  const [addressList, setAddressList] = useState([]);
  const [editData, setEditData] = useState({});
  const [deleteID, setDeleteID] = useState('');
  const access_token = Cookies.get('userToken');
  // const { pathname } = useLocation();

  const fetchAddressList = () => {
    getApi(addAddress, access_token)
      .then((res) => {
        if (res?.data?.success) {
          setAddressList(res?.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchAddressList();
  }, []);

  const handleDelete = (e) => {
    e.preventDefault();
    deleteApi(updateAddress + deleteID, access_token)
      .then((res) => {
        if (res?.data?.success) {
          const modalClose =
            document.getElementsByClassName('cancel-address')[0];
          modalClose.click();
          fetchAddressList();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="account-login pro-file-ad">
        <div className="container-fluid">
          <div className="return-details">
            <div className="row">
              <h3 className="primary-heading p-0">Profile Addresses</h3>
              {/* <Link to="/account" className="return-account">
                Return to Account details
              </Link> */}
              <span className="ps-0">
                <Link
                  to="#"
                  className="link-text"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop1"
                >
                  Add a New Address
                </Link>
              </span>
            </div>
          </div>
        </div>
        <div className="default-user">
          <div className="card-address">
            {addressList &&
              addressList?.map((item) => (
                <div className="card" key={item.id}>
                  <p className="text-profile text-capitalize">
                    {item.address_type}
                  </p>
                  <div className="default-body">
                    {item?.default === 1 && <h2>Default</h2>}
                    <p className="card-user-id-text">
                      {item.firstname} {item?.lastname}
                      <br />
                      {item?.company &&
                        item?.company !== '' &&
                        item?.company !== 'null' && (
                          <>
                            {item?.company}
                            <br />
                          </>
                        )}
                      {item?.street_address1}
                      <br />
                      {item?.street_address2 !== '' &&
                        item?.street_address2 !== null && (
                          <>
                            {item?.street_address2} <br />{' '}
                          </>
                        )}
                      {item?.city} {item?.state} {item.postal_code}
                      <br />
                      {item?.country}
                    </p>
                  </div>
                  <div
                    className="d-flex default-bottom gap-3 justify-content-center ml-auto mr-auto mt-auto"
                    style={{ marginTop: 'auto' }}
                  >
                    <Link
                      to=""
                      className="button-black size-button size-border"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                      onClick={() => setEditData(item)}
                    >
                      Edit
                    </Link>
                    <Link
                      to=""
                      className="button-black  size-button size-border"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      onClick={() => setDeleteID(item.id)}
                    >
                      Delete
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body d-flex">
              <AccountForm
                Heading="Edit address"
                ButtonFirst="Update address"
                fetchAddressList={fetchAddressList}
                personalDetail={editData}
              />
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="staticBackdrop1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body d-flex">
              <AccountForm
                Heading="Add address"
                ButtonFirst="Add address"
                fetchAddressList={fetchAddressList}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body delete-text">
              <p>Are you sure you wish to delete this address?</p>
            </div>
            <div className="modal-footer cancel-button">
              <button
                type="button"
                className="cancel ok"
                onClick={handleDelete}
              >
                Yes
              </button>
              <button
                type="button"
                className="cancel cancel-address"
                data-bs-dismiss="modal"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
