import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import { getApi } from '../utils/api';
import { rangeApi } from '../utils/endpoint';
import { generateSVGUrl } from '../utils/helpers';
import Cookies from 'js-cookie';

const FindRangeMap = () => {
  const [rangeList, setRangeList] = useState([]);
  // const [filterList, setFilterList] = useState([]);
  let access_token = Cookies.get('userToken');
  const [isLoading, setIsLoading] = useState(false);
  const [selectCity, setSelectCity] = useState('');
  const [markerData, setMarkerData] = useState([]);

  const fetchRangeList = () => {
    setIsLoading(true);
    getApi(rangeApi, access_token)
      .then((res) => {
        setRangeList(res?.data?.data);
        createMarkerList(res?.data?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchRangeList();
  }, []);

  // const { isLoaded } = useSelector((state) => state.findRange);
  // console.log(isLoaded, 'isLoaded');

  const createMarkerList = (data) => {
    let dataRes = data;
    let resArray = [];
    dataRes.map((item) => {
      let obj = {};
      obj.id = item.id;
      obj.address = item.address;
      obj.phone = item.phone;
      obj.name = item.name;
      obj.lat = Number(item.lat);
      obj.lng = Number(item.lng);
      obj.range_type_color = item?.range_type_details?.color
        ? generateSVGUrl(item?.range_type_details?.color)
        : generateSVGUrl('#337ab7');

      resArray.push(obj);
    });
    setMarkerData(resArray);
  };

  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();
  const markers = markerData ? markerData : [];

  const onMapLoad = (map) => {
    setMapRef(map);
    const bounds = new window.google.maps.LatLngBounds();
    markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    map.fitBounds(bounds);
  };

  const handleInputChange = (e) => {
    setSelectCity(e.target.value);
  };

  const handleSearch = () => {
    // setSelectCity(value);
    if (selectCity !== '') {
      const markerData = rangeList.filter((item) => item.city === selectCity);
      // console.log(markerData);
      // setFilterList(markerData);
      createMarkerList(markerData);
      setIsOpen(false);
    }
  };

  const handleMarkerClick = (id, lat, lng, address, phone, name) => {
    mapRef?.panTo({ lat, lng });
    setInfoWindowData({ id, address, phone, name });
    setIsOpen(true);
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ['places'],
  });

  return (
    <>
      <div className="find-range-map">
        <div className="find-range-list">
          <h4>FIND A RANGE</h4>
          <select
            className="form-select"
            value={selectCity}
            onChange={handleInputChange}
            disabled={isLoading}
          >
            <option value="" disabled>
              Select City
            </option>
            {rangeList?.map((item, index) => (
              <option value={item.city} key={index}>
                {item.city}
              </option>
            ))}
          </select>
          <button onClick={handleSearch} type="button">
            Search
          </button>
          <img src="../images/tilt_logo_bg-input.png" alt="" />
        </div>

        {!isLoaded ? (
          <h3>Loading...</h3>
        ) : (
          <GoogleMap
            mapContainerClassName="map-select"
            onLoad={onMapLoad}
            onClick={() => setIsOpen(false)}
            options={{
              gestureHandling: 'greedy',
            }}
            zoom={0}
            center={{
              lat: markers[0]?.lat ? Number(markers[0]?.lat) : 0,
              lng: markers[0]?.lng ? Number(markers[0]?.lng) : 0,
            }}
          >
            {markers?.map(
              ({ address, lat, lng, phone, name, range_type_color }, ind) => (
                <Marker
                  key={ind}
                  position={{ lat, lng }}
                  onClick={() => {
                    handleMarkerClick(ind, lat, lng, address, phone, name);
                  }}
                  icon={range_type_color}
                >
                  {isOpen && infoWindowData?.id === ind && (
                    <InfoWindow
                      onCloseClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      <h4>{infoWindowData.name}</h4>
                    </InfoWindow>
                  )}
                </Marker>
              )
            )}
          </GoogleMap>
        )}

        {/* <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d65276852.91553952!2d-96.49047445137457!3d3.0456128497300585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sin!4v1705386382255!5m2!1sen!2sin"
          allowfullscreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe> */}
      </div>
    </>
  );
};

export default FindRangeMap;
