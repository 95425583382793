import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoaded: false,
  loadError: null,
};

const findRangeSlice = createSlice({
  name: 'findRangeMap',
  initialState,
  reducers: {
    setDataRange: (state, action) => {
      return {
        ...state,
        isLoaded: action.payload.isLoaded,
        loadError: action.payload.loadError,
      };
    },
  },
});

export const { setDataRange } = findRangeSlice.actions;

export default findRangeSlice.reducer;
